const headers = [
  { label: 'ICCID', key: 'serial' },
  { label: 'Número', key: 'number' },
  { label: 'Operadora', key: 'service_provider' },
  { label: 'Provedora', key: 'carrier' },
  { label: 'APN', key: 'apn' },
  { label: 'APN - Usuário', key: 'apn_username' },
  { label: 'APN - Senha', key: 'apn_password' }
]

const data = [
  {
    serial: '12345678900987654321',
    number: '5500911223344',
    service_provider: 'Vivo',
    carrier: 'Allcom',
    apn: 'apn',
    apn_username: 'apn_username',
    apn_password: 'apn_password'
  },
  {
    serial: '12345678900987654321',
    number: '5500911223344',
    service_provider: 'Vivo',
    carrier: 'Allcom',
    apn: 'apn',
    apn_username: 'apn_username',
    apn_password: 'apn_password'
  }
]

export const chipModel = {
  filename: 'ModeloChips.csv',
  headers: headers,
  data: data
}
