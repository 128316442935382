import { useCallback, useState } from 'react'

import { Modal } from 'components/Modal'
import { SearchAddress } from './Search'
import { Button, Close } from 'components/Buttons'
import { addressTypes } from 'components/FormInputs/options'
import { InlineList, InputWithValidationError } from 'components/FormInputs'

import { Errors } from 'types'
import { Address, AddressType } from 'models'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { validate } from 'modules/Clients/validations/address'
import { check, checkIsFilled, checkIsValid } from 'utils/error'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any
}

export function CreateAddress({ open, setOpen }: Props) {
  const { isSaving, createAddress } = useEditClient()

  const [type, setType] = useState<AddressType>(addressTypes[0].value)
  const [address, setAddress] = useState<Address>()
  const [errors, setErrors] = useState<Errors>({})

  const onAttributeChange = useCallback(
    (attr: keyof Address, value: any): void => {
      const error = validate(attr, value)

      if (address) {
        setAddress({ ...address, [attr]: value })
        setErrors({ ...errors, [attr]: error })
      }
    },
    [address, errors]
  )

  const onClose = useCallback(() => {
    setAddress(undefined)
    setErrors({})
    setType(addressTypes[0].value)
    setOpen(false)
  }, [setOpen])

  const isFormValid = useCallback((): boolean => {
    if (!address) {
      setErrors({
        formatted_address: 'O campo deve ser preenchido'
      })

      return false
    }

    if (address.formatted_address.length <= 3) {
      setErrors({
        formatted_address: 'Endereço inválido'
      })

      return false
    }

    const requiredInputs = [
      'formatted_address',
      'street_number',
      'city',
      'region',
      'country',
      'zip_code'
    ]
    const isFilled = checkIsFilled(address, requiredInputs)
    const isValid = checkIsValid(isFilled)

    setErrors({
      ...errors,
      formatted_address: !isFilled.formatted_address
        ? 'O campo deve ser preenchido'
        : null,
      street_number: !isFilled.street_number
        ? 'O campo deve ser preenchido'
        : null,
      city: !isFilled.city ? 'O campo deve ser preenchido' : null,
      region: !isFilled.region ? 'O campo deve ser preenchido' : null,
      country: !isFilled.country ? 'O campo deve ser preenchido' : null,
      zip_code: !isFilled.zip_code ? 'O campo deve ser preenchido' : null
    })

    return isValid
  }, [address, errors])

  const onSelect = useCallback((address: any) => {
    setErrors({})
    setAddress(address)
  }, [])

  const onSave = useCallback(async () => {
    if (!address) return

    if (!isFormValid() || check(errors)) return

    await createAddress(address, type)
    onClose()
  }, [address, isFormValid, errors, createAddress, type, onClose])

  return (
    <Modal open={open} title="Novo endereço" onClose={onClose} width="sm:w-1/2">
      <div className="mt-4 space-y-5">
        <InlineList
          label="Tipo"
          description=""
          value={type}
          options={addressTypes}
          onChange={setType}
        />

        <div className="w-full">
          <SearchAddress onSelect={onSelect} error={errors.formatted_address} />
        </div>

        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              disabled={!address?.formatted_address}
              label="Número"
              name="number"
              value={address?.street_number}
              onChange={(value) => onAttributeChange('street_number', value)}
              error={errors.street_number}
            />
          </div>

          <div className="w-1/2">
            <InputWithValidationError
              disabled={!address?.formatted_address}
              optional="opcional"
              label="Complemento"
              name="complement"
              value={address?.complement}
              onChange={(value) => onAttributeChange('complement', value)}
              error={errors.complement}
            />
          </div>
        </div>

        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              disabled={!address?.formatted_address}
              label="Cidade"
              name="city"
              value={address?.city}
              onChange={(value) => onAttributeChange('city', value)}
              error={errors.city}
            />
          </div>

          <div className="w-1/2">
            <InputWithValidationError
              disabled={!address?.formatted_address}
              label="Estado"
              name="state"
              value={address?.region}
              onChange={(value) => onAttributeChange('region', value)}
              error={errors.region}
            />
          </div>
        </div>

        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              disabled={!address?.formatted_address}
              label="CEP"
              name="zipcode"
              value={address?.zip_code}
              onChange={(value) => onAttributeChange('zip_code', value)}
              error={errors.zip_code}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end space-x-4">
        <Close onClick={onClose} />
        <Button disabled={isSaving} type="button" onClick={onSave} />
      </div>
    </Modal>
  )
}
