import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'
import { useState, useCallback, useEffect, useMemo } from 'react'

import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import { LoadingCircle } from 'components/Loading'

import config from 'config'
import { Role, Order, OrderStatus } from 'models'
import { list } from '../services/read'
import { useURLParams } from 'hooks/useURLParams'
import { QueryFilter, ReadResult } from 'interfaces/queryOptions'
import { amount, currency, date, document, time } from 'utils'
import { translations } from 'modules/AccessPlans/translations'
import { SearchBar } from 'components/Search'
import { ProtectedSection } from 'components/ProtectedSection'
import { SimpleButton } from 'components/FormInputs/Button'
import { Label } from 'components/Label'
import { formatDuration } from '../helpers'
import { getOrderStatusAndDate } from 'utils/order-status'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SortIcon } from 'components/Table/SortIcon'
import { SimpleCustomSelect } from 'components/FormInputs'

const statusFilters = [
  { value: '', label: 'Selecione um estado' },
  { value: OrderStatus.pending, label: 'Pendente' },
  { value: OrderStatus.delivered, label: 'Ativado' },
  { value: OrderStatus.canceled, label: 'Cancelado' }
]

const removeFilter = (filters: QueryFilter[], key: string, range?: string) => {
  const filtered = [...filters]

  const opMask: Record<string, string> = { to: 'lte', from: 'gte' }

  const filterByKey = (f: QueryFilter) => f.key === key
  const filterByKeyAndOp = (f: QueryFilter) =>
    f.key === key && f.op === opMask[range as string]

  const filterIndex = range
    ? filtered.findIndex(filterByKeyAndOp)
    : filtered.findIndex(filterByKey)

  if (filterIndex >= 0) {
    filtered.splice(filterIndex, 1)
  }

  return filtered
}

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      filters: [],
      search: ''
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) params.filters = JSON.parse(atob(filters))

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [sort, setSort] = useState<Record<string, number>>({ ordered_at: -1 })
  const [isAccountSorted, setIsAccountSorted] = useState(false)
  const [isActivatedAtSorted, setIsActivatedAtSorted] = useState(false)
  const [isSortedDesc, setIsSortedDesc] = useState(true)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>(queryParams.filters)

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ReadResult<Order>[]>([])

  const [isLoading, setIsLoading] = useState(false)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)

      const options = {
        attributes: [
          'id',
          'token',
          'quantity',
          'ordered_at',
          'activated_at',
          'discount',
          'current_status'
        ],
        includes: {
          client: ['id', 'account_name', 'document', 'document_type'],
          plan: [
            'id',
            'name',
            'type',
            'currency',
            'amount',
            'duration',
            'usage_type'
          ]
        },
        filters: [
          ...filters,
          { key: 'plan.usage_type', op: 'neq', value: 'trial' }
        ],
        sort,
        page,
        search,
        limit: maxItemsPerPage
      }

      try {
        const { count, data } = await list(options)

        setData(data)
        setTotal(count)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error('Falha ao buscar assinaturas')
        console.error(err)
      }
    }

    loadData()
  }, [maxItemsPerPage, page, search, sort, filters])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const onSort = useCallback(
    (sortBy: string) => {
      const sortValue = isSortedDesc ? -1 : 1

      setSort({ [sortBy]: sortValue })

      if (sortBy === 'activated_at') {
        setIsAccountSorted(false)
        setIsActivatedAtSorted(true)
      } else {
        setIsAccountSorted(false)
        setIsActivatedAtSorted(true)
      }
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onFilter = useCallback(
    (key: string, value: any, range?: string) => {
      const cleanFilters = removeFilter(filters, key, range)

      if (value === '') {
        if (cleanFilters.length > 0) {
          query.set('q', btoa(JSON.stringify(cleanFilters)))
        } else {
          query.delete('q')
        }

        replace({
          pathname: window.location.pathname,
          search: query.toString()
        })

        setFilters([...cleanFilters])
        return
      }

      if (key === 'current_status') {
        cleanFilters.push({ key, op: 'eq', value })
      }

      if (cleanFilters.length > 0) {
        query.set('q', btoa(JSON.stringify(cleanFilters)))
      } else {
        query.delete('q')
      }

      setFilters([...cleanFilters])

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })
    },
    [filters, replace, query]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Assinaturas" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[Role.MANAGER, Role.SALES]}>
          <Link to="/subscriptions/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      <div className="w-full px-4 my-4 h-16 flex items-center justify-between">
        <div className="w-1/5">
          <SimpleCustomSelect
            label=""
            options={statusFilters}
            value={
              filters?.find((filter) => filter.key === 'current_status')
                ?.value ?? statusFilters[0].value
            }
            onChange={(value) => onFilter('current_status', value)}
          />
        </div>{' '}
      </div>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          CÓDIGO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          ESTADO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('client.account_name')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>CONTA</div>
                            {isAccountSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          PLANO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          VALOR UNITÁRIO
                        </th>
                        <th
                          scope="col"
                          className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          onClick={() => onSort('activated_at')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>ATIVADO EM</div>
                            {isActivatedAtSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>DURAÇÃO</div>
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data.map((subscription, index) => {
                        return (
                          <tr
                            key={subscription.id}
                            className={
                              index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                            }
                          >
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-1 truncate">
                                  <div className="text-xs text-left font-semibold text-gray-900 truncate">
                                    {' '}
                                    {subscription.attributes.token}{' '}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="text-sm text-left font-semibold text-gray-900 truncate">
                                <Label
                                  type="order_status"
                                  label={
                                    translations['subscription_status'][
                                      subscription.attributes.current_status!
                                    ]
                                  }
                                  value={
                                    subscription.attributes.current_status!
                                  }
                                />
                              </div>
                              <div className="ml-2 text-xs text-left text-gray-500">
                                {' '}
                                {date(
                                  getOrderStatusAndDate(subscription).date!
                                )}{' '}
                              </div>
                            </td>
                            <td className="px-4 py-3 max-w-xxs whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-1 truncate">
                                  <div className="text-xs text-left font-semibold text-gray-900 truncate">
                                    {' '}
                                    {
                                      subscription.relationships?.client
                                        .attributes.account_name
                                    }{' '}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {' '}
                                    {document(
                                      subscription.relationships?.client
                                        .attributes.document,
                                      subscription.relationships?.client
                                        .attributes.document_type
                                    )}{' '}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-500">
                              <div className="flex items-center">
                                <div className="flex-1">
                                  <div className="text-xs text-left font-semibold text-gray-900">
                                    {' '}
                                    {
                                      subscription.relationships?.plan
                                        .attributes.name
                                    }{' '}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {subscription.relationships?.plan.attributes
                                      .usage_type === 'tiered'
                                      ? `${
                                          translations.type[
                                            subscription.relationships?.plan
                                              .attributes.usage_type as string
                                          ]
                                        }: ${
                                          translations.tier_mode[
                                            subscription.relationships?.plan
                                              .attributes.tier_mode as string
                                          ]
                                        }`
                                      : translations.type[
                                          subscription.relationships?.plan
                                            .attributes.usage_type as string
                                        ]}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 whitespace-nowrap">
                              <div className="flex">
                                <div className="flex-1">
                                  <div className="text-xs text-left font-semibold text-gray-900">
                                    {' '}
                                    {`${currency(
                                      subscription.relationships?.plan
                                        .attributes.currency
                                    )} ${amount(
                                      subscription.relationships?.plan
                                        .attributes.amount -
                                        (subscription.attributes.discount ?? 0)
                                    )}`}{' '}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {' '}
                                    {`Quantidade: ${subscription.attributes?.quantity}`}{' '}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              <div className="flex">
                                <div className="flex-1">
                                  <div className="text-xs text-left font-semibold text-gray-900">
                                    {date(subscription.attributes.activated_at)}
                                  </div>
                                  <div className="text-xs text-left text-gray-500">
                                    {time(subscription.attributes.activated_at)}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                              {formatDuration(
                                subscription.relationships?.plan.attributes
                                  .duration,
                                subscription.relationships?.plan.attributes
                                  .interval
                              ) ?? '-'}
                            </td>
                            <td className="px-4 py-3 text-xs text-left text-gray-500">
                              <div className="flex flex-row">
                                <Link
                                  to={`/subscriptions/${subscription.id}/view`}
                                >
                                  <button
                                    type="button"
                                    className={
                                      'relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 ' +
                                      (['pending'].includes(
                                        subscription.attributes.current_status!
                                      )
                                        ? ''
                                        : 'rounded-r-md')
                                    }
                                  >
                                    <EyeIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Link>
                                {subscription.attributes.current_status ===
                                  'pending' && (
                                  <Link
                                    to={`/subscriptions/${subscription.id}`}
                                  >
                                    <button
                                      type="button"
                                      className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    >
                                      <PencilIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </Link>
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={maxItemsPerPage}
                itemsOnPage={data.length}
                totalItems={total}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
