export enum Role {
  ROOT = 'ROOT',
  MANAGER = 'MANAGER',
  FINANCE = 'FINANCE',
  SUPPORT = 'SUPPORT',
  SALES = 'SALES',
  LOGISTICS = 'LOGISTICS'
}

export interface RoleModel {
  id: number
  name: string
  created_at: Date
  updated_at: Date
  deleted_at?: Date
}
