import { createContext, ReactNode } from 'react'

export interface DatePickerAttributes {
  minDate: Date
  maxDate: Date
}

export interface ShowConfirmationDialogParams {
  title: ReactNode
  message: ReactNode
  onConfirm: () => any
  onCancel?: () => any
  datePicker?: boolean
  datePickerAttributes?: DatePickerAttributes
  date?: Date
  onDateChange?: (value: any) => any
}

const ConfirmationContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  showConfirmationDialog: (_params: ShowConfirmationDialogParams) => {}
})

export { ConfirmationContext }
