import { useState } from 'react'
import { PlusSmIcon } from '@heroicons/react/solid'

import { Errors } from 'types'
import { Currency, Tier } from 'models'
import { currency } from 'utils/presentation'
import { validate } from 'modules/AccessPlans/validation'
import { InputWithAddon, InputWithValidationError } from 'components/FormInputs'

interface Prop {
  selectedCurrency: Currency | undefined
  tiers: Tier[]
  setTiers: (value: any[]) => any
  errors: Errors
  setErrors: (value: any) => any
}

export function CreateTier({
  selectedCurrency,
  tiers,
  setTiers,
  errors,
  setErrors
}: Prop) {
  const [tier, setTier] = useState<Partial<Tier>>({})

  const onTierChange = (attr: keyof Tier, value: any) => {
    const error = validate(attr as any, value)

    setTier({ ...tier, [attr]: value })
    setErrors({ ...errors, [attr]: error })
  }

  const onAddTier = () => {
    if (!tier.unit_amount || !tier.up_to) return

    const parsedUnit = parseFloat(
      tier.unit_amount.toString().replaceAll(',', '.')
    )

    const lastTier = tiers[tiers.length - 1]

    if (lastTier && tier.up_to <= lastTier.up_to) {
      setErrors({
        ...errors,
        up_to: 'Até não pode ser menor ou igual ao anterior'
      })
      return
    }

    tiers.push({
      unit_amount: Math.floor(parsedUnit * 100),
      up_to: Math.floor(+(tier.up_to ?? 0))
    })

    setTiers([...tiers])
    setTier({})
    setErrors({ ...errors, unit_amount: null, up_to: null, tiers: null })
  }

  return (
    <div className="flex flex-row space-x-4 items-end relative">
      <div className="w-1/2">
        <InputWithAddon
          label="Valor unitário"
          name="unit_amount"
          type="text"
          pattern="[0-9]+([\.,][0-9]+)?"
          value={tier.unit_amount ?? 0}
          frontAddon={currency(selectedCurrency)}
          onChange={(value) => onTierChange('unit_amount', value)}
          error={errors.unit_amount}
        />
      </div>

      <div className="w-1/2">
        <InputWithValidationError
          label="Até"
          name="up_to"
          type="number"
          value={tier.up_to ?? 0}
          onChange={(value) => onTierChange('up_to', value)}
          error={errors.up_to}
        />
      </div>
      <div className="w-1/12">
        <button
          disabled={!tier.unit_amount || !tier.up_to}
          type="button"
          className={
            'overflow-hidden block border rounded-md shadow-sm py-2 px-3 focus:outline-none border-gray-300 focus:border-emerald-500 ' +
            (!tier.unit_amount || !tier.up_to ? 'bg-gray-100' : 'bg-white')
          }
          onClick={onAddTier}
        >
          <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className="w-1/2 pb-3">
        {errors.tiers && (
          <p className="text-xs text-red-600">
            Adicione faixas antes de salvar
          </p>
        )}
      </div>
    </div>
  )
}
