import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import { Installment } from 'models'
import { useEffect, useState } from 'react'
import { amount, cleanAmount, currency, formatCurrency } from 'utils'
import { getLastDayOfMonth, setToEndOfDay } from 'utils/datetime'

const DEFAULT_SPLIT_BY = 1

interface Props {
  totalAmount: number
  amountCurrency: string
  billingCycleDay: number
  setInstallments: (value: any[]) => any
  maxNumberOfInstallments: number
  clientInvoiceInDraftStatus?: boolean
}

export const errorTypes = {
  all_installments_should_have_value_other_than_zero:
    'Todas as parcelas devem ter um valor diferente de zero!',
  total_amount_and_installments_value_mismatch:
    'Valor das parcelas não bate com valor total!',
  installments_dates_must_be_in_order:
    'As datas de vencimento das parcelas devem estar em ordem!',
  negative_values_not_allowed:
    'Parcelas com valores negativos não são permitidas'
}

function calculatePaddingLeft(text: string) {
  if (!text || text === '') return 'pl-3'

  if (text.length === 1) return 'pl-8'

  if (text.length === 2) return 'pl-10'

  if (text.length === 3) return 'pl-12'

  if (text.length === 4) return 'pl-14'

  return 'pl-20'
}

const clearForm = (formId: string) => {
  const inputs = document.getElementById(formId) as HTMLFormElement
  if (inputs) {
    inputs.reset()
  }
}

const suggestQuantities = (
  total: number | undefined,
  splitBy: number,
  billingCycleDay: number,
  isClientInvoiceInDraftStatus: boolean,
  upfrontAmount?: number
): Installment[] => {
  if (!total) return []

  if (splitBy === 0) {
    return [
      {
        amount: amount(total),
        upfront: true,
        due_at: setToEndOfDay(new Date()),
        type: 'setup_fee',
        eligible: true
      }
    ]
  }

  let splitted
  let suggestions: number[]

  if (upfrontAmount) {
    total -= upfrontAmount
  }

  const mod = total % splitBy
  if (mod === 0) {
    splitted = total / splitBy
    suggestions = Array(splitBy).fill(splitted)
  } else {
    splitted = (total - mod) / splitBy
    suggestions = Array(splitBy).fill(splitted)
    for (let i = 0; i < mod; i++) {
      suggestions[i]++
    }
  }

  const formattedSuggestions = suggestions.map((suggestion, index) => ({
    amount: amount(suggestion),
    upfront: false,
    due_at: calculateDueDate(
      index,
      billingCycleDay,
      isClientInvoiceInDraftStatus
    ),
    type: 'setup_fee',
    eligible: true
  }))

  return [
    {
      amount: upfrontAmount ? amount(upfrontAmount) : '0,00',
      upfront: true,
      due_at: setToEndOfDay(new Date()),
      type: 'setup_fee',
      eligible: true
    },
    ...formattedSuggestions
  ]
}

const calculateDueDate = (
  index: number,
  billingCycleDay: number,
  isClientInvoiceInDraftStatus: boolean
) => {
  const baseDate = new Date()

  if (isClientInvoiceInDraftStatus && baseDate.getDate() <= billingCycleDay) {
    index--
  }

  const currentYear = baseDate.getFullYear()
  const currentMonth = baseDate.getMonth()

  const baseMonth = currentMonth + 1 + index

  const dueYear = currentYear + Math.floor(baseMonth / 12)
  const dueMonth = baseMonth % 12

  return setToEndOfDay(new Date(dueYear, dueMonth, billingCycleDay))
}

export function CreateInstallment({
  totalAmount,
  amountCurrency,
  billingCycleDay,
  setInstallments,
  maxNumberOfInstallments,
  clientInvoiceInDraftStatus = false
}: Props) {
  const [splitBy, setSplitBy] = useState(DEFAULT_SPLIT_BY)
  const [quantities, setQuantities] = useState([] as Installment[])
  const [error, setError] = useState(null as null | string)

  const onSplitBy = (value: string) => {
    const parsed = parseInt(value)

    clearForm('input-fields')

    setError(null)
    setSplitBy(parsed)

    const upfrontAmount = quantities[0]?.amount

    setQuantities(
      suggestQuantities(
        totalAmount,
        parsed,
        billingCycleDay,
        clientInvoiceInDraftStatus,
        cleanAmount(upfrontAmount)
      )
    )
  }

  const onQuantityChange = (index: number, value: string | undefined) => {
    setError(null)

    if (!value) return

    quantities[index] = { ...quantities[index], amount: formatCurrency(value) }
    setQuantities([...quantities])
  }

  useEffect(() => {
    onSplitBy(splitBy.toString())
  }, [totalAmount])

  useEffect(() => {
    if (!quantities.length) return

    const notAllValid = quantities.some(
      (quantity, idx) =>
        idx !== 0 && (quantity == null || cleanAmount(quantity.amount) === 0)
    )

    const sum = quantities.reduce(
      (acc, quantity) => acc + cleanAmount(quantity.amount),
      0
    )

    const negativeValuesFound = quantities.some(
      (quantity) => cleanAmount(quantity.amount) < 0
    )

    if (notAllValid) {
      setError(errorTypes.all_installments_should_have_value_other_than_zero)
      return
    }

    if (sum !== totalAmount) {
      setError(errorTypes.total_amount_and_installments_value_mismatch)
      return
    }

    if (negativeValuesFound) {
      setError(errorTypes.negative_values_not_allowed)
      return
    }

    const installments = quantities
      .map((qnt) => ({
        ...qnt,
        amount: cleanAmount(qnt.amount)
      }))
      .filter((inst) => inst.amount !== 0)

    const isSomeInstallmentUnvalid = installments.reduce(
      (isSomeInstallmentUnvalid, current, index, arr) => {
        if (isSomeInstallmentUnvalid) return true
        if (index === 0) return false

        const previousDueAt = arr[index - 1].due_at
        if (previousDueAt && current.due_at && current.due_at < previousDueAt) {
          setError(errorTypes.installments_dates_must_be_in_order)
          return true
        }

        return false
      },
      false
    )

    if (isSomeInstallmentUnvalid) return

    setInstallments(installments)
  }, [quantities])

  return (
    <>
      <div className="flex pt-3 justify-between items-center">
        <div className="flex space-x-2 items-center">
          <label className="text-sm">Parcelar:</label>
          <select
            value={splitBy}
            onChange={(e: any) => onSplitBy(e.target.value)}
            className="block py-1 px-2 text-base border border-gray-300 focus:outline-none focus:border-emerald-500 sm:text-sm rounded-md"
          >
            <option key={0} value={0}>
              Não
            </option>
            {Array.from({ length: maxNumberOfInstallments }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                {`${index + 1}x`}
              </option>
            ))}
          </select>
        </div>
        <label className="text-sm">
          {'Valor total: '}
          <span className="font-semibold">{`${currency(
            amountCurrency
          )} ${amount(totalAmount)}`}</span>
        </label>
      </div>
      <form id="input-fields" className="mt-3 flex-row space-y-4">
        <div className="relative">
          {quantities.map((qnt, index) => (
            <div key={index} className="flex items-end space-x-2 mb-2">
              <div
                className={
                  'w-[125px] h-10.5 flex relative border rounded-md px-3 py-2 shadow-sm ' +
                  (error &&
                  ([
                    errorTypes.installments_dates_must_be_in_order,
                    errorTypes.total_amount_and_installments_value_mismatch
                  ].includes(error) ||
                    index !== 0)
                    ? 'focus-within:border-red-500 border-red-300'
                    : 'focus-within:border-emerald-500 border-gray-300')
                }
              >
                <label
                  htmlFor={'qnt_' + (index + 1)}
                  className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                >
                  {`${
                    qnt.upfront
                      ? splitBy !== 0
                        ? 'Entrada'
                        : ''
                      : quantities.some((q) => q.upfront)
                      ? `#${index}`
                      : `#${index + 1}`
                  }`}
                </label>
                <div className="relative flex rounded-md">
                  <span className="absolute left-0 inset-y-0 inline-flex items-center text-gray-400 bg-transparent">
                    {currency(amountCurrency)}
                  </span>
                  <input
                    disabled={splitBy === 0}
                    type="text"
                    name={'qnt-' + (index + 1)}
                    id={'qnt-' + (index + 1)}
                    placeholder={qnt.toString()}
                    value={quantities[index].amount}
                    pattern="[0-9]+([\.,][0-9]+)?"
                    className={
                      calculatePaddingLeft(currency(amountCurrency)) +
                      ' flex-1 min-w-0 block w-full rounded-md focus:outline-none aria-hidden' +
                      ' disabled:bg-white disabled:cursor-not-allowed'
                    }
                    onChange={(e: any) =>
                      onQuantityChange(index, e.currentTarget.value)
                    }
                  />
                </div>
              </div>
              <DatePickerWithError
                label={''}
                name="upfrontDueAt"
                date={qnt.due_at}
                onChange={(date) =>
                  setQuantities((prevQuantities) =>
                    prevQuantities.map((item, i) =>
                      i === index ? { ...item, due_at: date! } : item
                    )
                  )
                }
                minDate={new Date()}
                {...(qnt.upfront
                  ? {
                      maxDate: getLastDayOfMonth()
                    }
                  : {
                      filterDate: (date: Date) => {
                        const today = new Date()
                        const isCurrentMonth =
                          date.getMonth() === today.getMonth() &&
                          date.getFullYear() === today.getFullYear()

                        const isBillingDay = date.getDate() === billingCycleDay

                        return (
                          isBillingDay &&
                          (!isCurrentMonth || clientInvoiceInDraftStatus)
                        )
                      }
                    })}
              />
            </div>
          ))}
          {splitBy !== 0 && (
            <div className="absolute right-0 bottom-1 mt-6 flex justify-end">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={() => {
                  setError(null)
                  setQuantities(
                    suggestQuantities(
                      totalAmount,
                      quantities.length - 1,
                      billingCycleDay,
                      clientInvoiceInDraftStatus,
                      cleanAmount(quantities[0].amount)
                    )
                  )
                }}
              >
                Recalcular parcelas
              </button>
            </div>
          )}
        </div>
      </form>
      {error && (
        <p className="text-xs text-red-600" id="quantities-error">
          {error}
        </p>
      )}
    </>
  )
}
