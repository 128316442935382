interface ResizibleTextAreaProps {
  label: string
  name: string
  resize?: string
  autoComplete?: string
  onChange: (value: string) => any
  value?: string | null
  optional?: boolean
  [key: string]: any
}

export function ResizibleTextArea({
  label,
  name,
  onChange,
  value,
  optional,
  ...rest
}: ResizibleTextAreaProps) {
  return (
    <div>
      <label
        htmlFor={name}
        className="block text-left text-sm font-medium text-gray-700"
      >
        {label}
        {optional && (
          <span className="text-xs text-gray-400"> (Opcional) </span>
        )}
      </label>
      <textarea
        name={name}
        value={value ?? ''}
        resize="false"
        autoComplete="off"
        className="mt-1 w-full px-3 py-2 text-gray-700 border rounded-md shadow-sm focus:outline-none resize-none border-gray-300 focus:border-emerald-500"
        rows={4}
        onChange={(e) => onChange(e.currentTarget.value)}
        {...rest}
      ></textarea>
    </div>
  )
}
