export enum AddressType {
  billing = 'billing',
  deliver = 'deliver',
  other = 'other'
}

export type ClientAddress = {
  id: number
  client_id: number
  address_id: number
  type: AddressType
  created_at: Date
  updated_at: Date
  deleted_at?: Date
}
