import { Chip } from 'models'
import { QueryOptions } from 'interfaces/queryOptions'
import { list as baseList, createBatch } from 'services/chips'

export interface ChipListResponse {
  id: number
  enterprise?: string
  serial?: string
  number?: string
  service_provider?: string
  carrier?: string
  apn?: string
  apn_username?: string
  apn_password?: string
}

export interface ListResponse {
  count: number
  chips: ChipListResponse[]
}

export const list = async (
  options: QueryOptions = {}
): Promise<ListResponse> => {
  const result = await baseList(options)

  const chips = result.data.map((chip) => {
    const chipAttributes = chip.attributes.attributes
    const enterpriseAttributes =
      chip.relationships?.enterprise?.attributes.attributes ?? {}

    return {
      id: chip.id,
      enterprise: enterpriseAttributes.name,
      serial: chipAttributes.serial,
      number: chipAttributes.number,
      service_provider: chipAttributes.service_provider,
      carrier: chipAttributes.carrier,
      apn: chipAttributes.apn,
      apn_username: chipAttributes.apn_username,
      apn_password: chipAttributes.apn_password
    }
  })

  return { count: result.count, chips }
}

export const create = async (attributes: Partial<Chip>[]) => {
  const payload = attributes.map((a) => {
    return { attributes: { ...a, from_softruck: true } }
  })

  await createBatch(payload)
}
