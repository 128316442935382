import { useEffect } from 'react'

import { OrderTable } from './components/OrderTable'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'

export function OrderTab() {
  const { client, fetchClient } = useViewClient()

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  return (
    <>
      <div className="py-8">
        <OrderTable />
      </div>
    </>
  )
}
