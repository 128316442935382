import { ReadResult } from 'interfaces/queryOptions'
import { Order } from 'models'
import { getFirstDayOfMonth } from './datetime'

export const dateColumnToOrderStatus: Record<string, string> = {
  approved_at: 'approved',
  queued_at: 'in_line',
  processing_at: 'in_progress',
  fiscal_data_updated_at: 'updating_fiscal_data',
  ready_at: 'ready_for_pick_up',
  shipped_at: 'shipping',
  canceled_at: 'canceled',
  activated_at: 'delivered'
}

export function getOrderStatusAndDate(order: ReadResult<Order>): {
  status: string
  date: Date | null
} {
  const dateColumns = [
    { column: 'activated_at', value: order.attributes.activated_at },
    { column: 'approved_at', value: order.attributes.approved_at },
    { column: 'queued_at', value: order.attributes.queued_at },
    { column: 'processing_at', value: order.attributes.processing_at },
    {
      column: 'fiscal_data_updated_at',
      value: order.attributes.fiscal_data_updated_at
    },
    { column: 'ready_at', value: order.attributes.ready_at },
    { column: 'shipped_at', value: order.attributes.shipped_at },
    { column: 'canceled_at', value: order.attributes.canceled_at }
  ]

  // filtering out null/undefined dates and sorting by the most recent
  const latestDateColumn = dateColumns
    .filter(({ value }) => value !== null && value !== undefined) // filter out null/undefined
    .sort((a, b) =>
      a.value != null && b.value != null && a.value > b.value ? -1 : 1
    )[0] // sorting

  // return both the status and the date, if pending, date is null
  if (latestDateColumn?.value != null) {
    return {
      status: dateColumnToOrderStatus[latestDateColumn.column],
      date: latestDateColumn.value
    }
  } else {
    return {
      status: 'pending',
      date: order.attributes.ordered_at
        ? new Date(order.attributes.ordered_at)
        : new Date(order.attributes.created_at!)
    }
  }
}

export function determineCancelationMinDate(
  deliveryDate: Date | undefined,
  orderedAt: Date
): Date {
  const today = new Date()

  if (!deliveryDate) {
    return orderedAt
  } else {
    const deliveryMonth = deliveryDate.getMonth()
    const currentMonth = today.getMonth()

    if (deliveryMonth !== currentMonth) {
      return getFirstDayOfMonth()
    } else {
      return deliveryDate
    }
  }
}
