import { ClientStatus } from 'models/clientStatusHistory'

export const documentTypes = [
  { value: 'cnpj', label: 'CNPJ' },
  { value: 'rut', label: 'RUT' }
]

export const types = [
  { value: 'society', label: 'Associação' },
  { value: 'cooperative', label: 'Cooperativa' },
  { value: 'enterprise', label: 'Empresa' }
]

export const segments = [
  { value: 'transportation', label: 'Transporte e Logistica' },
  { value: 'education', label: 'Educação' },
  { value: 'government', label: 'Governamental' },
  { value: 'retail', label: 'Varejo' },
  { value: 'services', label: 'Serviços Gerais' },
  { value: 'trucking', label: 'Caminhões' },
  { value: 'construction', label: 'Materiais e construção' },
  { value: 'energy', label: 'Energia e Utilidades' },
  { value: 'food', label: 'Comida e Bebida' },
  { value: 'healthcare', label: 'Saúde' },
  { value: 'tracking', label: 'Rastreamento' },
  { value: 'insurance', label: 'Seguro' },
  { value: 'association', label: 'Associação' },
  { value: 'other', label: 'Outros' }
]

export const statuses: { value: ClientStatus; label: string }[] = [
  { value: 'pending', label: 'Pendente' },
  { value: 'active', label: 'Ativo' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'not_renewed', label: 'Finalizado' },
  { value: 'overdue', label: 'Inadimplente' },
  { value: 'restricted', label: 'Bloqueado' }
]

export const countries = [
  { value: 1, label: 'Brasil' },
  { value: 2, label: 'Uruguai' },
  { value: 3, label: 'Argentina' },
  { value: 4, label: 'Chile' },
  { value: 5, label: 'México' },
  { value: 6, label: 'Paraguai' },
  { value: 7, label: 'Venezuela' }
]

export const timezones = [
  'America/Sao_Paulo',
  'America/Montevideo',
  'America/Buenos_Aires',
  'America/Santiago',
  'America/Mexico_City',
  'America/Asuncion',
  'America/Caracas'
].map((tz) => ({ value: tz, label: tz }))

export function phoneCode(countryCode: string | undefined) {
  if (!countryCode) return '55'

  const codeMap: Record<string, string> = {
    BRA: '55',
    URY: '598',
    ARG: '54',
    CHL: '56',
    MEX: '52',
    PRY: '595',
    VEN: '58'
  }

  return codeMap[countryCode]
}
