import { Currency, Interval } from 'models'
import {
  CouponDurationMode,
  CouponForm,
  CouponMode,
  CouponType
} from 'models/coupon'
import { validateCreation as validate } from 'modules/Orders/validation'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Errors } from 'types'
import { cleanAmount, currency, deepClone, formatCurrency } from 'utils'
import { check } from 'utils/error'
import { create } from '../services/create'
import { PageHeader } from 'components/PageHeader'
import { Cards } from 'components/FormInputs/Cards'
import {
  couponDurationModes,
  couponTypes,
  currencies,
  fineModes,
  intervals,
  yesOrNo
} from 'components/FormInputs/options'
import { FormSection } from 'components/Form'
import {
  InputWithAddon,
  InputWithValidationError,
  RadioListWithDescription,
  SimpleCustomSelect
} from 'components/FormInputs'
import { listCountries } from 'modules/Staff/services/api'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import { setDateToUTC } from '../helpers'

export function Create() {
  const history = useHistory()

  const [expires, setExpires] = useState('no')

  const formDataInitialState = {
    name: '',
    currency: Currency.brl,
    interval: Interval.month,
    type: CouponType.software,
    duration_mode: CouponDurationMode.forever,
    duration: '1',
    expires_at: null,
    redeems_remaining: '',
    mode: CouponMode.fixed,
    amount: 0,
    country_id: 1
  }

  const [formData, setFormData] = useState<CouponForm>(formDataInitialState)

  interface CountryOption {
    value: number
    label: string
  }

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await listCountries({
        attributes: ['name', 'code']
      })

      setCountries(
        countries.data.map((country) => {
          return {
            value: country.id,
            label: country.attributes.name ?? '',
            code: country.attributes.code
          }
        })
      )

      for (const country of countries.data) {
        if (country.attributes.code === 'BRA') {
          setFormData({ ...formData, country_id: country.id.toString() })
          break
        }
      }
    }

    fetchCountries()
  }, [])

  const [countries, setCountries] = useState<CountryOption[]>([])

  const [errors, setErrors] = useState<Errors>({})

  const onSave = async () => {
    if (!formData) return

    const transformAttributes = (
      attributes: Record<string, any>
    ): Record<string, any> => {
      const { mode, amount, redeems_remaining, duration } = attributes

      return {
        mode: amount === '' ? null : mode,
        amount:
          amount === '' ? null : mode === null ? null : cleanAmount(amount),
        redeems_remaining:
          redeems_remaining === '' ? null : parseInt(redeems_remaining),
        duration: duration === '' ? null : parseInt(duration)
      }
    }

    if (validateFields(formData) || check(errors)) {
      toast.error('Corrija os erros antes de prosseguir')
      return
    }

    const otherAttributes = transformAttributes(formData)

    try {
      await create({ ...formData, ...otherAttributes })
      toast.success('Cupom criado com sucesso')
      history.push('/coupons')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao tentar criar o cupom'
      )
    }
  }

  const validateFields = (form: CouponForm) => {
    let isInvalid = false

    const isNameFilled =
      Object.prototype.hasOwnProperty.call(form, 'name') &&
      form.name !== null &&
      form.name?.trim() !== ''
    if (!isNameFilled) {
      errors.name = 'O campo deve ser preenchido'
      isInvalid = true
    } else {
      errors.name = null
    }

    const isDurationFilled =
      form.duration?.toString() !== '' &&
      !isNaN(Number(form.duration)) &&
      Number.isInteger(Number(form.duration))
    if (!isDurationFilled) {
      errors.duration = 'Deve ser um número inteiro válido'
      isInvalid = true
    } else {
      errors.duration = null
    }

    const isAmountValid =
      form.amount?.toString() !== '0' && form.amount?.toString() !== '0,00'
    if (!isAmountValid) {
      errors.amount = 'Insira um valor'
      isInvalid = true
    } else {
      errors.amount = null
    }

    setErrors({ ...errors })
    return isInvalid
  }

  const onAttributeChange = (attr: keyof CouponForm, value: any) => {
    if (!formData) return

    if (attr === 'amount') {
      value = formatCurrency(value)
    }

    if (attr === 'redeems_remaining' || attr === 'duration') {
      value = value === '' ? '' : isNaN(parseInt(value)) ? '' : parseInt(value)
    }

    if (attr === 'duration_mode' && (value === 'forever' || value === 'once')) {
      setFormData({ ...formData, duration: '1', duration_mode: value })
      return
    }

    const error = validate(attr as any, value)

    setFormData({ ...formData, [attr]: value })
    setErrors({ ...errors, [attr]: error })
  }

  const onCountryChange = useCallback(
    (countryId: number) => {
      setFormData({ ...formData, country_id: countryId })
      setErrors({})
    },
    [formData]
  )

  return (
    <>
      <PageHeader title="Cupons" action="Novo" />

      <div className="flex flex-row space-x-8">
        <div className="w-3/5 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <FormSection title="Novo cupom">
              <Cards
                value={formData?.type}
                options={deepClone(couponTypes)}
                onChange={(type) => onAttributeChange('type', type)}
              />

              <InputWithValidationError
                label="Nome"
                name="name"
                value={formData?.name}
                onChange={(name) => onAttributeChange('name', name)}
                error={errors.name}
              />

              <SimpleCustomSelect
                label="País"
                options={countries}
                value={formData.country_id}
                onChange={(value) => onCountryChange(value)}
              />

              <SimpleCustomSelect
                label="Moeda"
                options={currencies}
                value={formData?.currency}
                onChange={(currency: any) =>
                  onAttributeChange('currency', currency)
                }
              />
            </FormSection>

            <InputWithValidationError
              label="Número de resgates máximo"
              name="redeems_remaining"
              value={formData.redeems_remaining}
              onChange={(value) =>
                onAttributeChange('redeems_remaining', value)
              }
              error={errors.redeems_remaining}
            />

            <div className="animate-fade-in-down mt-10">
              <FormSection title="Valores e Períodos">
                <div className="flex flex-row space-x-8">
                  <div className="w-1/3">
                    <SimpleCustomSelect
                      label="Tipo de desconto"
                      options={fineModes}
                      value={formData?.mode}
                      onChange={(mode) => onAttributeChange('mode', mode)}
                    />
                  </div>

                  <div className="w-1/3">
                    <InputWithAddon
                      label="Valor"
                      name="amount"
                      type="text"
                      pattern="[0-9]+([\.,][0-9]+)?"
                      value={formData?.amount}
                      frontAddon={
                        formData.mode === 'fixed'
                          ? currency(formData?.currency)
                          : '%'
                      }
                      onChange={(value) => onAttributeChange('amount', value)}
                      error={errors.amount}
                    />
                  </div>
                </div>

                <RadioListWithDescription
                  label="Modo de Aplicação"
                  options={couponDurationModes}
                  value={formData?.duration_mode}
                  onChange={(durationMode) =>
                    onAttributeChange('duration_mode', durationMode)
                  }
                />

                <div className="flex flex-row space-x-8">
                  {formData.duration_mode === 'repeating' && (
                    <div className="w-1/3">
                      <InputWithValidationError
                        label="Duração"
                        name="duration"
                        value={formData?.duration}
                        onChange={(duration) =>
                          onAttributeChange('duration', duration)
                        }
                        error={errors.duration}
                      />
                    </div>
                  )}
                  <div className="w-1/3">
                    <SimpleCustomSelect
                      label="Cobrança"
                      options={intervals}
                      value={formData?.interval}
                      onChange={(interval) =>
                        onAttributeChange('interval', interval)
                      }
                    />
                  </div>
                </div>

                <SimpleCustomSelect
                  label="Data de expiração"
                  options={yesOrNo}
                  value={expires}
                  onChange={(value: any) => {
                    if (value === 'no') {
                      setFormData({ ...formData, expires_at: null })
                    } else {
                      const selectedDate = setDateToUTC(
                        new Date(),
                        23,
                        59,
                        59,
                        999
                      )
                      setFormData({ ...formData, expires_at: selectedDate })
                    }
                    setExpires(value)
                  }}
                />

                {expires === 'yes' && (
                  <DatePickerWithError
                    label="Expira em"
                    name="expires_at"
                    date={formData.expires_at!}
                    minDate={new Date()}
                    onChange={(value) => {
                      const selectedDate = value
                        ? setDateToUTC(new Date(value), 23, 59, 59, 999)
                        : setDateToUTC(new Date(), 23, 59, 59, 999)
                      setFormData({ ...formData, expires_at: selectedDate })
                    }}
                  />
                )}
              </FormSection>
            </div>

            <div className="mt-12 flex justify-end animate-fade-in-down">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={() => history.goBack()}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
