import { CSVLink } from 'react-csv'

import { Role } from 'models'
import { chipModel, deviceModel } from '../static'
import { ProtectedSection } from 'components/ProtectedSection'

const model: Record<string, any> = {
  devices: deviceModel,
  chips: chipModel
}
interface EmptyContentProps {
  modelType: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function EmptyContent({ onChange, modelType }: EmptyContentProps) {
  return (
    <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
      <div className="mx-4 border rounded-lg h-48 flex justify-center animate-fade-in-down">
        <div className="flex flex-col justify-center">
          <h1 className="text-xl m-4 text-center">
            Escolha um arquivo para cadastro
          </h1>
          <p className="text-gray-500 text-xs text-center">
            Baixe o{' '}
            <button
              type="button"
              className="inline-flex items-center px-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              <CSVLink {...model[modelType]}>modelo</CSVLink>
            </button>{' '}
            da planilha. Insira os registros desejados com os campos
            correspondentes. <br />
            Em seguida, clique no botão &quot;Escolher arquivo&quot; para
            carregar a planilha com os items desejados.
          </p>
          <div className="mt-4 text-center">
            <label
              htmlFor="fileInput"
              className="px-4 py-1.5 ease-in-out shadow-sm rounded bg-gray-200 transition border border-solid border-gray-300 inline-block mb-2 text-gray-700 hover:bg-gray-300 cursor-pointer"
            >
              Escolher arquivo
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".csv"
              onChange={onChange}
              className="hidden"
            />
          </div>
        </div>
      </div>
    </ProtectedSection>
  )
}

// "block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
