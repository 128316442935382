import { createBrowserHistory } from 'history'
import { ToastContainer } from 'react-toastify'
import { Router, Switch } from 'react-router-dom'

import { fleetview } from 'services/fleetview'

import { Home } from 'modules/App'
import { AuthProvider } from 'modules/Auth/components/AuthProvider'

import { LoginRoute } from 'components/LoginRoute'
import { PrivateRoute } from 'components/PrivateRoute'
import { ConfirmationDialogProvider } from 'components/ConfirmationDialog'
import { PasswordReset } from 'components/PasswordReset'

const history = createBrowserHistory()

function App() {
  return (
    <div className="App">
      <AuthProvider axiosInstances={[fleetview]}>
        <ConfirmationDialogProvider>
          <Router history={history}>
            <Switch>
              <LoginRoute path="/login" />
              <PasswordReset path="/reset-password" />
              <PrivateRoute path="/">
                <Home history={history} />
              </PrivateRoute>
            </Switch>
          </Router>
        </ConfirmationDialogProvider>
      </AuthProvider>
      <ToastContainer className={'-mr-1'} autoClose={2500} />
    </div>
  )
}

export default App
