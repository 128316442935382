import config from 'config'
import { Chip } from 'models'
import { fleetview } from '../fleetview'
import { buildQueryString } from 'utils'
import { QueryOptions, ListResult, ReadResult } from 'interfaces/queryOptions'

export async function list(options: QueryOptions): Promise<ListResult<Chip>> {
  options.count = options.count ?? true
  options.limit = options.limit ?? config.items_per_list_page

  const optionString = buildQueryString(options)

  const response = await fleetview.get(`/v5/billing/chips${optionString}`)
  return response.data
}

export async function showFreeChip(
  options: Pick<QueryOptions, 'attributes' | 'includes' | 'filters'> = {}
): Promise<ReadResult<Chip> | undefined> {
  const optionsString = buildQueryString(options)

  const result = await fleetview.get(`/v5/billing/chips${optionsString}`)

  if (result.data.data.length === 0) return undefined

  const chip = result.data.data[0]

  chip.attributes = chip.attributes.attributes

  return chip
}

export async function createBatch(
  payload: Record<string, any>
): Promise<ListResult<Chip>> {
  const body = { data: payload }

  const result = await fleetview.post('/v5/billing/chips/stock', body)
  return result.data
}
