import { Modal } from 'components/Modal'

interface Props {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export function Info({ isOpen, setIsOpen }: Props) {
  return (
    <Modal open={isOpen} title="Informações" onClose={() => setIsOpen(!isOpen)}>
      <div>
        <p className="mt-2 text-sm text-gray-500">
          Chips podem ser cancelados ou retornados.
        </p>
        <p className="mt-2 text-sm text-gray-500">
          Chips alterados serão deletados da plataforma e não poderão mais ser
          alterados ou visualizados pelo cliente.
        </p>
      </div>
    </Modal>
  )
}
