import { StringOrNull, Validations } from 'types'

const patterns = {
  phone: /^[0-9() -]+$/,
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  softruck_email: /@softruck+/gi
}

const validations: Validations = {
  fullname: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'
      return null
    }
  },
  email: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      if (!value.match(patterns.email)) return 'O email deve ser válido'

      if (!value.match(patterns.softruck_email))
        return 'O email deve ser da Softruck.'

      return null
    }
  },
  language: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'

      const supportedLanguages = ['pt_BR', 'en_US', 'es_UY']

      if (!supportedLanguages.includes(value))
        return 'Idioma não suportado. Por favor, escolha entre "pt_BR", "en_US" ou "es_UY".'

      return null
    }
  },
  phone_number: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.phone)) return 'Somente números são aceitos'

      return null
    }
  }
}

export function validate(prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
