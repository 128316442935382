import DatePicker from 'react-datepicker'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { forwardRef } from 'react'
import { CalendarIcon } from '@heroicons/react/outline'

interface Props {
  label: string
  name: string
  date: Date | null
  minDate?: Date | null
  maxDate?: Date | null
  error?: string | null
  optional?: boolean
  onChange: (value: Date | null) => any
  [key: string]: any
}

const ButtonInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <>
    <div className="mt-1 relative rounded-md shadow-sm">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <CalendarIcon className="text-gray-500 h-5 w-5" />
      </div>
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        className="flex-1 w-full text-left pl-10 px-3 py-2.5 min-w-0 bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:border-emerald-500"
      >
        {value}
      </button>
    </div>
  </>
))

ButtonInput.displayName = 'ButtonInput'

export function DatePickerWithError({
  label,
  name,
  date,
  minDate,
  maxDate,
  onChange,
  error = null,
  optional,
  ...rest
}: Props) {
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="block text-left text-sm font-medium text-gray-700"
      >
        {label}
        {optional && (
          <span className="text-xs text-gray-400"> (Opcional) </span>
        )}
      </label>
      <DatePicker
        selected={date}
        minDate={minDate ?? new Date(14, 0, 1)}
        maxDate={maxDate}
        dateFormat={'dd/MM/yyyy'}
        onChange={(value) => onChange(value)}
        customInput={<ButtonInput />}
        formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1)}
        {...rest}
      />
      {error && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <ExclamationCircleIcon
            className="h-5 w-5 text-red-500"
            aria-hidden="true"
          />
        </div>
      )}
      {error && (
        <p
          className="absolute left-0 text-xs text-red-600"
          id={`${name}-error`}
        >
          {error}
        </p>
      )}
    </div>
  )
}
