import { EyeIcon } from '@heroicons/react/outline'
import { LoadingCircle } from 'components/Loading'
import { Pagination } from 'components/Pagination'
import config from 'config'
import { ReadResult } from 'interfaces/queryOptions'
import { Order, OrderStatus } from 'models'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { translations } from 'modules/Orders/translations'
import { translations as planTranslations } from 'modules/AccessPlans/translations'
import { translations as clientTranslations } from 'modules/Clients/translations'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { classNames, date } from 'utils'
import { Label } from 'components/Label'
import { getOrderStatusAndDate } from 'utils/order-status'

const maxItemsPerPage = config.items_per_list_page

export function SubscriptionsTable() {
  const { client, isLoadingSubscriptions, subscriptions, listSubscriptions } =
    useViewClient()

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [lastPage, setLastPage] = useState(1)

  const [data, setData] = useState<ReadResult<Order>[]>([])

  useEffect(() => {
    if (!client) return

    listSubscriptions(page)
  }, [listSubscriptions, client, page])

  useEffect(() => {
    if (!client || !subscriptions) return

    const results: ReadResult<Order>[] = []
    for (const subscription of subscriptions.data) {
      results.push(subscription)
    }

    setTotal(subscriptions.count)
    setData(results)
  }, [client, subscriptions])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [total])

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  return (
    <div className="">
      {isLoadingSubscriptions ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          CÓDIGO
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          ESTADO
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          PLANO
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          TIPO
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          COBRANÇA
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          PEDIDO EM
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        >
                          ATIVADO EM
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-light text-gray-500"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {data?.map((subscription, index) => (
                        <tr
                          key={subscription.id}
                          className={classNames(
                            index === 0 ? 'border-gray-300' : 'border-gray-200',
                            'border-t'
                          )}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div className="text-xs text-left font-semibold text-gray-500 truncate">
                              {subscription.attributes.token ?? '-'}
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            <div className="text-xs text-left font-semibold text-gray-500 truncate">
                              <Label
                                type="order_status"
                                label={
                                  translations['order_status'][
                                    subscription.attributes
                                      .current_status as OrderStatus
                                  ]
                                }
                                value={subscription.attributes.current_status}
                              />
                            </div>
                            <div className="ml-2 text-xs text-left text-gray-500">
                              {' '}
                              {date(
                                getOrderStatusAndDate(subscription).date as Date
                              )}{' '}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-sm text-left font-semibold text-gray-900 truncate max-w-xxs">
                              {subscription.relationships?.plan.attributes.name}
                            </div>
                            <div className="text-xs text-left text-gray-500">
                              {
                                translations['plan_nature'][
                                  subscription.relationships?.plan.attributes
                                    .nature
                                ]
                              }
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-sm text-left font-semibold text-gray-900 truncate">
                              {
                                planTranslations['type'][
                                  subscription.relationships?.plan.attributes
                                    .usage_type
                                ]
                              }
                            </div>
                            <div className="text-xs text-left text-gray-500">
                              {
                                clientTranslations['tier_mode'][
                                  subscription.relationships?.plan.attributes
                                    .tier_mode
                                ]
                              }
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {
                              planTranslations['billing_type'][
                                subscription.relationships?.plan.attributes
                                  .billing_type
                              ]
                            }
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {date(subscription.attributes.ordered_at)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {date(subscription.attributes.activated_at)}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <Link to={`/subscriptions/${subscription.id}/view`}>
                              <button
                                type="button"
                                className={
                                  'relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 rounded-md'
                                }
                              >
                                <EyeIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Pagination
            currentPage={page}
            pageSize={maxItemsPerPage}
            itemsOnPage={subscriptions?.data.length ?? 1}
            totalItems={total}
            onPageChange={onPageChange}
          />
        </>
      )}
    </div>
  )
}
