import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { EyeIcon, PencilIcon } from '@heroicons/react/outline'
import { useEffect, useState, useCallback, useMemo } from 'react'

import { Pagination } from 'components/Pagination'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'

import config from 'config'
import { translations } from '../translations'
import { Role } from 'models'
import { list } from '../services/api'
import { date, time, phone } from 'utils'
import { useURLParams } from 'hooks/useURLParams'
import { ReadResult } from 'interfaces/queryOptions'
import { SearchBar } from 'components/Search'
import { StaffMember } from 'models/staffMember'

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      search: ''
    }

    const search = query.get('search')

    if (search) params.search = search

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [sort] = useState({})
  const [search, setSearch] = useState(queryParams.search)

  const [total, setTotal] = useState(0)
  const [data, setData] = useState<ReadResult<StaffMember>[]>([])

  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(() => {
    async function loadData() {
      setIsLoading(true)

      const options = {
        includes: { role: ['name'] },
        limit: maxItemsPerPage,
        search,
        page,
        sort
      }

      try {
        const { count, data } = await list(options)
        setData(data)
        setTotal(count)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(true)
        toast.error('Falha ao buscar usuários')
        console.error(err)
      }
    }
    loadData()
  }, [maxItemsPerPage, page, search, sort])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Usuários" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[Role.MANAGER]}>
          <Link to="/staff-members/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div className="flex flex-col animate-fade-in-down">
          <div className="-my-2 overflow-x-auto px-4">
            <div className="py-2 align-middle inline-block min-w-full">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        USUÁRIO
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        PAPEL
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        CONTATO
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        IDIOMA
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        CRIADO EM
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        ATUALIZADO EM
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-sm font-light text-gray-500"
                      >
                        AÇÕES
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {data.map((staff, index) => (
                      <tr
                        key={staff.id}
                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                      >
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xxs">
                          <div
                            title={staff.attributes.fullname}
                            className="text-xs text-left font-semibold text-gray-900 truncate"
                          >
                            {staff.attributes.fullname ?? '-'}
                          </div>
                          <div className="text-xs text-left text-gray-500">
                            {staff.attributes.email ?? '-'}
                          </div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-xs text-left text-gray-900">
                            {
                              translations.role[
                                staff.relationships?.role.attributes
                                  .name as string
                              ]
                            }
                          </div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-xs text-left text-gray-900">
                            {phone(staff.attributes.phone_number)}
                          </div>
                        </td>
                        <td className="px-4 py-3 whitespace-nowrap">
                          <div className="text-xs text-left text-gray-900">
                            {
                              translations.languages[
                                staff.attributes.language as string
                              ]
                            }
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-xs text-left font-semibold text-gray-900">
                                {date(staff.attributes.created_at)}
                              </div>
                              <div className="text-xs text-left text-gray-500">
                                {time(staff.attributes.created_at)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                          <div className="flex">
                            <div className="flex-1">
                              <div className="text-xs text-left font-semibold text-gray-900">
                                {date(staff.attributes.updated_at)}
                              </div>
                              <div className="text-xs text-left text-gray-500">
                                {time(staff.attributes.updated_at)}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-auto">
                          <ProtectedSection
                            roles={[Role.MANAGER]}
                            id={staff.id.toString()}
                          >
                            <div className="flex flex-row">
                              <Link
                                to={`/staff-members/${staff.id}/view/general`}
                              >
                                <button
                                  type="button"
                                  className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                >
                                  <EyeIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </Link>
                              <Link
                                to={`/staff-members/${staff.id}/edit/general`}
                              >
                                <button
                                  type="button"
                                  className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                >
                                  <PencilIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              </Link>
                            </div>
                          </ProtectedSection>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="mx-4">
            <Pagination
              currentPage={page}
              pageSize={maxItemsPerPage}
              itemsOnPage={data.length}
              totalItems={total}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      )}
    </>
  )
}
