import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useState, ReactNode, useCallback } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

import { UsersTab } from './Users'
import { ClientTab } from './Client'
import { AddressesTab } from './Addresses'
import { EnterpriseTab } from './Enterprise'

import { classNames, deepClone } from 'utils'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'

type Tab = {
  id: string
  title: string
  current: boolean
  completed: boolean
  component: ReactNode
}

const defaultTabs = [
  {
    id: 'general',
    title: 'Cliente',
    current: false,
    completed: true,
    component: <ClientTab />
  },
  {
    id: 'address',
    title: 'Endereço',
    current: false,
    completed: true,
    component: <AddressesTab />
  },
  {
    id: 'enterprise',
    title: 'Empresa',
    current: false,
    completed: true,
    component: <EnterpriseTab />
  },
  {
    id: 'user',
    title: 'Usuários',
    current: false,
    completed: true,
    component: <UsersTab />
  }
]

export const EditTabs = () => {
  const { replace } = useHistory()

  const { clientId, editTab } = useParams() as unknown as {
    clientId: number
    editTab: string
  }

  const { client, fetchClient, wizard } = useEditClient()

  const [tabs, setTabs] = useState<Tab[]>(defaultTabs)
  const [active, setActive] = useState<Tab>()

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  useEffect(() => {
    if (!client || !editTab || !wizard) return

    const tabs: Tab[] = deepClone(defaultTabs)

    const index = tabs.findIndex((t) => t.id === editTab)
    tabs[index].current = true

    const wiz = wizard.split('')
    tabs.map((t, idx) => (t.completed = wiz[idx] === '1'))

    setTabs(tabs)
    setActive(tabs[index])
  }, [client, editTab, wizard])

  const onTabClick = useCallback(
    (selected: Tab) => {
      if (!clientId) return

      selected.current = true

      setActive(selected)

      replace(`/clients/${clientId}/edit/${selected.id}`)
    },
    [clientId, replace]
  )

  return (
    <>
      <div>
        <div className="sm:hidden"></div>
        <div className="hidden sm:block">
          <div>
            <nav
              className="flex flex-row w-10/12 border-b border-gray-200"
              aria-label="Tabs"
            >
              {tabs?.map((tab) => (
                <button
                  key={tab.title}
                  className={classNames(
                    tab.current
                      ? 'border-emerald-500 text-emerald-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap justify-between w-1/6 py-3 px-1 border-b-2 font-medium text-sm bg-white'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                  onClick={() => onTabClick(tab)}
                >
                  <div className="flex flex-row justify-center items-center space-x-1">
                    {!tab.completed && (
                      <ExclamationCircleIcon
                        className="min-h-fit h-4 min-w-fit w-4 text-amber-400"
                        aria-hidden="true"
                      />
                    )}
                    <div className="">{tab.title}</div>
                  </div>
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {active && active.component}
    </>
  )
}
