import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormSection } from 'components/Form'
import { PageHeader } from 'components/PageHeader'

import {
  InlineList,
  InputWithAddon,
  ResizibleTextArea,
  SimpleCustomSelect,
  CheckboxWithDescription,
  InputWithValidationError,
  RadioListWithDescription
} from 'components/FormInputs'
import { Cards } from 'components/FormInputs/Cards'
import { TierTable, CreateTier } from '../components/Tiers'
import {
  accessPlanTypes,
  billingTypes,
  currencies,
  fineModes,
  intervals,
  tierModes,
  yesOrNo
} from 'components/FormInputs/options'

import { Errors } from 'types'
import { amount, cleanAmount, currency, formatCurrency } from 'utils'
import { check } from 'utils/error'
import { show } from '../services/read'
import { validate } from '../validation'
import { AccessPlanForm } from '../types'

import { Plan, UsageType, Currency, Role, Tier } from 'models'
import { ProtectedSection } from 'components/ProtectedSection'
import { Destroy } from 'components/Buttons/Destroy'
import { destroy } from '../services/delete'
import { LoadingCircle } from 'components/Loading'
import { edit } from '../services/update'

export function Edit() {
  const { accessId } = useParams() as unknown as { accessId: number }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)
  const [formData, setFormData] = useState<AccessPlanForm>({})

  const [setupFeeInstallmentFriendly, setSetupFeeInstallmentFriendly] =
    useState('no')

  const [tiers, setTiers] = useState<Tier[]>([])
  const [errors, setErrors] = useState<Errors>({})

  const onSave = async () => {
    if (!formData) return

    formData.tiers = tiers
    formData.usage_type = undefined

    const transformAttributes = (
      attributes: Record<string, any>
    ): Record<string, any> => {
      const {
        minimum_invoice_amount,
        fine_mode,
        fine_amount,
        tier_mode,
        tiers
      } = attributes

      return {
        minimum_invoice_amount:
          minimum_invoice_amount === ''
            ? null
            : cleanAmount(minimum_invoice_amount),
        fine_mode: fine_amount === '' ? null : fine_mode,
        fine_amount:
          fine_amount === ''
            ? 0
            : fine_mode === null
            ? 0
            : cleanAmount(fine_amount),
        tier_mode:
          tiers === null ? null : tiers?.length === 0 ? null : tier_mode,
        tiers:
          tier_mode === null || (tiers && tiers.length === 0) ? null : tiers
      }
    }

    if (validateFields(formData) || check(errors)) {
      toast.error('Corrija os erros antes de prosseguir')
      return
    }

    const otherAttributes = transformAttributes(formData)

    if (
      !formData.setup_fee_max_installments ||
      setupFeeInstallmentFriendly === 'no'
    ) {
      delete formData.setup_fee_max_installments
    }

    try {
      await edit(accessId, {
        ...formData,
        setup_fee: cleanAmount(formData.setup_fee),
        ...otherAttributes
      })
      toast.success('Plano editado com sucesso')
      history.push('/access-plans')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao tentar editar o plano'
      )
    }
  }

  const validateFields = (plan: AccessPlanForm) => {
    if (!plan || !plan.usage_type) return

    let isInvalid = false

    const isNameFilled = !!(
      Object.prototype.hasOwnProperty.call(plan, 'name') && plan.name !== null
    )
    if (!isNameFilled) {
      errors.name = !isNameFilled ? 'O campo deve ser preenchido' : null
      isInvalid = true
    }

    const isDurationFilled = !!(
      Object.prototype.hasOwnProperty.call(plan, 'duration') &&
      plan.duration !== null
    )
    const isIntervalFilled = !!(
      Object.prototype.hasOwnProperty.call(plan, 'interval_count') &&
      plan.interval_count !== null
    )

    if (!isDurationFilled || !isIntervalFilled) {
      errors.amount = !isIntervalFilled ? 'O campo deve ser preenchido' : null
      errors.duration = !isDurationFilled ? 'O campo deve ser preenchido' : null
      isInvalid = true
    }

    if (plan.usage_type === UsageType.licensed) {
      const isAmountFilled = !!(
        Object.prototype.hasOwnProperty.call(plan, 'amount') &&
        plan.amount !== null
      )
      errors.amount = !isAmountFilled ? 'O campo deve ser preenchido' : null
    }

    if (plan.usage_type === UsageType.tiered && tiers.length <= 0) {
      errors.tiers = 'Adicione faixas antes de continuar'
      isInvalid = true
    }

    setErrors({ ...errors })

    return isInvalid
  }

  const onAttributeChange = (
    attr: keyof Plan | keyof Partial<AccessPlanForm>,
    value: any
  ) => {
    if (!formData) return

    validate(attr as any, value)

    if (attr === 'fine_amount' || attr === 'setup_fee') {
      value = formatCurrency(value)
    }

    if (
      (attr === 'setup_fee' && value === '0,00') ||
      (attr === 'setup_fee_max_installments' && value === '')
    ) {
      setSetupFeeInstallmentFriendly('no')
    }

    setFormData({ ...formData, [attr]: value })
  }

  const fetch = useCallback(
    async (id: number) => {
      try {
        const data = await show(id)

        const planSetupFeeMaxInstallments =
          data.meta?.installments?.find(
            (installment: any) => installment.type === 'setup_fee'
          )?.requirements?.max_installment_number ?? 0

        if (planSetupFeeMaxInstallments) {
          setSetupFeeInstallmentFriendly('yes')
        }

        setFormData({
          name: data.name,
          description: data.description,
          currency: data.currency,
          amount: amount(data.amount),
          usage_type: data.usage_type,
          duration: data.duration,
          interval: data.interval,
          interval_count: data.interval_count,
          billing_type: data.billing_type,
          renewable: data.renewable,
          restricted: data.restricted,
          prorata: data.prorata,
          minimum_invoice_amount: amount(data.minimum_invoice_amount),
          fine_mode: data.fine_mode,
          fine_amount: amount(data.fine_amount),
          tier_mode: data.tier_mode,
          tiers: data.tiers,
          setup_fee: amount(data.setup_fee),
          ...(planSetupFeeMaxInstallments && {
            setup_fee_max_installments: planSetupFeeMaxInstallments
          })
        })

        if (data.tiers) {
          setTiers(data.tiers)
        }

        setIsLoading(false)
      } catch (err: any) {
        setIsLoading(false)
        toast.error('Falha ao recuperar dados do plano')
      }
    },
    [show, setFormData, setTiers, setIsLoading]
  )

  useEffect(() => {
    if (!accessId) return
    fetch(accessId)
  }, [accessId])

  return (
    <>
      <PageHeader title="Planos de acesso" action="Edição" />

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div className="flex flex-row space-x-8 animate-fade-in-down">
          <div className="w-3/5 px-4">
            <form
              onSubmit={(e) => {
                e.preventDefault()
                onSave()
              }}
            >
              <FormSection title="Plano de acesso">
                <Cards
                  value={formData?.usage_type}
                  options={[
                    accessPlanTypes.find(
                      (ops) => ops.value === formData.usage_type
                    ) ?? accessPlanTypes[0]
                  ]}
                  onChange={(type) => onAttributeChange('usage_type', type)}
                />

                {formData.usage_type && (
                  <>
                    <InputWithValidationError
                      label="Nome"
                      name="name"
                      value={formData?.name}
                      onChange={(name) => onAttributeChange('name', name)}
                      error={errors.name}
                    />

                    <ResizibleTextArea
                      label="Descrição"
                      name="description"
                      value={formData?.description}
                      onChange={(description) =>
                        onAttributeChange('description', description)
                      }
                    />

                    <fieldset className="space-y-5">
                      <legend className="text-left">Extensão de prazos</legend>
                      <CheckboxWithDescription
                        label="Renovável"
                        name="renewable"
                        checked={formData?.renewable ?? false}
                        onChange={(renewable) =>
                          onAttributeChange('renewable', renewable)
                        }
                        description="Se o plano permite renovação este campo deverá ser ativado, lembrando que comodatos de rastreadores não devem ter essa condição como ativada"
                      />
                    </fieldset>
                  </>
                )}
              </FormSection>

              {
                <>
                  <FormSection title="Valores e Períodos">
                    {[UsageType.licensed, UsageType.metered].includes(
                      formData.usage_type!
                    ) && (
                      <div className="flex flex-row space-x-8">
                        <div className="w-1/3">
                          <SimpleCustomSelect
                            label="Moeda"
                            options={currencies}
                            value={formData.currency ?? Currency.brl}
                            onChange={(currency) =>
                              onAttributeChange('currency', currency)
                            }
                          />
                        </div>

                        <div className="w-1/3">
                          <InputWithAddon
                            label="Valor"
                            name="amount"
                            type="text"
                            pattern="[0-9]+([\.,][0-9]+)?"
                            value={formData.amount ?? ''}
                            frontAddon={currency(formData?.currency)}
                            onChange={(value) =>
                              onAttributeChange('amount', value)
                            }
                            error={errors.amount}
                          />
                        </div>
                      </div>
                    )}
                    {formData.usage_type === UsageType.tiered && (
                      <>
                        <div className="w-1/3">
                          <SimpleCustomSelect
                            label="Moeda"
                            options={currencies}
                            value={formData?.currency}
                            onChange={(currency) =>
                              onAttributeChange('currency', currency)
                            }
                          />
                        </div>

                        <InlineList
                          label="Modo de faixas"
                          description="Definem como será feita a cobrança em cada faixa."
                          value={formData.tier_mode}
                          options={tierModes}
                          onChange={(tierMode) =>
                            onAttributeChange('tier_mode', tierMode)
                          }
                        />

                        <CreateTier
                          selectedCurrency={formData?.currency}
                          tiers={tiers}
                          setTiers={setTiers}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      </>
                    )}
                    {
                      <>
                        <div className="flex flex-row space-x-8">
                          <div className="w-1/3">
                            <InputWithAddon
                              label="Taxa de adesão"
                              name="setup_fee"
                              type="text"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              value={formData?.setup_fee}
                              frontAddon={currency(formData?.currency)}
                              onChange={(value) =>
                                onAttributeChange('setup_fee', value)
                              }
                            />
                          </div>

                          {formData?.setup_fee !== '0,00' && (
                            <div className="w-1/3">
                              <SimpleCustomSelect
                                label="Parcelável"
                                options={yesOrNo}
                                value={setupFeeInstallmentFriendly}
                                onChange={(value) =>
                                  setSetupFeeInstallmentFriendly(value)
                                }
                              />
                            </div>
                          )}

                          {formData?.setup_fee !== '0,00' && (
                            <div className="w-1/3">
                              <InputWithValidationError
                                label="Número máximo de parcelas"
                                type="number"
                                name="quantity"
                                min="1"
                                max="120"
                                value={
                                  formData?.setup_fee_max_installments ?? 1
                                }
                                onChange={(value) =>
                                  onAttributeChange(
                                    'setup_fee_max_installments',
                                    value
                                  )
                                }
                                error={errors.quantity}
                                onWheel={(
                                  e: React.FocusEvent<HTMLInputElement>
                                ) => e.target.blur()}
                              />
                            </div>
                          )}
                        </div>

                        <InputWithAddon
                          label="Valor mínimo da fatura"
                          name="minimum_invoice_amount"
                          type="text"
                          pattern="[0-9]+([\.,][0-9]+)?"
                          value={formData?.minimum_invoice_amount}
                          frontAddon={currency(formData?.currency)}
                          onChange={(value) =>
                            onAttributeChange('minimum_invoice_amount', value)
                          }
                        />

                        <div className="flex flex-row space-x-8">
                          <div className="w-1/3">
                            <SimpleCustomSelect
                              label="Tipo de multa"
                              options={fineModes}
                              value={formData?.fine_mode}
                              onChange={(fineModes) =>
                                onAttributeChange('fine_mode', fineModes)
                              }
                            />
                          </div>

                          <div className="w-1/3">
                            <InputWithAddon
                              label="Valor da multa"
                              name="fine_amount"
                              type="text"
                              pattern="[0-9]+([\.,][0-9]+)?"
                              value={formData?.fine_amount}
                              frontAddon={
                                formData.fine_mode === 'fixed'
                                  ? currency(formData?.currency)
                                  : '%'
                              }
                              onChange={(value) =>
                                onAttributeChange('fine_amount', value)
                              }
                            />
                          </div>
                        </div>
                      </>
                    }
                    <RadioListWithDescription
                      label="Modo de Fatura"
                      options={billingTypes}
                      value={formData?.billing_type}
                      onChange={(billingType) =>
                        onAttributeChange('billing_type', billingType)
                      }
                    />

                    <div className="flex flex-row space-x-8">
                      <div className="w-1/3">
                        <InputWithValidationError
                          label="Duração em meses"
                          name="duration"
                          value={formData?.duration}
                          onChange={(duration) =>
                            onAttributeChange('duration', duration)
                          }
                          error={errors.duration}
                        />
                      </div>
                      <div className="w-1/3">
                        <SimpleCustomSelect
                          label="Cobrança"
                          options={intervals}
                          value={formData?.interval}
                          onChange={(interval) =>
                            onAttributeChange('interval', interval)
                          }
                        />
                      </div>
                      <div className="w-1/3">
                        <InputWithValidationError
                          label="Intervalo"
                          name="interval_count"
                          value={formData?.interval_count}
                          onChange={(intervalCount) =>
                            onAttributeChange('interval_count', intervalCount)
                          }
                          error={errors.interval_count}
                        />
                      </div>
                    </div>
                  </FormSection>

                  <FormSection title="Restrições">
                    <fieldset className="space-y-5">
                      <legend className="text-left">
                        Aplicação de descontos
                      </legend>
                      <CheckboxWithDescription
                        label="Restrito"
                        name="restricted"
                        checked={formData?.restricted ?? false}
                        onChange={(restricted) =>
                          onAttributeChange('restricted', restricted)
                        }
                        description="Planos restritos não podem ser aplicados nenhum tipo de desconto"
                      />
                    </fieldset>

                    <fieldset className="space-y-5" disabled>
                      <legend className="text-left">Pro rata</legend>
                      <CheckboxWithDescription
                        label="Pro rata"
                        name="prorata"
                        checked={formData?.prorata ?? false}
                        onChange={(prorata) =>
                          onAttributeChange('prorata', prorata)
                        }
                        description="Planos com pro rata levarão em conta cada dia de utilização na geração das faturas"
                        readOnly
                      />
                    </fieldset>
                  </FormSection>
                </>
              }
              {formData.usage_type && (
                <div className="mt-12 flex justify-end">
                  <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
                    <div className="flex px-4">
                      <Destroy
                        id={accessId}
                        destroy={destroy}
                        successMessage="Plano excluído com sucesso"
                        onDestroy={() => history.push('/access-plans')}
                      />
                    </div>
                  </ProtectedSection>
                  <button
                    type="button"
                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                    onClick={() => history.goBack()}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none"
                  >
                    Salvar
                  </button>
                </div>
              )}
            </form>
          </div>

          <div className="w-1/3 relative">
            <div className="sticky top-0">
              {tiers.length > 0 && (
                <div className="pt-10">
                  <TierTable
                    currency={formData?.currency ?? Currency.brl}
                    tiers={tiers}
                    setTiers={setTiers}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
