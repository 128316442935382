import { Translations } from 'types'

export const translations: Translations = {
  type: {
    metered: 'Por consumo',
    licensed: 'Licença',
    tiered: 'Faixa'
  },
  billing_type: {
    postpaid: 'Pós-pago',
    prepaid: 'Pré-pago'
  },
  interval: {
    day: 'Diária',
    week: 'Semanal',
    month: 'Mensal',
    year: 'Anual'
  },
  order_status: {
    pending: 'Pendente',
    approved: 'Aprovado',
    delivered: 'Entregue',
    canceled: 'Cancelado'
  },
  subscription_status: {
    pending: 'Pendente',
    approved: 'Aprovado',
    delivered: 'Ativado',
    canceled: 'Cancelado'
  },
  tier_mode: {
    discount: 'Desconto',
    volume: 'Volume',
    package: 'Pacote'
  },
  nature: {
    service: 'Serviço',
    good: 'Bem'
  }
}
