import { Fragment, useRef, useState, ReactNode } from 'react'
import { TrashIcon } from '@heroicons/react/solid'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { toast } from 'react-toastify'

import { SimpleButton } from '../FormInputs/Button'

interface Props {
  id: number | string
  complementaryId?: number | string
  destroy: (
    id: number | string,
    complementaryId?: number | string
  ) => Promise<void>
  successMessage: string
  hint?: string
  children?: ReactNode
  onDestroy?: () => any
}

export function Destroy({
  id,
  complementaryId,
  destroy,
  successMessage,
  hint = 'Excluir',
  children = <TrashIcon className="h-6 w-6" aria-hidden="true" />,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDestroy = () => {}
}: Props) {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  const doDestroy = async () => {
    try {
      if (complementaryId) {
        await destroy(id, complementaryId)
      } else {
        await destroy(id)
      }

      toast.success(successMessage)
      onDestroy()
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Não foi possível completar a operação'
      )
    }
  }

  return (
    <>
      <SimpleButton
        title={hint}
        onClick={() => setOpen(true)}
        className="flex relative items-center px-4 p-2 text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
      >
        {children}
      </SimpleButton>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Excluir
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tem certeza que deseja realizar esta operação?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpen(false)
                      doDestroy()
                    }}
                  >
                    Excluir
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
