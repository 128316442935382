import { CheckIcon } from '@heroicons/react/solid'
import { Step } from '../../types'
import { date } from 'utils'

export function StepPanel({
  steps,
  statusDates
}: {
  steps: Step[]
  statusDates?: Record<string, any>
}) {
  return (
    <div className="px-4 animate-fade-in-down">
      <nav aria-label="Progress">
        <ol className="shadow border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
          {steps.map((step, index) => (
            <li key={step.name} className="relative md:flex-1 md:flex">
              {step.status === 'complete' ? (
                <div className="group p-2 flex flex-col items-center justify-center w-full relative">
                  <span className="p-2 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-emerald-600 rounded-full">
                      <CheckIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-900">
                      {step.name}
                    </span>
                  </span>
                  {statusDates && statusDates[step.label] && (
                    <div className="text-xs text-left text-gray-500">
                      {date(statusDates[step.label] as Date)}{' '}
                    </div>
                  )}
                </div>
              ) : step.status === 'current' ? (
                <div
                  className="px-6 py-4 flex flex-col items-center justify-center w-full relative text-sm font-medium"
                  aria-current="step"
                >
                  <span className="p-2 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-emerald-600 rounded-full">
                      <span className="text-emerald-600">{step.id}</span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-emerald-600">
                      {step.name}
                    </span>
                  </span>
                  {statusDates && statusDates[step.label] && (
                    <div className="text-xs text-left text-gray-500">
                      {date(statusDates[step.label] as Date)}{' '}
                    </div>
                  )}
                </div>
              ) : (
                <div className="px-6 py-4 group flex flex-col items-center justify-center w-full relative items-center">
                  <span className="p-2 flex items-center text-sm font-medium">
                    <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full">
                      <span className="text-gray-500 group-hover:text-gray-900">
                        {step.id}
                      </span>
                    </span>
                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                      {step.name}
                    </span>
                  </span>
                  <div className="text-xs text-left text-gray-500">-</div>
                </div>
              )}

              {index !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="hidden md:block absolute top-0 right-0 h-full w-5"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}
