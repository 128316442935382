const config = {
  search_debounce_rate: 500,
  autocomplete_debounce_rate: 1200,
  items_per_list_page: 25,
  max_pages_per_list_navigation: 10,
  min_search_length: 2,
  fleetview_base_url: process.env.REACT_APP_BASE_URL,
  max_concurrent_validations: 5,
  google_key: process.env.GOOGLE_KEY,
  account_role_id: process.env.REACT_APP_ACCOUNT_ROLE_ID
}

export default config
