import { ReactNode } from 'react'
import { RadioGroup } from '@headlessui/react'
import { classNames } from 'utils'

interface RadioListWithDescriptionProps {
  label: ReactNode
  options: { label: string; value: string; description?: string }[]
  value?: string
  onChange?: (value: string) => any
}

export function RadioListWithDescription({
  label,
  options,
  value,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {}
}: RadioListWithDescriptionProps) {
  const selected =
    options.find((option) => option.value === value) ?? options[0]

  return (
    <RadioGroup
      value={selected}
      onChange={(option) => onChange(option.value)}
      className="text-left"
    >
      <RadioGroup.Label>{label}</RadioGroup.Label>
      <div className="bg-white rounded-md -space-y-px mt-1">
        {options.map((option, idx) => (
          <RadioGroup.Option
            key={option.label}
            value={option}
            className={({ checked }) =>
              classNames(
                idx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                idx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked
                  ? 'bg-emerald-50 border-emerald-200'
                  : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-emerald-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-emerald-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-emerald-900' : 'text-gray-900',
                      'block text-sm font-medium'
                    )}
                  >
                    {option.label}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? 'text-emerald-700' : 'text-gray-500',
                      'block text-sm'
                    )}
                  >
                    {option.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
