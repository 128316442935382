interface Props {
  label: string
  name: string
  onChange: (value: string) => any
  error?: string | null
  value?: any
  type?: string
  frontAddon: string
  backAddon?: string
  [key: string]: any
}

function calculatePaddingLeft(text: string) {
  if (!text || text === '') return 'pl-3'

  if (text.length === 1) return 'pl-8'

  if (text.length === 2) return 'pl-10'

  if (text.length === 3) return 'pl-12'

  if (text.length === 4) return 'pl-14'

  return 'pl-20'
}

export function InputWithAddon({
  label,
  name,
  type,
  value,
  onChange,
  frontAddon,
  backAddon,
  error,
  optional = false,
  ...rest
}: Props) {
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="block text-left text-sm font-medium text-gray-700"
      >
        {label}
        {optional && (
          <span className="text-xs text-gray-400"> (Opcional) </span>
        )}
      </label>
      <div className="relative mt-1 flex rounded-md shadow-sm">
        <span className="absolute left-0 inset-y-0 inline-flex items-center px-3 text-gray-400 bg-transparent">
          {frontAddon}
        </span>
        <input
          type={type}
          name={name}
          id={name}
          autoComplete="off"
          value={value ?? ''}
          onChange={(e) => onChange(e.currentTarget.value)}
          className={
            calculatePaddingLeft(frontAddon) +
            ' flex-1 min-w-0 block w-full px-3 py-2 border rounded-md focus:outline-none' +
            (error
              ? ' border-red-300 focus:border-red-500'
              : ' border-gray-300 focus:border-emerald-500')
          }
          {...rest}
        />
        {backAddon && (
          <span className="absolute right-0 inset-y-0 inline-flex items-center px-3 bg-transparent text-gray-400">
            {backAddon}
          </span>
        )}
      </div>
      {error && (
        <p
          className="absolute left-0 text-xs text-red-600"
          id={`${name}-error`}
        >
          {error}
        </p>
      )}
    </div>
  )
}
