import { useCallback, useEffect, useState } from 'react'
import {
  MailIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon
} from '@heroicons/react/outline'

import { LoadingCircle } from 'components/Loading'
import { Attention } from './components/Attention'
import { CreateUser } from './components/CreateUser'
import { UpdateUser } from './components/UpdateUser'
import { useConfirmationDialog } from 'components/ConfirmationDialog'

import { User } from 'models'
import { classNames, phone } from 'utils'
import { ReadResult } from 'interfaces/queryOptions'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { SendEmail } from './components/SendEmail'

export function UsersTab() {
  const {
    isLoadingUsers,
    client,
    fetchClient,
    enterprise,
    fetchEnterprise,
    users,
    listUsers,
    deleteUser
  } = useEditClient()

  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isSendEmailOpen, setIsSendEmailOpen] = useState(false)

  const [isEnterpriseCreated, setIsEnterpriseCreated] = useState(false)

  const [index, setIndex] = useState(0)

  const [showConfirmationDialog] = useConfirmationDialog()

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  useEffect(() => {
    if (!client) return

    if (!enterprise) {
      fetchEnterprise()
    }
  }, [client, enterprise, fetchEnterprise])

  useEffect(() => {
    if (!client || !enterprise) return

    if (!users) {
      listUsers()
    }

    setIsEnterpriseCreated(client.relationships?.enterprise?.id && enterprise)
  }, [client, enterprise, listUsers, users])

  const onDelete = useCallback(
    async (id: number) => {
      if (!users) return

      setIndex(0)

      await deleteUser(id)
    },
    [deleteUser, users]
  )

  const onRemoveUser = (id: number) => {
    showConfirmationDialog({
      title: 'Remover usuário',
      message: 'Tem certeza que deseja deletar o usuário?',
      onConfirm: () => onDelete(id)
    })
  }

  return (
    <>
      <div className="flex flex-row justify-between mt-10 animate-fade-in-down">
        <div className="w-10/12 px-4">
          <div>
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h3 className="text-xl font-medium text-gray-900">
                  Administradores
                </h3>
                <p className="mt-2 text-sm text-gray-700">
                  Todos os usuários administradores cadastrados
                </p>
              </div>
              {!isLoadingUsers && isEnterpriseCreated && (
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none animate-fade-in-down">
                  <button
                    type="button"
                    onClick={() => setIsCreateOpen(!isCreateOpen)}
                    className="h-9 w-full flex items-center justify-center text-sm font-medium rounded-md text-emerald-600 border border-emerald-600 hover:bg-emerald-600 hover:text-white"
                  >
                    <div className="flex flex-row items-center justify-center px-2">
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      <div className="mr-2 ml-1">Adicionar usuário </div>
                    </div>
                  </button>
                </div>
              )}
            </div>

            {isLoadingUsers ? (
              <LoadingCircle />
            ) : !isEnterpriseCreated ? (
              <Attention />
            ) : (
              users &&
              users.length > 0 && (
                <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 md:rounded-lg animate-fade-in-down">
                  <table className="min-w-full divide-y divide-gray-300">
                    <tbody>
                      {users.map((user: ReadResult<User>, idx: number) => (
                        <tr key={idx}>
                          <td
                            className={classNames(
                              idx === 0 ? '' : 'border-t border-transparent',
                              'relative py-4 pl-4 sm:pl-6 pr-3 text-sm'
                            )}
                          >
                            <div className="font-medium text-gray-900">
                              {user.attributes.name}
                            </div>
                            {idx !== 0 ? (
                              <div className="absolute right-0 left-6 -top-px h-px bg-gray-200" />
                            ) : null}
                          </td>
                          <td
                            className={classNames(
                              idx === 0 ? '' : 'border-t border-gray-200',
                              'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                            )}
                          >
                            {user.attributes.email}
                          </td>
                          <td
                            className={classNames(
                              idx === 0 ? '' : 'border-t border-gray-200',
                              'hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell'
                            )}
                          >
                            {phone(user.attributes.phone1)}
                          </td>
                          <td
                            className={classNames(
                              idx === 0 ? '' : 'border-t border-transparent',
                              'relative py-3.5 pl-3 pr-4 sm:pr-6 text-right text-sm font-medium'
                            )}
                          >
                            <div className="flex flex-row justify-end">
                              <button
                                type="button"
                                onClick={() => {
                                  setIndex(idx)
                                  setIsEditOpen(true)
                                }}
                                className={
                                  '-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 rounded-l-md'
                                }
                              >
                                <PencilIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setIndex(idx)
                                  setIsSendEmailOpen(true)
                                }}
                                className={`-ml-px relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 ${
                                  users && users.length > 1
                                    ? ''
                                    : 'rounded-r-md'
                                }`}
                              >
                                <MailIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                              {users && users.length > 1 && (
                                <button
                                  type="button"
                                  onClick={() => onRemoveUser(idx)}
                                  className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-red-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                >
                                  <TrashIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </button>
                              )}
                            </div>
                            {idx !== 0 ? (
                              <div className="absolute right-6 left-0 -top-px h-px bg-gray-200" />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <CreateUser open={isCreateOpen} setOpen={setIsCreateOpen} />
      <UpdateUser open={isEditOpen} setOpen={setIsEditOpen} index={index} />
      <SendEmail
        open={isSendEmailOpen}
        setOpen={setIsSendEmailOpen}
        index={index}
      />
    </>
  )
}
