import { useContext } from 'react'
import { ConfirmationContext, ShowConfirmationDialogParams } from './context'

export function useConfirmationDialog(): [
  (params: ShowConfirmationDialogParams) => any
] {
  const context = useContext(ConfirmationContext)

  return [context.showConfirmationDialog]
}
