import { useHistory } from 'react-router-dom'

import { Destroy } from 'components/Buttons/Destroy'
import { destroy, destroyPermission } from 'modules/Staff/services/api'

export function DestroyButton({ id }: { id: string | number }) {
  const history = useHistory()

  return (
    <Destroy
      id={id}
      destroy={destroy}
      successMessage="Usuário excluído com sucesso"
      onDestroy={() => history.push('/staff-members')}
    />
  )
}

export function DestroyPermissionButton({
  staffId,
  clientId
}: {
  staffId: string | number
  clientId: string | number
}) {
  const history = useHistory()

  return (
    <Destroy
      id={staffId}
      complementaryId={clientId}
      destroy={destroyPermission}
      successMessage="Permissão revogada com sucesso"
      onDestroy={() => {
        history.push(`/staff-members/${staffId}/view/clients`)
        window.location.reload()
      }}
    />
  )
}
