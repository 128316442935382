import {
  notEmpty,
  notNullNonNegativeInteger,
  notNullPositiveInteger
} from 'utils/validations'

type StringOrNull = string | null

type Validations = {
  [key: string]: { validate: (value: StringOrNull) => StringOrNull }
}

const validations: Validations = {
  name: { validate: notEmpty },
  amount: { validate: notNullNonNegativeInteger },
  minimum_invoice_amount: { validate: notNullNonNegativeInteger },
  fine_amount: { validate: notNullNonNegativeInteger },
  duration: { validate: notNullPositiveInteger },
  interval_count: { validate: notNullPositiveInteger }
}

export function validate(prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
