import { useState } from 'react'

export type AuthData = null | {
  email: string
  password: string
}

type AuthDataHook = [AuthData, (data: AuthData) => void]

export function useAuthData(): AuthDataHook {
  const [authData, setAuthData] = useState<AuthData>(null)

  const updateAuthData = (data: AuthData): void => {
    setAuthData(data)
  }

  return [authData, updateAuthData]
}
