import { StringOrNull, Validations } from 'types'

const patterns = {
  phone: /^[0-9() -]+$/,
  domain: /^[a-z0-9]+$/,
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  softruck_email: /@softruck+/gi,
  color: /^#(?:[0-9a-fA-F]{3}){1,2}$/
}

const validations: Validations = {
  account_name: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'
      return null
    }
  },
  email: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return 'O campo não pode ser vazio.'

      if (!value.match(patterns.email)) return 'O email deve ser valido'

      if (value.match(patterns.softruck_email))
        return 'O email não deve ser da Softruck.'

      return null
    }
  },
  billing_cycle_day: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'

      const number = parseInt(value)

      if (number < 1 || number > 31)
        return 'O dia de pagamento deve ser entre 1 e 31.'
      return null
    }
  },
  phone: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.phone)) return 'Somente números são aceitos'

      return null
    }
  },
  user_email: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.email)) return 'O email deve ser válido.'
      return null
    }
  },
  domain: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.domain))
        return 'O domínio só pode conter minúsculas e números.'

      return null
    }
  },
  color1: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.color))
        return 'Formato inválido para cor. Ex: #1a2b3c'
      return null
    }
  },
  color2: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.color))
        return 'Formato inválido para cor. Ex: #1a2b3c'
      return null
    }
  }
}

export function validate(prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
