export type DocumentType = 'cpf' | 'cnpj' | 'rut'

export type ClientType = 'enterprise' | 'society' | 'cooperative' | 'individual'

export type CorporateType = 'matriz' | 'filial'

export type ClientSegment =
  | 'transportation'
  | 'education'
  | 'government'
  | 'retail'
  | 'services'
  | 'trucking'
  | 'construction'
  | 'energy'
  | 'food'
  | 'healthcare'
  | 'tracking'
  | 'other'

export type WizardSteps =
  | 'client'
  | 'address'
  | 'subscription'
  | 'enterprise'
  | 'customization'
  | 'user'

export interface Phone {
  phone: string
  type?: string
  responsible?: string
}

export interface CorporateActivity {
  text: string
  code: string
}

export interface CorporateStructure {
  name: string
  document: string
  document_type: DocumentType
  email: string
  phone: string
  role: string
}

export interface CorporateData {
  corporate_name?: string
  corporate_type?: CorporateType
  share_capital?: string
  legal_nature?: string
  situation?: string
  mien?: string
  main_activity?: CorporateActivity[]
  corporate_structure?: CorporateStructure[]
}

export enum ClientStatus {
  pending = 'pending',
  active = 'active',
  not_renewed = 'not_renewed',
  overdue = 'overdue',
  restricted = 'restricted',
  canceled = 'canceled'
}

export interface Client {
  id: number
  account_name: string
  fantasy_name: string
  description: string
  document: string
  document_type: DocumentType
  email: string
  phones: Phone[]
  type: ClientType
  billing_cycle_day: number
  country_id?: number
  source?: string
  timezone: string
  picture_id?: number
  corporate_data?: CorporateData
  created_at: Date
  updated_at: Date
  deleted_at?: Date
  enterprise_id?: number
  segment: ClientSegment
  wizard: string
  relationships?: Record<string, any>
}
