interface SelectBoxWithDescriptionProps {
  label: string
  name: string
  checked?: boolean
  description?: string
  onChange?: (value: boolean) => any
  [key: string]: any
}

export function CheckboxWithDescription({
  label,
  name,
  checked,
  description,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {},
  ...rest
}: SelectBoxWithDescriptionProps) {
  return (
    <div className="flex justify-start px-4">
      <input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        className="flex-shrink-0 mt-1 h-4 w-4 border-gray-300 rounded text-emerald-600"
        onChange={() => onChange(!checked)}
        {...rest}
      />
      <div className="ml-3 text-left text-md">
        <label htmlFor={name} className="font-medium text-gray-700">
          {label}
        </label>
        <p id={`${name}-description`} className="text-gray-500">
          {description}
        </p>
      </div>
    </div>
  )
}
