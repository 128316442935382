import { ListResult, QueryOptions } from 'interfaces/queryOptions'
import { Invoice } from 'models/invoice'
import { list as baseList } from 'services/billing'

export async function list(
  options: QueryOptions = {}
): Promise<ListResult<Invoice>> {
  const response = await baseList<Invoice>('/invoices', options)
  return response
}
