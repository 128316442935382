import { useCallback, useEffect, useState } from 'react'

import { Close, Button } from 'components/Buttons'
import { DocumentInput } from '../../../Inputs/Document'
import { IdentificationTable } from '../../../Identification'
import {
  InputWithAddon,
  InputWithValidationError,
  RadioListWithDescription,
  ResizibleTextArea,
  SimpleCustomSelect
} from 'components/FormInputs'

import { Errors } from 'types'
import { check } from 'utils/error'
import { document } from 'utils'
import { Client } from 'models'
import { ReadResult } from 'interfaces/queryOptions'
import { validate } from 'modules/Clients/validations/client'
import { phone as phoneMask } from 'utils/presentation'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import {
  phoneCode,
  segments,
  timezones,
  types
} from 'modules/Clients/helpers/constants'
import { StatusChange } from './components/StatusChange'
import { listCountries } from 'modules/Staff/services/api'
import { translations } from 'modules/Clients/translations'

export function ClientTab() {
  const {
    isSaving,
    client,
    setClient,
    clientStatus,
    fetchClient,
    editClient,
    identification,
    refreshCorporateData,
    isRefreshing
  } = useEditClient()

  const [phone, setPhone] = useState('')
  const [errors, setErrors] = useState<Errors>({})

  interface CountryOption {
    value: number | undefined
    label: string | undefined
  }

  const [countries, setCountries] = useState<CountryOption[]>([])

  useEffect(() => {
    const fetchData = async () => {
      if (!client) {
        fetchClient()
      }

      const countriesData = await listCountries({
        attributes: ['*']
      })

      const transformedCountries = countriesData.data.map((country) => ({
        value: country.id,
        label: translations.country[country.attributes.name || ('' as string)]
      }))

      setCountries(transformedCountries)
    }

    fetchData()
  }, [client, fetchClient])

  useEffect(() => {
    if (!client) return

    setPhone(client.attributes.phones?.[0].phone.slice(2) ?? '')
  }, [client, clientStatus])

  const onAttributeChange = useCallback(
    (attr: any, value: any): void => {
      const error = validate(attr as any, value)

      if (client) {
        setClient({
          ...client,
          attributes: { ...client.attributes, [attr]: value }
        })
        setErrors({ ...errors, [attr]: error })
      }
    },
    [client, errors, setClient]
  )

  const onPhoneChange = useCallback(
    (value: any) => {
      const error = validate('phone' as any, value)

      setPhone(phoneMask(value))

      setErrors({ ...errors, phone: error })
    },
    [errors]
  )

  const validateFields = useCallback(
    (client: ReadResult<Client> | undefined): boolean => {
      if (!client) return false

      const isFilled = {
        email:
          Object.prototype.hasOwnProperty.call(client.attributes, 'email') &&
          client.attributes.email !== '',
        document:
          Object.prototype.hasOwnProperty.call(client.attributes, 'document') &&
          client.attributes.document !== '',
        account_name:
          Object.prototype.hasOwnProperty.call(
            client.attributes,
            'account_name'
          ) && client.attributes.account_name !== ''
      }

      const isClientValid =
        isFilled.document && isFilled.account_name && isFilled.email
      setErrors({
        ...errors,
        document: !isFilled.document ? 'O campo deve ser preenchido' : null,
        account_name: !isFilled.account_name
          ? 'O campo deve ser preenchido'
          : null,
        email: !isFilled.email ? 'O campo deve ser preenchido' : null
      })

      return !isClientValid
    },
    [errors]
  )

  const onSave = useCallback(async () => {
    if (!client) return

    if (validateFields(client) || check(errors)) {
      return
    }

    if (phone && phone !== '') {
      const { phones } = client.attributes

      const newPhone = `${phoneCode(
        client?.relationships?.country.attributes.code
      )}${phone.replaceAll(/([^\d]+)/gi, '')}`
      if (phones && phones.length > 0) {
        phones[0].phone = newPhone
      } else {
        client.attributes.phones = [
          {
            phone: newPhone,
            type: 'Principal'
          }
        ]
      }
    }

    const result = await editClient()

    if (result) {
      setErrors(result)
    }
  }, [client, editClient, errors, phone, validateFields])

  return (
    <>
      <div className="flex flex-row justify-between mt-10 animate-fade-in-down">
        <div className="w-1/2 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
            noValidate
          >
            <div className="space-y-6 mb-5">
              <div className="flex flex-row justify-between">
                <div className="text-xl text-left leading-6 font-medium text-gray-900">
                  Informações principais
                </div>
                <div className="w-1/4">
                  <StatusChange />
                </div>
              </div>
              <div className="mb-10">
                <div className="flex flex-row space-x-4 items-end">
                  <div className="w-1/2">
                    <InputWithValidationError
                      label="País"
                      name="country"
                      value={
                        countries.find(
                          (country) =>
                            country.value === client?.relationships?.country.id
                        )?.label
                      }
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChange={() => {}}
                      disabled
                    />
                  </div>

                  <div className="w-1/2">
                    <DocumentInput
                      name="document"
                      disabled
                      value={document(
                        client?.attributes.document,
                        client?.attributes.document_type
                      )}
                      docType={
                        client
                          ? client.attributes.document_type?.toUpperCase()
                          : ''
                      }
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onChange={() => {}}
                      error={errors.document}
                    />
                  </div>
                </div>
              </div>

              <InputWithValidationError
                label="Nome da conta"
                name="account_name"
                value={client?.attributes.account_name}
                onChange={(value) => onAttributeChange('account_name', value)}
                error={errors.account_name}
              />

              <div className="flex flex-row space-x-4">
                <div className="w-1/2">
                  <InputWithValidationError
                    label="E-mail"
                    name="email"
                    type="email"
                    value={client?.attributes.email}
                    onChange={(value) => onAttributeChange('email', value)}
                    error={errors.email}
                  />
                </div>
                <div className="w-1/2">
                  <InputWithAddon
                    optional
                    label="Telefone"
                    name="phone"
                    type="phone"
                    frontAddon={
                      '+' +
                      phoneCode(client?.relationships?.country.attributes.code)
                    }
                    value={
                      phone
                        ? phoneMask(phone)
                        : phoneMask(
                            client?.attributes.phones?.[0].phone.slice(2)
                          )
                    }
                    onChange={(value) => onPhoneChange(value)}
                    error={errors.phone}
                  />
                </div>
              </div>

              <RadioListWithDescription
                label="Tipo de cliente"
                options={types}
                value={client?.attributes.type}
                onChange={(value) => onAttributeChange('type', value)}
              />

              <div className="flex flex-row space-x-4">
                <div className="w-1/2">
                  <SimpleCustomSelect
                    label="Segmento"
                    options={segments}
                    value={client?.attributes.segment}
                    onChange={(value) => onAttributeChange('segment', value)}
                  />
                </div>

                <div className="w-1/2">
                  <InputWithValidationError
                    disabled
                    label="Vencimento"
                    name="billing_cycle_day"
                    type="text"
                    value={client?.attributes.billing_cycle_day}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onChange={() => {}}
                  />
                </div>
              </div>

              <div className="flex flex-row space-x-4">
                <div className="w-1/2">
                  <SimpleCustomSelect
                    label="Fuso Horário"
                    options={timezones}
                    value={client?.attributes.timezone}
                    onChange={(value) => onAttributeChange('timezone', value)}
                  />
                </div>
              </div>

              <ResizibleTextArea
                optional
                label="Descrição"
                name="description"
                value={client?.attributes.description}
                onChange={(value) => onAttributeChange('description', value)}
              />
            </div>

            <div className="mt-8 w-full space-x-4 flex justify-end animate-fade-in-down">
              <Close linkTo="/clients" />
              <Button disabled={isSaving} label="Salvar" type="submit" />
            </div>
          </form>
        </div>

        {identification && identification.document && (
          <IdentificationTable
            refreshing={isRefreshing}
            identification={identification}
            onRefresh={refreshCorporateData}
          />
        )}
      </div>
    </>
  )
}
