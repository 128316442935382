import { useEffect, useState } from 'react'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { ProtectedSection } from 'components/ProtectedSection'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Coupon } from 'models/coupon'
import { show } from '../services/read'
import { ReadResult } from 'interfaces/queryOptions'
import { translations } from 'modules/Orders/translations'
import { translations as countryTranslations } from 'modules/Clients/translations'
import { amount, currency, date } from 'utils'
import { toast } from 'react-toastify'

export function Show() {
  const { couponId } = useParams() as unknown as { couponId: string }

  const [isLoading, setIsLoading] = useState(true)

  const [coupon, setCoupon] = useState<ReadResult<Coupon>>()

  const history = useHistory()

  useEffect(() => {
    if (!couponId) return

    const fetchCoupon = async () => {
      try {
        const coupon = await show(couponId, {
          includes: {
            country: ['name'],
            orders: ['token']
          }
        })

        setCoupon(coupon)
      } catch (err: any) {
        history.push('/coupons')
        toast.error(err.suggestedMessage ?? 'Não foi possível exibir o cupom')
      } finally {
        setIsLoading(false)
      }
    }

    fetchCoupon()
  }, [couponId])

  return (
    <>
      <PageHeader title="Cupom" action="Visão geral" />

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <div className="flex flex-row justify-between py-8 animate-fade-in-down">
          <div className="w-1/2">
            <div className="">
              <div className="w-full">
                <div className="flex justify-between">
                  <h3 className="ml-4 text-xl leading-6 font-medium text-gray-900">
                    {`${coupon?.attributes.token} - ${coupon?.attributes.name}`}
                  </h3>
                  <div>
                    {coupon?.attributes.deactivated_at ? (
                      <span className="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Não ativo
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-base leading-5 font-semibold rounded-full bg-emerald-100 text-emerald-800">
                        Ativo
                      </span>
                    )}
                  </div>
                </div>
                <div className="mt-5 border-t border-gray-200 animate-fade-in-down">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Tipo
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {
                          translations.plan_type[
                            coupon?.attributes.type as string
                          ]
                        }
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        País
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {
                          countryTranslations.country[
                            coupon?.relationships?.country.attributes
                              .name as string
                          ]
                        }
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Aplicação
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {`Aplicação ${
                          translations.duration_mode[
                            coupon?.attributes.duration_mode as string
                          ]
                        }`}
                        {coupon?.attributes.duration_mode === 'repeating' && (
                          <div className="text-xs text-left text-gray-900">
                            {`${coupon.attributes.duration} ${
                              translations.coupon_interval[
                                coupon.attributes.interval as string
                              ]
                            }`}
                          </div>
                        )}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Valor
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {coupon?.attributes.mode === 'fixed'
                          ? `${currency(coupon.attributes.currency)} ${amount(
                              coupon.attributes.amount
                            )}`
                          : `${coupon?.attributes.amount} %`}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Expira em
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {coupon?.attributes.expires_at
                          ? date(coupon?.attributes.expires_at)
                          : '-'}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Resgates restantes
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {coupon?.attributes.redeems_remaining ?? '-'}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Criado em
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {date(coupon?.attributes.created_at)}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="ml-4 text-sm font-medium text-gray-500">
                        Atualizado em
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {date(coupon?.attributes.updated_at)}
                      </dd>
                    </div>
                  </dl>
                </div>
                {!coupon?.relationships?.orders && (
                  <ProtectedSection roles={[]}>
                    <div className="mt-6 flex justify-center space-x-3 md:mt-0 md:ml-4">
                      <Link to={`/coupons/${coupon?.id}`}>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Editar
                        </button>
                      </Link>
                    </div>
                  </ProtectedSection>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
