import { list as baseList, show as baseShow } from 'services/billing'
import { QueryOptions, ListResult, ReadResult } from 'interfaces/queryOptions'
import { Coupon } from 'models/coupon'

export async function list(
  options: QueryOptions = {}
): Promise<ListResult<Coupon>> {
  const response = await baseList<Coupon>('/coupons', options)
  return response
}

export async function show(
  couponId: string | number,
  options?: Pick<QueryOptions, 'attributes' | 'includes'>
): Promise<ReadResult<Coupon>> {
  const response = await baseShow<Coupon>('/coupons', couponId, options)
  return response
}
