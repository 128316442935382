import { TrashIcon } from '@heroicons/react/outline'
import { Currency, Installment } from 'models'
import { amount, currency as currencyMask, date } from 'utils'

interface Prop {
  currency: Currency
  installments: Installment[]
  setInstallments?: (value: any[]) => any
  type: string
}

export function InstallmentTable({
  currency,
  installments,
  setInstallments,
  type
}: Prop) {
  return (
    <div className="mr-4 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <div className="flex items-start justify-between">
          <h3 className="text-xl text-left leading-6 font-medium text-gray-900">
            Parcelas
          </h3>
          <div className="ml-3 h-7 flex items-center">
            {setInstallments && (
              <button
                type="button"
                className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                onClick={() => setInstallments([])}
              >
                <span className="sr-only">Esvaziar</span>
                <TrashIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
        <p className="max-w-2xl text-sm text-gray-500">{type}</p>
      </div>
      {installments.map((installment, idx) => (
        <div key={idx} className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
              <dt className="text-center text-sm font-medium text-gray-500">
                {`${installment.upfront ? 'Entrada: ' : ''}${currencyMask(
                  currency
                )} ${amount(installment.amount)}`}
              </dt>
              <dd className="text-center mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                {`${date(installment.due_at)}`}
              </dd>
            </div>
          </dl>
        </div>
      ))}
    </div>
  )
}
