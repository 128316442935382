import { AxiosInstance } from 'axios'
import { useHistory } from 'react-router-dom'
import { useCallback, useState, useEffect } from 'react'

import { useToken, Token } from './useToken'
import { login as baseLogin } from 'services/auth'
import { UserData, useUserData } from './useUserData'
import { AuthData, useAuthData } from './useAuthData'

import { toast } from 'react-toastify'

interface Props {
  axiosInstances: AxiosInstance[]
}

type AuthHook = [
  (email: string, password: string) => Promise<void>,
  (
    email: string,
    password: string,
    verificationCode: string | undefined
  ) => Promise<void>,
  () => void,
  UserData,
  boolean,
  Token,
  AuthData
]

export function useAuth({ axiosInstances }: Props): AuthHook {
  const [loading, setLoading] = useState(true)
  const [token, updateToken] = useToken()
  const [userData, updateUserData] = useUserData()
  const [authData, updateAuthData] = useAuthData()

  const history = useHistory()

  useEffect(() => {
    setLoading(true)
  }, [])

  useEffect(() => {
    if (token && token.raw) {
      const interceptors = setAxiosIntancesInterceptors(
        axiosInstances,
        token.raw
      )
      setLoading(true)
      updateUserData(token)
      return () => {
        interceptors.forEach((interceptor) => {
          interceptor.request.forEach((index) =>
            interceptor.api.interceptors.request.eject(index)
          )
          interceptor.response.forEach((index) =>
            interceptor.api.interceptors.response.eject(index)
          )
        })
      }
    }
  }, [token, axiosInstances, updateUserData])

  const initialAuth = useCallback(
    async (email: string, password: string) => {
      setLoading(true)
      const response = await baseLogin(email, password)

      if (response.passwordReset) {
        toast.info(
          `Um link de refinição de senha foi enviado ao email ${email}`
        )
        return
      }

      if (response) {
        updateAuthData({ email, password })
      }

      setLoading(false)

      if (history) {
        history.push('/login')
      }
    },
    [history, updateAuthData]
  )

  const login = useCallback(
    async (email: string, password: string, verificationCode?: string) => {
      setLoading(true)
      const response = await baseLogin(email, password, verificationCode)

      if (response && response.data.token) {
        updateToken({ token: response.data.token })
      }
      setLoading(false)

      if (history) {
        history.push('/login')
      }
    },
    [history, updateToken]
  )

  const logout = useCallback(() => {
    updateToken({ token: null })
    setLoading(false)

    updateAuthData(null)

    if (history) history.push('/login')
  }, [history, updateToken, updateAuthData])

  return [initialAuth, login, logout, userData, loading, token, authData]
}

function setAxiosIntancesInterceptors(
  axiosInstances: AxiosInstance[],
  token: string
) {
  const ejectMap = []
  for (const instance of axiosInstances) {
    instance.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error.response?.data ?? error)
    )
    const authInterceptor = instance.interceptors.request.use((request) => {
      request.headers.Authorization =
        request.headers.Authorization ?? `Bearer ${token}`
      return request
    })
    // return a Map with the ids of the interceptors to be removed on component unmount
    ejectMap.push({
      api: instance,
      request: [authInterceptor],
      response: []
    })
  }
  return ejectMap
}
