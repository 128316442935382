import { ReactNode, useCallback, useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'

import { classNames } from 'utils'

type Option = {
  label: string
  value: string
}

interface Props {
  label: ReactNode
  description: ReactNode
  options: Option[]
  value?: any
  onChange?: (value: any) => any
}

export function InlineList({
  label,
  description,
  value,
  options,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = () => {}
}: Props) {
  const [selected, setSelected] = useState<Option>()

  useEffect(() => {
    if (!value) return

    setSelected(options.find((ops) => ops.value === value))
  }, [options, value])

  const onSelect = useCallback(
    (option: Option | undefined) => {
      if (!option) return

      setSelected(option)
      onChange(option.value)
    },
    [onChange]
  )

  return (
    <RadioGroup
      value={selected}
      onChange={(option) => onSelect(option)}
      className="text-left"
    >
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <p className="text-sm leading-5 text-gray-500">{description}</p>
      <div className="bg-white rounded-md -space-y-px mt-2">
        <div className="flex flex-row space-x-4">
          {options.map((option) => (
            <div key={option.label}>
              <RadioGroup.Option
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked
                      ? 'bg-emerald-50 border-emerald-200'
                      : 'border-gray-300',
                    'rounded-md relative border px-4 py-3 flex cursor-pointer focus:outline-none shadow'
                  )
                }
              >
                {({ active, checked }) => (
                  <>
                    <span
                      className={classNames(
                        checked
                          ? 'bg-emerald-600 border-transparent'
                          : 'bg-white border-gray-300',
                        active ? 'ring-2 ring-offset-2 ring-emerald-500' : '',
                        'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                      )}
                      aria-hidden="true"
                    >
                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                    </span>
                    <div className="ml-3 flex">
                      <RadioGroup.Label
                        as="span"
                        className={classNames(
                          checked ? 'text-emerald-900' : 'text-gray-900',
                          'block text-sm font-medium'
                        )}
                      >
                        {option.label}
                      </RadioGroup.Label>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            </div>
          ))}
        </div>
      </div>
    </RadioGroup>
  )
}
