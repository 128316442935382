import { useEditStaff } from 'modules/Staff/hooks/useEditStaff'
import { useEffect, useState, useCallback } from 'react'

import { Role } from 'models'
import { DestroyButton } from 'modules/Staff/components/Destroy'

import { check } from 'utils/error'
import { Errors } from 'types'
import { validate } from 'modules/Staff/validations/user'
import { ReadResult } from 'interfaces/queryOptions'
import { StaffMember } from 'models'
import {
  InputWithValidationError,
  RadioListWithDescription,
  SimpleCustomSelect
} from 'components/FormInputs'
import { Button, Close } from 'components/Buttons'
import { languages } from 'modules/Staff/helpers/constants'
import { ProtectedSection } from 'components/ProtectedSection'
import { roles as roleNames } from 'modules/Staff/helpers/constants'

export function UserTab() {
  const {
    isSaving,
    staff,
    staffId,
    setStaff,
    fetchStaff,
    editStaff,
    roles,
    fetchRoles
  } = useEditStaff()

  const [errors, setErrors] = useState<Errors>({})

  const [role, setRole] = useState(staff?.relationships?.role.attributes.name)

  useEffect(() => {
    if (!staff) {
      fetchStaff()
    }
    fetchRoles()
  }, [staff, fetchStaff])

  const onAttributeChange = useCallback(
    (attr: any, value: any): void => {
      const error = validate(attr as any, value)

      if (attr === 'role_id') {
        const role = roles.data.find((role) => role.attributes.name === value)
        if (role?.attributes.name) {
          setRole(role?.attributes.name)
          value = role?.id
        }
      }

      if (staff) {
        setStaff({
          ...staff,
          attributes: { ...staff.attributes, [attr]: value }
        })
        setErrors({ ...errors, [attr]: error })
      }
    },
    [staff, errors, setStaff, roles.data]
  )

  const validateFields = useCallback(
    (staff: ReadResult<StaffMember> | undefined): boolean => {
      if (!staff) return false

      const isFilled = {
        email:
          Object.prototype.hasOwnProperty.call(staff.attributes, 'email') &&
          staff.attributes.email !== '',
        fullname:
          Object.prototype.hasOwnProperty.call(staff.attributes, 'fullname') &&
          staff.attributes.fullname !== '',
        language:
          Object.prototype.hasOwnProperty.call(staff.attributes, 'language') &&
          staff.attributes.language !== ''
      }

      const isStaffValid =
        isFilled.email && isFilled.fullname && isFilled.language
      setErrors({
        ...errors,
        email: !isFilled.email ? 'O campo deve ser preenchido' : null,
        fullname: !isFilled.fullname ? 'O campo deve ser preenchido' : null,
        language: !isFilled.language ? 'O campo deve ser preenchido' : null
      })

      return !isStaffValid
    },
    [errors]
  )

  const onSave = useCallback(async () => {
    if (!staff) return

    if (validateFields(staff) || check(errors)) {
      return
    }

    const result = await editStaff()

    if (result) {
      setErrors(result)
    }
  }, [staff, editStaff, errors, validateFields])

  return (
    <>
      <div className="flex flex-row justify-between mt-10 animate-fade-in-down">
        <div className="w-1/2 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
            noValidate
          >
            <div className="space-y-6 mb-5">
              <div className="flex flex-row justify-between">
                <div className="text-xl text-left leading-6 font-medium text-gray-900">
                  Informações gerais
                </div>
              </div>

              <InputWithValidationError
                label="Nome"
                name="fullname"
                value={staff?.attributes.fullname}
                onChange={(value) => onAttributeChange('fullname', value)}
                error={errors.fullname}
              />

              <InputWithValidationError
                label="E-mail"
                name="email"
                type="email"
                value={staff?.attributes.email}
                onChange={(value) => onAttributeChange('email', value)}
                error={errors.email}
              />

              <InputWithValidationError
                label="Telefone"
                name="phone_number"
                type="phone_number"
                value={staff?.attributes.phone_number}
                onChange={(value) => onAttributeChange('phone_number', value)}
                error={errors.phone}
              />

              <SimpleCustomSelect
                label="Idioma"
                options={languages}
                value={staff?.attributes.language}
                onChange={(value) => onAttributeChange('language', value)}
              />

              <ProtectedSection roles={[Role.MANAGER]}>
                <RadioListWithDescription
                  label="Papel do usuário"
                  options={roleNames}
                  value={role}
                  onChange={(value) => onAttributeChange('role_id', value)}
                />
              </ProtectedSection>
            </div>

            <div className="mt-8 w-full space-x-4 flex justify-end animate-fade-in-down">
              <ProtectedSection roles={[Role.MANAGER]}>
                <DestroyButton id={staffId} />
              </ProtectedSection>

              <Close linkTo="/staff-members" />
              <Button disabled={isSaving} label="Salvar" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
