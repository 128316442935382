import { useState } from 'react'
import { toast } from 'react-toastify'

import { Modal } from 'components/Modal'

import { split } from 'modules/Orders/services'
import { ReadResult } from 'interfaces/queryOptions'
import { Order } from 'models'

const DEFAULT_SPLIT_BY = 1

const clearForm = (formId: string) => {
  const inputs = document.getElementById(formId) as HTMLFormElement
  if (inputs) {
    inputs.reset()
  }
}

const suggestQuantities = (
  total: number | undefined,
  splitBy: number
): number[] => {
  if (!total) return []

  let splitted
  let suggestions: number[]

  const mod = total % splitBy
  if (mod === 0) {
    splitted = total / splitBy
    suggestions = Array(splitBy).fill(splitted)
  } else {
    splitted = (total - mod) / splitBy
    suggestions = Array(splitBy).fill(splitted)
    for (let i = 0; i < mod; i++) {
      suggestions[i]++
    }
  }

  return suggestions
}

interface SplitModalProps {
  open: boolean
  order: ReadResult<Order> | undefined
  setOpen: (open: boolean) => any
}

export function SplitModal({ open, order, setOpen }: SplitModalProps) {
  const [splitBy, setSplitBy] = useState(DEFAULT_SPLIT_BY)
  const [suggestedQuantities, setSuggestedQuantities] = useState([] as number[])
  const [quantities, setQuantities] = useState([] as number[])
  const [error, setError] = useState(null as null | string)

  const onSplitBy = (value: string) => {
    const parsed = parseInt(value)

    clearForm('input-fields')

    setError(null)
    setSplitBy(parsed)

    if (parsed === DEFAULT_SPLIT_BY) {
      setSuggestedQuantities([])
      return
    }

    const suggestions = suggestQuantities(order?.attributes.quantity, parsed)

    setSuggestedQuantities([...suggestions])
    setQuantities([])
  }

  const onQuantityChange = (index: number, value: string | undefined) => {
    setError(null)

    if (!value) return

    const parsedValue = parseInt(value)

    if (isNaN(parsedValue)) return

    quantities[index] = parseInt(value)
    setQuantities([...quantities])
  }

  const onClose = () => {
    setSplitBy(DEFAULT_SPLIT_BY)
    setQuantities([])
    setSuggestedQuantities([])
    setError(null)
    setOpen(!open)
  }

  const onSave = async () => {
    if (splitBy <= DEFAULT_SPLIT_BY) {
      onClose()
      return
    }

    const sum = quantities.reduce(
      (partial: number, acc: number) => partial + acc,
      0
    )

    if (sum !== order?.attributes.quantity) {
      setError('Novas quantidades não batem com quantidade original!')
      return
    }

    try {
      await split(order.id, quantities)
      toast.success('Pedido dividido com sucesso!')
    } catch (error: any) {
      toast.error('Erro ao dividir pedido')
    }

    onClose()
    window.location.reload()
  }

  return (
    <Modal open={open} title="Novos lotes" onClose={onClose}>
      <div className="flex my-4 justify-between items-center">
        <div className="flex space-x-2 items-center">
          <label>Dividir em:</label>
          <select
            value={splitBy}
            onChange={(e: any) => onSplitBy(e.target.value)}
            className="block py-1 px-2 text-base border border-gray-300 focus:outline-none focus:border-emerald-500 sm:text-sm rounded-md"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
        </div>
        <label className="text-sm">
          {'Quantidade original: '}
          <span className="font-semibold">{order?.attributes.quantity}</span>
        </label>
      </div>
      <form id="input-fields" className="mt-6 flex space-x-4">
        {suggestedQuantities.map((qnt, index) => (
          <div key={index} className="w-full">
            <div
              className={
                'relative border rounded-md px-3 py-2 shadow-sm ' +
                (error
                  ? 'focus-within:border-red-500 border-red-300'
                  : 'focus-within:border-emerald-500 border-gray-300')
              }
            >
              <label
                htmlFor={'qnt_' + (index + 1)}
                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
              >
                {`#${index + 1}`}
              </label>
              <input
                type="text"
                name={'qnt-' + (index + 1)}
                id={'qnt-' + (index + 1)}
                placeholder={qnt.toString()}
                className="block w-full focus:outline-none text-gray-900 sm:text-sm"
                onChange={(e: any) =>
                  onQuantityChange(index, e.currentTarget.value)
                }
              />
            </div>
          </div>
        ))}
      </form>
      {error && (
        <p className="text-xs text-red-600" id="quantities-error">
          {error}
        </p>
      )}

      <div className="mt-6 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          onClick={onClose}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
          onClick={onSave}
        >
          Salvar
        </button>
      </div>
    </Modal>
  )
}
