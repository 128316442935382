import { PageHeader } from 'components/PageHeader'
import { SearchBar } from 'components/Search'
import config from 'config'
import { useURLParams } from 'hooks/useURLParams'
import { QueryFilter, ReadResult } from 'interfaces/queryOptions'
import { User } from 'models'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { listUsersByClients } from '../services'
import { Pagination } from 'components/Pagination'
import { LinkIcon, QrcodeIcon } from '@heroicons/react/outline'
import { LoadingCircle } from 'components/Loading'
import { LoginAsModal } from '../components/LoginAsModal'
import { SortIcon } from 'components/Table/SortIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { translations } from 'modules/Staff/translations'
import { SimpleCustomSelect } from 'components/FormInputs'
import { GetUserAccessCodeModal } from '../components/GetUserAccessCodeModal'

const userRoleFilters = [
  { value: '', label: 'Selecione um papel' },
  { value: 'ADMIN', label: 'Administrador' },
  { value: 'ACCOUNT', label: 'Conta' },
  { value: 'REGULAR', label: 'Cliente' },
  { value: 'OWNER', label: 'Proprietário' },
  { value: 'ENTERPRISE', label: 'Colaborador' },
  { value: 'VISITOR', label: 'Visitante' },
  { value: 'CALLCENTER', label: 'Operador' },
  { value: 'PROVIDER', label: 'Prestador' }
]

const removeFilter = (filters: QueryFilter[], key: string, range?: string) => {
  const filtered = [...filters]

  const opMask: Record<string, string> = { to: 'lte', from: 'gte' }

  const filterByKey = (f: QueryFilter) => f.key === key
  const filterByKeyAndOp = (f: QueryFilter) =>
    f.key === key && f.op === opMask[range as string]

  const filterIndex = range
    ? filtered.findIndex(filterByKeyAndOp)
    : filtered.findIndex(filterByKey)

  if (filterIndex >= 0) {
    filtered.splice(filterIndex, 1)
  }

  return filtered
}

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      filters: [],
      search: ''
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) params.filters = JSON.parse(atob(filters))

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)

  const [sort, setSort] = useState<Record<string, number>>({ username: 1 })
  const [isNameSorted, setIsNameSorted] = useState(false)
  const [isUsernameSorted, setIsUsernameSorted] = useState(false)
  const [isEnterpriseSorted, setIsEnterpriseSorted] = useState(false)
  const [isSortedDesc, setIsSortedDesc] = useState(true)

  const [filters, setFilters] = useState<QueryFilter[]>(queryParams.filters)

  const [total, setTotal] = useState(0)

  const [data, setData] = useState<ReadResult<User>[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const [isLoginAsModalOpen, setIsLoginAsModalOpen] = useState(false)
  const [isGetUserAccessCodeModalOpen, setIsGetUserAccessCodeModalOpen] =
    useState(false)

  const [chosenUser, setChosenUser] = useState({})

  const maxItemsPerPage = config.items_per_list_page

  useEffect(() => {
    setIsLoading(false)

    const fetchData = async () => {
      if (search) {
        await fetchUsers(search, 1)
      }
    }

    fetchData()
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total, query, sort])

  const fetchUsers = async (search: string, page: number) => {
    setIsLoading(true)
    const options = {
      limit: maxItemsPerPage,
      filters,
      search,
      page,
      sort
    }

    try {
      const { count, data } = await listUsersByClients(options)
      setData(data)
      setTotal(count)
      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
      toast.error('Falha ao buscar clientes')
      console.error(err)
    }
  }

  const onSort = useCallback(
    (sortBy: string) => {
      const sortValue = isSortedDesc ? -1 : 1

      setSort({ [sortBy]: sortValue })

      if (sortBy === 'name') {
        setIsUsernameSorted(false)
        setIsEnterpriseSorted(false)
        setIsNameSorted(true)
      } else if (sortBy === 'username') {
        setIsNameSorted(false)
        setIsEnterpriseSorted(false)
        setIsUsernameSorted(true)
      } else {
        setIsNameSorted(false)
        setIsUsernameSorted(false)
        setIsEnterpriseSorted(true)
      }
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  const onPageChange = useCallback(
    async (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)

      await fetchUsers(search, newPage)
    },
    [lastPage]
  )

  const onFilter = useCallback(
    (key: string, value: any, range?: string) => {
      const cleanFilters = removeFilter(filters, key, range)

      if (value === '') {
        if (cleanFilters.length > 0) {
          query.set('q', btoa(JSON.stringify(cleanFilters)))
        } else {
          query.delete('q')
        }

        replace({
          pathname: window.location.pathname,
          search: query.toString()
        })

        setFilters([...cleanFilters])
        return
      }

      if (key === 'user_type') {
        cleanFilters.push({ key, op: 'eq', value })
      }

      if (cleanFilters.length > 0) {
        query.set('q', btoa(JSON.stringify(cleanFilters)))
      } else {
        query.delete('q')
      }

      setFilters([...cleanFilters])

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })
    },
    [filters, replace, query]
  )

  const onSearch = useCallback(
    async (term: string) => {
      if (!term || !term.length || term.length <= config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
        toast.warning('Insira um nome de usuário')
        return
      } else {
        const search = query.get('search')
        if (search === term) return
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setIsLoading(true)

      setSearch(term)
      setPage(1)

      await fetchUsers(term, 1)
    },
    [query, replace, maxItemsPerPage, sort]
  )

  return (
    <>
      <PageHeader title="Login como Cliente" action="Procurar Cliente" />

      <div className="ml-5">
        <div className="flex justify-between pr-8 items-center">
          <SearchBar value={search} onChange={onSearch} />
          <div className="w-1/5">
            <SimpleCustomSelect
              label=""
              options={userRoleFilters}
              value={
                filters?.find((filter) => filter.key === 'user_type')?.value ??
                userRoleFilters[0].value
              }
              onChange={(value) => onFilter('user_type', value)}
            />
          </div>
        </div>
        {isLoading ? (
          <LoadingCircle />
        ) : (
          <div className="flex flex-column flex-col animate-fade-in-down">
            <div className="w-1/5 mb-5"></div>
            <div className="-my-2 overflow-x-auto pr-4">
              <div className="py-2 align-middle inline-block min-w-full pr-4">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('name')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>NOME</div>
                            {isNameSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('username')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>USUÁRIO</div>
                            {isUsernameSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          EMAIL
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          TELEFONE
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('enterprise_name')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>EMPRESA</div>
                            {isEnterpriseSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          DOCUMENTO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          CPF
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          PAPEL
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data.map((user, index) => (
                        <tr
                          key={user.id}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xxs">
                            <div
                              title={user.attributes.name}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {user.attributes.name ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left max-w-xxs text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.username}
                              className="text-xs text-left text-gray-900 truncate"
                            >
                              {user.attributes.username ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.email}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {user.attributes.email ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.phone1}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {user.attributes.phone1 ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.enterprise_name}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {user.attributes.enterprise_name ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.document_number}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {user.attributes.document_number ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.cpf}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {user.attributes.cpf ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xs">
                            <div
                              title={user.attributes.user_type}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {
                                translations.role[
                                  user.attributes.user_type as string
                                ]
                              }
                            </div>
                          </td>
                          <td className="px-4 py-3 text-xs text-left text-gray-500 max-w-auto">
                            <div className="flex flex-row">
                              <button
                                type="button"
                                onClick={() => {
                                  setChosenUser(user)
                                  setIsLoginAsModalOpen(true)
                                }}
                                className="-ml-px relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                              >
                                <LinkIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setChosenUser(user)
                                  setIsGetUserAccessCodeModalOpen(true)
                                }}
                                className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                              >
                                <QrcodeIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={maxItemsPerPage}
                itemsOnPage={data.length}
                totalItems={total}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        )}
      </div>
      <LoginAsModal
        open={isLoginAsModalOpen}
        setOpen={setIsLoginAsModalOpen}
        user={chosenUser}
      />
      <GetUserAccessCodeModal
        open={isGetUserAccessCodeModalOpen}
        setOpen={setIsGetUserAccessCodeModalOpen}
        user={chosenUser}
      />
    </>
  )
}
