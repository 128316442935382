import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { resetPassword } from 'services/auth'

export function RedefinePassword({ token }: { token: string }) {
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const history = useHistory()

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      setIsLoading(true)

      try {
        if (password && repeatPassword) {
          if (password !== repeatPassword) {
            setIsLoading(false)
            return toast.error('As senhas não conferem')
          }

          await resetPassword(token, password)

          history.push('/login')
          toast.success('Senha redefinida com sucesso')

          setIsLoading(false)
        }
      } catch (err: any) {
        setIsLoading(false)

        if (err.message === 'Request failed with status code 401') {
          toast.error('Link de redefinição de senha expirado')
          history.push('/login')

          return
        }

        toast.error('Ocorreu um erro na redefinição de senha. Tente novamente')
      }
    },
    [password, repeatPassword]
  )

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src="softruck-logo.svg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Softruck Billing
        </h2>
      </div>

      <div className="m-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white p-8 shadow sm:rounded-md">
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Nova senha
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-emerald-600 focus:ring-1 focus:border-emerald-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Repita a nova senha
              </label>
              <div className="mt-1">
                <input
                  id="passwordRepeat"
                  name="passwordRepeat"
                  type="password"
                  autoComplete="passwordRepeat"
                  required
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  className="appearance-none block w-full p-3 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:ring-emerald-600 focus:ring-1 focus:border-emerald-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                // onKeyPress={(e: any) => e.key === "Enter" ? onAddToCart(currentSerial) : null}
                className="w-full flex justify-center p-3 rounded-md text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
              >
                {isLoading ? (
                  <div className="flex justify-center items-center">
                    <svg
                      className="animate-spin rounded-full h-5 w-5"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle
                        className="opacity-25 stroke-current text-white"
                        cx="12"
                        cy="12"
                        r="10"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75 fill-current text-white"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                ) : (
                  'Redefinir'
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
