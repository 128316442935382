export function applyDiscount(
  amount: number | undefined,
  discount: number | undefined
) {
  if (!amount) return

  if (!discount) return amount

  return amount - discount
}
