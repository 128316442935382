import { RefreshIcon } from '@heroicons/react/outline'
import { Identification } from 'models'
import { date, document, time } from 'utils'

interface IdentificationTableProps {
  refreshing?: boolean
  identification: Partial<Identification>
  onRefresh?: () => void
}

export function IdentificationTable({
  refreshing,
  identification,
  onRefresh
}: IdentificationTableProps) {
  return (
    <div className="w-1/2 animate-fade-in-down">
      <div className="px-5 w-full">
        <div className="ml-4 flex justify-between">
          <div className="flex flex-col">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Informações da Receita Federal
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Dados coletados com a Receita Federal.
            </p>
            <span className="text-xs mt-0.5 text-indigo-600">
              {`Última atualização: ${date(identification?.updated_at)} ${time(
                identification?.updated_at
              )}`}
            </span>
          </div>
          <div>
            {onRefresh && (
              <button
                type="button"
                disabled={refreshing}
                className="relative inline-flex items-center px-3 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                onClick={onRefresh}
              >
                <RefreshIcon
                  className={`h-4 w-4 ${
                    refreshing === true ? 'animate-spin' : ''
                  }`}
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
        </div>
        <div className="mt-5 border-t border-gray-200">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Documento
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {document(identification.document, 'cnpj')}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Razão Social
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {identification.corporate_name}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Nome fantasia
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {identification.trade_name}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Situação
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  <div className="text-transform: capitalize flex-1 truncate">
                    {identification.situation}
                  </div>
                  <div className="text-xs text-gray-500">
                    {identification.situation_motive}
                  </div>
                  <div className="text-xs text-gray-500">
                    {date(identification.situation_date)}
                  </div>
                </div>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Fundação
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {date(identification.foundation_date)}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Capital Social
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {identification.share_capital}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Atividade Principal
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {identification.main_activity
                  ? identification.main_activity[0].text
                  : '-'}
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Natureza Jurídica
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {identification.legal_nature}
              </dd>
            </div>

            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="ml-4 text-sm font-medium text-gray-500">
                Endereço
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {identification.address ? (
                  <div>
                    <div className="flex-1 truncate">
                      {`${identification.address?.address}, ${identification.address?.number}`}
                    </div>
                    <div className="text-xs text-gray-500">
                      {identification.address?.complement
                        ? `${identification.address?.complement} - ${identification.address?.neighborhood}`
                        : identification.address?.neighborhood}
                    </div>
                    <div className="text-xs text-gray-500">
                      {`${identification.address?.city} - ${identification.address?.uf}`}
                    </div>
                    <div className="text-xs text-gray-500">
                      {`CEP: ${identification.address?.zip_code}`}
                    </div>
                  </div>
                ) : (
                  '-'
                )}
              </dd>
            </div>

            {identification.corporate_structure &&
              identification.corporate_structure.length > 0 && (
                <>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="ml-4 text-sm font-medium text-gray-500">
                      Quadro Sociatário
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 divide-y">
                      <div className="h-32 overflow-y-auto">
                        {identification.corporate_structure.map((structure) => (
                          <div
                            key={structure.name}
                            className="py-2 pr-4 items-center"
                          >
                            <div className="text-xs">{structure.name}</div>
                            <div className="text-transform: capitalize text-xs text-gray-500">
                              {structure.role}
                            </div>
                          </div>
                        ))}
                      </div>
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4"></div>
                </>
              )}
          </dl>
        </div>
      </div>
    </div>
  )
}
