import { fleetview } from '../fleetview'

import { buildQueryString } from 'utils'

export async function login(
  email: string,
  password: string,
  verificationCode?: string
): Promise<any> {
  const response = await fleetview.post('/v5/auth/internal/login', {
    email,
    password,
    verification_code: verificationCode
  })
  return response.data
}

export async function getStaff(hashid: string): Promise<any> {
  const options = { includes: { role: ['name'] } }
  const response = await fleetview.get(
    `/v5/billing/staff-members/${hashid}` + buildQueryString(options)
  )
  return response.data.data
}

export async function forgotPassword(email: string): Promise<any> {
  const response = await fleetview.post('/v5/auth/internal/reset-password', {
    email
  })
  return response.data
}

export async function resetPassword(
  token: string,
  password: string
): Promise<any> {
  const response = await fleetview.patch(
    '/v5/billing/staff-members/password',
    {
      data: {
        attributes: {
          password
        }
      }
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return response.data
}
