export function cleanPayload(
  payload: Record<string, any>,
  isCreating: boolean
) {
  const cleanedPayload = { ...payload }

  Object.keys(payload.attributes).forEach((k) => {
    if (isCreating) {
      if (payload.attributes[k] === '' || payload.attributes[k] == null) {
        delete payload.attributes[k]
      }
    } else {
      if (payload.attributes[k] === null) {
        delete payload.attributes[k]
      }
      if (payload.attributes[k] === '') {
        payload.attributes[k] = null
      }
    }
  })

  return cleanedPayload
}
