import { useCallback, useEffect, useState } from 'react'

import { Modal } from 'components/Modal'
import { InputWithValidationError } from 'components/FormInputs'
import { Close, Button } from 'components/Buttons'

import { Errors } from 'types'
import { User } from 'models/user'
import { phone } from 'utils/presentation'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { validate } from 'modules/Clients/validations/user'
import { ReadResult } from 'interfaces/queryOptions'
import { check } from 'utils/error'

interface Props {
  open: boolean
  index: number
  setOpen: (open: boolean) => any
}

export function UpdateUser({ open, setOpen, index }: Props) {
  const { isSaving, users, editUser } = useEditClient()

  const [user, setUser] = useState<ReadResult<User>>()
  const [errors, setErrors] = useState<Errors>({})

  useEffect(() => {
    if (users && users.length > 0) {
      setUser({ ...users[index] })
    }
  }, [index, users])

  const onPhoneChange = useCallback(
    (value: any) => {
      if (!user) return

      const error = validate('phone', value)

      setUser({ ...user, attributes: { ...user.attributes, phone1: value } })
      setErrors({ ...errors, phone: error })
    },
    [errors, user]
  )

  const onAttributeChange = useCallback(
    (attr: keyof User, value: any) => {
      if (!user) return

      const error = validate(attr, value)

      setUser({ ...user, attributes: { ...user.attributes, [attr]: value } })
      setErrors({ ...errors, [attr]: error })
    },
    [errors, user]
  )

  const onClose = useCallback(() => {
    if (users) {
      setUser({ ...users[index] })
    }

    setErrors({})
    setOpen(!open)
  }, [index, open, setOpen, users])

  const onSave = useCallback(async () => {
    if (!user) return

    if (check(errors)) return

    await editUser(index, user)
    onClose()
  }, [editUser, errors, index, onClose, user])

  return (
    <Modal
      open={open}
      title="Editar usuário"
      onClose={onClose}
      width="sm:w-1/2"
    >
      <div className="mt-4 space-y-5">
        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              disabled
              label="Login"
              name="login"
              value={user?.attributes.username}
              onChange={(value: any) => onAttributeChange('username', value)}
              error={errors.username}
            />
          </div>

          <div className="w-1/2">
            <InputWithValidationError
              label="Nome"
              name="name"
              value={user?.attributes.name}
              onChange={(value: any) => onAttributeChange('name', value)}
              error={errors.name}
            />
          </div>
        </div>

        <div className="flex flex-row space-x-4">
          <div className="w-1/2">
            <InputWithValidationError
              label="Email"
              name="email"
              value={user?.attributes.email}
              onChange={(value: any) => onAttributeChange('email', value)}
              error={errors.email}
            />
          </div>

          <div className="w-1/2">
            <InputWithValidationError
              optional="opcional"
              label="Telefone"
              name="phone"
              value={phone(user?.attributes.phone1)}
              onChange={(value) => onPhoneChange(value)}
              error={errors.phone}
            />
          </div>
        </div>
      </div>

      <div className="mt-10 flex justify-end space-x-4">
        <Close onClick={onClose} />
        <Button disabled={isSaving} type="button" onClick={onSave} />
      </div>
    </Modal>
  )
}
