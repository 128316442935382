import { StringOrNull, Validations } from 'types'

import { notEmpty } from 'utils/validations'

const validations: Validations = {
  formatted_address: { validate: notEmpty },
  street_number: { validate: notEmpty },
  city: { validate: notEmpty },
  region: { validate: notEmpty },
  country: { validate: notEmpty },
  zip_code: { validate: notEmpty }
}

export function validate(prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
