import { ListResult } from 'interfaces/queryOptions'
import { Country } from 'models/country'
import { CheckIcon } from '@heroicons/react/solid'

interface CountryCheckboxListProps {
  countries: ListResult<Country>
  selectedCountries: string[]
  onToggleCountry: (countryCode: string) => void
}

export function CountryCheckboxList({
  countries,
  selectedCountries,
  onToggleCountry
}: CountryCheckboxListProps) {
  return (
    <div className="flex-col space-y-2">
      <label className="block text-left text-sm font-medium text-gray-700">
        Países
      </label>
      <div className="flex flex-col space-y-2">
        {countries.data.map((country: any) => (
          <label
            key={country.attributes.code}
            className={`
              relative inline-flex items-center p-2 border border-gray-300 rounded-md cursor-pointer transition-all
            `}
          >
            <input
              type="checkbox"
              className="sr-only"
              checked={selectedCountries.includes(country.attributes.code)}
              onChange={() => onToggleCountry(country.attributes.code)}
            />
            <div
              className={`
                ${
                  selectedCountries.includes(country.attributes.code)
                    ? 'bg-emerald-600 border-transparent'
                    : 'bg-white border-gray-300'
                }
                h-4 w-4 mt-0.5 cursor-pointer rounded-md border flex items-center justify-center
              `}
            >
              {selectedCountries.includes(country.attributes.code) && (
                <CheckIcon className="w-3 h-3 text-white" />
              )}
            </div>
            <div className="ml-3 flex flex-col">
              <span
                className={`
                  ${
                    selectedCountries.includes(country.attributes.code)
                      ? 'text-emerald-900'
                      : 'text-gray-900'
                  }
                  block text-sm font-medium
                `}
              >
                {country.attributes.name}
              </span>
            </div>
          </label>
        ))}
      </div>
    </div>
  )
}
