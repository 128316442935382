import { StringOrNull, Validations } from 'types'

import {
  notEmpty,
  notNullNonNegativeInteger,
  notNullPositiveFloat,
  notNullPositiveInteger
} from 'utils/validations'

const validations: Validations = {
  name: { validate: notEmpty },
  amount: { validate: notNullPositiveFloat },
  duration: { validate: notNullPositiveInteger },
  interval_count: { validate: notNullPositiveInteger },
  unit_amount: { validate: notNullPositiveFloat },
  up_to: { validate: notNullPositiveInteger },
  minimum_invoice_amount: { validate: notNullNonNegativeInteger },
  fine_amount: { validate: notNullNonNegativeInteger }
}

export function validate(prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
