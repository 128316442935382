import { useContext } from 'react'

import { Client, Identification, Order } from 'models'
import { ListResult, QueryOptions, ReadResult } from 'interfaces/queryOptions'

import { ClientViewContext } from '../contexts/ClientViewContext'
import { ClientDevice } from 'models/clientDevices'
import { ClientChip } from 'models/clientChips'

export type UseViewClientProps = {
  isLoadingClient: boolean
  client: ReadResult<Client> | undefined
  setClient: (data: ReadResult<Client>) => void
  fetchClient: () => Promise<void>
  refreshCorporateData: () => Promise<void>
  isRefreshing: boolean
  identification: Partial<Identification> | undefined

  isLoadingSubscriptions: boolean
  subscriptions: ListResult<Order> | undefined
  listSubscriptions: (page: number) => Promise<void>

  isLoadingOrders: boolean
  orders: ListResult<Order> | undefined
  listOrders: (page: number) => Promise<void>

  isLoadingDevices: boolean
  devices: ListResult<ClientDevice>
  listDevices: (options: QueryOptions) => Promise<void>
  updateDeviceStatus: (deviceIndex: number, payload: any) => Promise<void>

  isLoadingChips: boolean
  chips: ListResult<ClientChip>
  listChips: (options: QueryOptions) => Promise<void>
  updateChipStatus: (chipIndex: number, payload: any) => Promise<void>
}

export function useViewClient(): UseViewClientProps {
  const context = useContext(ClientViewContext)

  if (!context) {
    throw new Error('useShowClient must be used within an ClientProvider')
  }

  return context
}
