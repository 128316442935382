import { ListResult, QueryOptions } from 'interfaces/queryOptions'
import { User } from 'models'
import { fleetview } from 'services/fleetview'
import { buildQueryString } from 'utils'

const BASE_URL = '/v5/billing'

export async function listUsersByClients(
  options: QueryOptions = {}
): Promise<ListResult<User>> {
  const response = await fleetview.get(
    `${BASE_URL}/users/by-clients` + buildQueryString(options)
  )
  return response.data
}

export async function loginAs(userId: string): Promise<any> {
  const response = await fleetview.post(`${BASE_URL}/users/${userId}/login-as`)
  return response.data
}

export async function generateAccessCode(username: string): Promise<any> {
  const response = await fleetview.post(
    `${BASE_URL}/users/generate-access-code`,
    {
      data: {
        attributes: {
          username
        }
      }
    }
  )
  return response.data
}
