import { useCallback, useEffect, useState } from 'react'

import { StatusSelect } from './StatusSelect'
import { ClientStatus } from 'models/clientStatusHistory'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { useConfirmationDialog } from 'components/ConfirmationDialog'

export function StatusChange() {
  const { client, fetchClient, updateClientStatus, clientStatus } =
    useEditClient()

  const [status, setstatus] = useState<ClientStatus>()

  const [showConfirmationDialog] = useConfirmationDialog()

  const disabled = status && ['canceled', 'not_renewed'].includes(status)

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  useEffect(() => {
    if (client) {
      setstatus(clientStatus)
    }
  }, [client, clientStatus])

  const updateStatus = useCallback(
    (status: ClientStatus) => {
      updateClientStatus(status)
    },
    [updateClientStatus]
  )

  const onStatusChange = (status: ClientStatus) => {
    showConfirmationDialog({
      title: 'Alterar situação',
      message: 'Tem certeza que deseja alterar a situação do cliente?',
      onConfirm: () => updateStatus(status)
    })
  }

  return (
    <StatusSelect
      disabled={disabled}
      value={status}
      onChange={onStatusChange}
    />
  )
}
