import { ReactNode, createContext, useCallback, useState } from 'react'
import { UseViewPlanProps } from '../hooks/useViewPlan'
import { useParams } from 'react-router-dom'
import { Plan } from 'models'
import { show } from '../services/api'
import { toast } from 'react-toastify'

type Props = {
  children: ReactNode
}

export const PlanViewContext = createContext<UseViewPlanProps>(
  {} as UseViewPlanProps
)

export function PlanViewProvider({ children }: Props) {
  const { planId } = useParams() as unknown as { planId: string }

  const [isLoadingPlan, setIsLoadingPlan] = useState(true)
  const [plan, setPlan] = useState<Partial<Plan>>()

  const fetchPlan = useCallback(async () => {
    if (!planId) return

    setIsLoadingPlan(true)
    try {
      const options = { includes: { country: ['*'] } }

      const data = await show(planId, options)

      setPlan(data)

      setIsLoadingPlan(false)
    } catch (error: any) {
      setIsLoadingPlan(false)
      toast.error(error.suggestedMessage ?? 'Falha ao recuperar dados do plano')
    }
  }, [planId])

  return (
    <PlanViewContext.Provider
      value={{
        isLoadingPlan,
        plan,
        setPlan,
        fetchPlan
      }}
    >
      {children}
    </PlanViewContext.Provider>
  )
}
