import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/solid'

export function PageHeader({
  title,
  action,
  children
}: {
  title: string
  action?: string
  children?: ReactNode
}) {
  return (
    <>
      <nav className="flex mx-4 mb-8" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div>
              <Link to="/" className="text-gray-500 hover:text-gray-700">
                <HomeIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </Link>
            </div>
          </li>

          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              <div>{title}</div>
            </div>
          </li>

          <li>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 text-gray-500"
                aria-hidden="true"
              />
              <div>{action}</div>
            </div>
          </li>
        </ol>
      </nav>
      {children && (
        <div className="m-4">
          <div className="flex flex-row items-center justify-between">
            {children}
          </div>
        </div>
      )}
    </>
  )
}
