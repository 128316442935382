interface ButtonProps {
  onClick?: () => any
  className?: string
  [key: string]: any
}

export function CancelButton({
  label,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick = () => {}
}: ButtonProps) {
  return (
    <button
      type="button"
      className="inline-flex py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none"
      onClick={onClick}
    >
      {label}
    </button>
  )
}
