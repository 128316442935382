import { ReadResult } from 'interfaces/queryOptions'
import { Coupon } from 'models/coupon'
import { patch } from 'services/billing'

export async function activateCoupon(
  couponId: string | number
): Promise<ReadResult<Coupon>> {
  const response = await patch(`/coupons/${couponId}/activate`)
  return response
}

export async function deactivateCoupon(
  couponId: string | number
): Promise<ReadResult<Coupon>> {
  const response = await patch(`/coupons/${couponId}/deactivate`)
  return response
}

export async function updateCoupon(
  couponId: string | number,
  data: Record<string, any>
): Promise<ReadResult<Coupon>> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { country_id, ...rest } = data

  const attributes = {
    ...rest
  }

  const relationships = {
    country: {
      type: 'country',
      id: data.country_id
    }
  }

  const response = await patch(`/coupons/${couponId}`, {
    data: { attributes, relationships }
  })
  return response
}
