import { useCallback, useEffect, useState } from 'react'

import { Modal } from 'components/Modal'
import { OrderHardwareStatus } from 'models'
import { ClientDevice } from 'models/clientDevices'
import { ReadResult } from 'interfaces/queryOptions'
import { WriteOffInvoiceMotives } from 'models/orderItem'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import {
  InlineList,
  InputWithValidationError,
  SimpleCustomSelect
} from 'components/FormInputs'
import { Close } from 'components/Buttons/Close'
import { Button } from 'components/Buttons/Basic'
import { Errors } from 'types'

type SelectOption = { value: string; label: string }

type Reason = Record<string, SelectOption[]>

const writeOffReasons: Reason = {
  default: [{ value: '', label: '' }],
  canceled: [{ value: 'loss', label: 'Perda' }],
  returned: [{ value: 'return', label: 'Retorno' }],
  ownership_changed: [{ value: 'asset_sale', label: 'Venda de ativo' }]
}

const returned = { value: 'returned', label: 'Retornado' }
const canceled = { value: 'canceled', label: 'Cancelado' }
const ownership_changed = {
  value: 'ownership_changed',
  label: 'Troca de titularidade'
}

const status: Record<string, Record<OrderHardwareStatus, SelectOption[]>> = {
  active: {
    ordered: [ownership_changed],
    returned: [],
    canceled: [],
    ownership_changed: []
  },
  inactive: {
    ordered: [returned, canceled, ownership_changed],
    returned: [],
    canceled: [],
    ownership_changed: []
  }
}

interface Props {
  index: number | undefined
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

export function UpdateDeviceStatus({ index, isOpen, setOpen }: Props) {
  const { devices, updateDeviceStatus } = useViewClient()

  const [device, setDevice] = useState<ReadResult<ClientDevice>>()

  const [newDeviceStatus, setNewDeviceStatus] = useState<OrderHardwareStatus>()

  const [isSaving, setIsSaving] = useState(false)
  const [reasons, setReasons] = useState<Reason>({
    default: writeOffReasons.default
  })
  const [date, setDate] = useState(new Date())
  const [motive, setMotive] = useState<WriteOffInvoiceMotives>()
  const [invoice, setInvoice] = useState<string>()

  const [errors, setErrors] = useState<Errors>()

  useEffect(() => {
    if (index === undefined || !devices) return

    const device = devices.data[index]

    if (device) {
      setDevice(device)

      const reasons = device.attributes.activated
        ? { ownership_changed: writeOffReasons.ownership_changed }
        : { ...writeOffReasons }

      setReasons(reasons)
    }
  }, [index, devices])

  const onClose = useCallback(() => {
    setOpen(false)
    setNewDeviceStatus(undefined)
    setMotive(undefined)
    setInvoice(undefined)
    setDate(new Date())
    setErrors(undefined)
  }, [setOpen])

  const onSave = useCallback(async () => {
    if (index === undefined || !device) return

    if (!newDeviceStatus || !motive) return

    if (!invoice || invoice === '') {
      setErrors({ ...errors, invoice: 'Campo deve ser preenchido' })
      return
    }

    setIsSaving(true)
    const payload = {
      write_off_date: date,
      write_off_invoice: invoice,
      write_off_invoice_motive: motive,
      item_id: device.id,
      status: newDeviceStatus
    }

    await updateDeviceStatus(index, payload)
    setIsSaving(false)
    onClose()
  }, [
    date,
    device,
    errors,
    index,
    invoice,
    motive,
    newDeviceStatus,
    onClose,
    updateDeviceStatus
  ])

  return (
    <Modal open={isOpen} title="Atualização de estado" onClose={onClose}>
      <div className="grid grid-cols-12 gap-4 mt-4 animate-fade-in-down">
        <div className="col-span-12">
          <SimpleCustomSelect
            label="Novo estado"
            options={
              status[device?.attributes.activated ? 'active' : 'inactive'][
                device?.attributes.item_status as OrderHardwareStatus
              ]
            }
            value={newDeviceStatus}
            onChange={setNewDeviceStatus}
          />
        </div>

        <div className="col-span-6">
          <div className="mt-1">
            <InputWithValidationError
              label="Nota fiscal"
              type="text"
              name="write_off_invoice"
              id="write_off_invoice"
              value={invoice}
              onChange={(value) => {
                setInvoice(value)
                setErrors({ ...errors, invoice: null })
              }}
              error={errors?.invoice}
            />
          </div>
        </div>

        <div className="col-span-6">
          <div className="mt-1 relative">
            <DatePickerWithError
              label="Data da emissão"
              name="write_off_date"
              date={date}
              onChange={(value) =>
                setDate(value ? new Date(value) : new Date())
              }
            />
          </div>
        </div>

        <div className="col-span-12">
          {!newDeviceStatus ? (
            <div className="py-10"></div>
          ) : (
            <div className="animate-fade-in-down">
              <InlineList
                label="Motivo"
                description=""
                options={reasons[newDeviceStatus]}
                value={motive}
                onChange={setMotive}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-8 flex space-x-4 justify-end animate-fade-in-down">
        <Close onClick={onClose} />

        <Button
          type="button"
          onClick={onSave}
          disabled={isSaving}
          label="Salvar"
        />
      </div>
    </Modal>
  )
}
