import { ExclamationCircleIcon } from '@heroicons/react/solid'

interface Props {
  name: string
  onChange: (value: string) => any
  error?: string | null
  value?: any
  type?: string
  docType?: string
  [key: string]: any
}

export function DocumentInput({
  name,
  type,
  value,
  onChange,
  docType,
  error,
  ...rest
}: Props) {
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="block text-left text-sm font-medium text-gray-700"
      >
        Documento
      </label>
      <div className="mt-1 flex rounded-md shadow-sm items-center justify-center border">
        <span className="w-18 inline-flex rounded-l-md justify-center items-center px-3 py-2 text-gray-700 bg-gray-50 border-r">
          {docType ? docType : '-'}
        </span>
        <input
          type={type}
          name={name}
          id={name}
          autoComplete="off"
          value={value ?? ''}
          onChange={(e) => onChange(e.currentTarget.value)}
          className={
            'flex-1 min-w-0 block w-full px-3 py-2 rounded-r-md focus:outline-none' +
            (error
              ? ' border-red-300 focus:border-red-500'
              : ' border-gray-300 focus:border-emerald-500')
          }
          {...rest}
        />

        {error && (
          <div
            className={
              'pr-3 absolute inset-y-0 right-0 flex items-center pointer-events-none'
            }
          >
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p
          className="absolute left-0 text-xs text-red-600"
          id={`${name}-error`}
        >
          {error}
        </p>
      )}
    </div>
  )
}
