import { createContext } from 'react'
import { Token } from './hooks/useToken'
import { AuthData } from './hooks/useAuthData'
import { ReadResult } from 'interfaces/queryOptions'
import { StaffMember } from 'models'

const AuthContext = createContext({
  user: null as null | ReadResult<StaffMember>,
  initialAuth: async (_email: string, _password: string): Promise<void> => {
    // Placeholder for future implementation
  },
  login: async (
    _email: string,
    _password: string,
    _verificationCode?: string
  ): Promise<void> => {
    // Placeholder for future implementation
  },
  logout: (): void => {
    // Placeholder for future implementation
  },
  loading: false,
  token: null as null | Token,
  authData: null as null | AuthData
})

export { AuthContext }
