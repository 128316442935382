import config from 'config'
import { Device } from 'models'
import { fleetview } from '../fleetview'
import { buildQueryString } from 'utils'
import { QueryOptions, ListResult, ReadResult } from 'interfaces/queryOptions'

export async function list(options: QueryOptions): Promise<ListResult<Device>> {
  options.count = options.count ?? true
  options.limit = options.limit ?? config.items_per_list_page

  const optionString = buildQueryString(options)

  const response = await fleetview.get(`/v5/billing/devices${optionString}`)
  return response.data
}

export async function showFreeDevice(
  options: Pick<QueryOptions, 'attributes' | 'includes' | 'filters'> = {}
): Promise<ReadResult<Device> | undefined> {
  const optionString = buildQueryString(options)

  const response = await fleetview.get(`/v5/billing/devices${optionString}`)

  if (response.data.data.length === 0) return undefined

  const device = response.data.data[0]
  const deviceType = device.relationships.type
  const deviceChip = device.relationships.chip ?? undefined
  const deviceLastGps = device.relationships.last_gps ?? undefined

  device.attributes = device.attributes.attributes
  device.relationships.type.attributes = deviceType.attributes.attributes
  if (deviceChip)
    device.relationships.chip.attributes = deviceChip.attributes.attributes
  if (deviceLastGps)
    device.relationships.last_gps.attributes =
      deviceLastGps.attributes.attributes

  return device
}

export async function createBatch(
  devicesInformations: Record<string, any>
): Promise<ListResult<Device>> {
  const registersResult = await fleetview.post('/v5/billing/devices/stock', {
    data: devicesInformations
  })
  return registersResult.data
}

export async function listDeviceTypes(
  options: QueryOptions
): Promise<Record<string, any>> {
  options.count = options.count ?? true
  options.limit = options.limit ?? config.items_per_list_page

  const optionString = buildQueryString(options)

  const response = await fleetview.get(
    `/v5/billing/devices/models${optionString}`
  )
  return response.data.data
}
