const headers = [
  { label: 'Identificador', key: 'name' },
  { label: 'IMEI', key: 'imei' },
  { label: 'Nota fiscal de compra', key: 'purchase_tax_note' },
  { label: 'Data nota fiscal compra', key: 'purchase_tax_note_date' }
]

const data = [
  {
    name: '123456789',
    imei: '123456789012345',
    purchase_tax_note: 'NF2345',
    purchase_tax_note_date: '2021-08-23'
  },
  {
    name: '123465143125341',
    imei: '123465143125341',
    purchase_tax_note: 'NF2345',
    purchase_tax_note_date: '2021-08-23'
  }
]

export const deviceModel = {
  filename: 'ModeloDevices.csv',
  headers: headers,
  data: data
}
