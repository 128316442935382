import { useCallback, useEffect, useState } from 'react'

import { Modal } from 'components/Modal'

import { User } from 'models/user'
import { useEditClient } from 'modules/Clients/hooks/useEditClient'
import { ReadResult } from 'interfaces/queryOptions'

interface Props {
  open: boolean
  index: number
  setOpen: (open: boolean) => any
}

export function SendEmail({ open, setOpen, index }: Props) {
  const { isSaving, users, sendEmail } = useEditClient()

  const [user, setUser] = useState<ReadResult<User>>()

  useEffect(() => {
    if (users && users.length > 0) {
      setUser({ ...users[index] })
    }
  }, [index, users])

  const onSend = useCallback(async () => {
    if (!user) return

    await sendEmail(index)
    setOpen(false)
  }, [index, sendEmail, setOpen, user])

  return (
    <Modal
      open={open}
      title="Boas vindas"
      onClose={() => setOpen(false)}
      width="sm:w-1/2"
    >
      <div className="mt-5">
        <div className="max-w-xl text-md text-gray-600">
          <p>Enviar um email de boas vindas para:</p>
        </div>
        <div className="mt-2 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 sm:mt-0">
              <div className="text-md font-medium text-gray-900">
                {user?.attributes.name}
              </div>
              <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                <div>{user?.attributes.email}</div>
                <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                  &middot;
                </span>
                <div className="mt-1 sm:mt-0">{user?.attributes.email}</div>
              </div>
            </div>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
            <button
              disabled={isSaving}
              type="button"
              onClick={onSend}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:text-sm"
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
