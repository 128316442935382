import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { DuplicateIcon, EyeIcon, PencilIcon } from '@heroicons/react/outline'

import { SplitModal } from '../components/Modal'
import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import { LoadingCircle } from 'components/Loading'
import { SortIcon } from 'components/Table/SortIcon'
import { SorUptIcon } from 'components/Table/SortUpIcon'
import { SimpleButton } from 'components/FormInputs/Button'
import { SortDownIcon } from 'components/Table/SortDownIcon'
import { ProtectedSection } from 'components/ProtectedSection'
import {
  InputWithValidationError,
  SimpleCustomSelect
} from 'components/FormInputs'

import config from 'config'
import { list } from '../services/read'
import { translations } from '../translations'
import { useURLParams } from 'hooks/useURLParams'
import { applyDiscount } from 'modules/common/utils'
import { Order, OrderStatus, PlanType, Role } from 'models'
import { document, date, amount, currency, time } from 'utils'
import { QueryFilter, ReadResult } from 'interfaces/queryOptions'
import { Label } from 'components/Label'
import { SearchBar } from 'components/Search'
import { getOrderStatusAndDate } from 'utils/order-status'

const statusFilters = [
  { value: '', label: 'Selecione um estado' },
  { value: OrderStatus.pending, label: 'Pendente' },
  { value: OrderStatus.approved, label: 'Aprovado' },
  { value: OrderStatus.in_line, label: 'Na fila' },
  { value: OrderStatus.in_progress, label: 'Em progresso' },
  { value: OrderStatus.updating_fiscal_data, label: 'Emitindo nota fiscal' },
  { value: OrderStatus.ready_for_pick_up, label: 'Pronto para entrega' },
  { value: OrderStatus.shipping, label: 'Em envio' },
  { value: OrderStatus.delivered, label: 'Entregue' },
  { value: OrderStatus.canceled, label: 'Cancelado' }
]

const removeFilter = (filters: QueryFilter[], key: string, range?: string) => {
  const filtered = [...filters]

  const opMask: Record<string, string> = { to: 'lte', from: 'gte' }

  const filterByKey = (f: QueryFilter) => f.key === key
  const filterByKeyAndOp = (f: QueryFilter) =>
    f.key === key && f.op === opMask[range as string]

  const filterIndex = range
    ? filtered.findIndex(filterByKeyAndOp)
    : filtered.findIndex(filterByKey)

  if (filterIndex >= 0) {
    filtered.splice(filterIndex, 1)
  }

  return filtered
}

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      filters: [],
      search: ''
    }

    const search = query.get('search')
    const filters = query.get('q')

    if (search) params.search = search
    if (filters) params.filters = JSON.parse(atob(filters))

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>(queryParams.filters)

  const [sort, setSort] = useState<Record<string, number>>({ ordered_at: -1 })
  const [isAccountSorted, setIsAccountSorted] = useState(false)
  const [isOrderedAtSorted, setIsOrderedAtSorted] = useState(false)
  const [isActivatedAtSorted, setIsActivatedAtSorted] = useState(false)
  const [isSortedDesc, setIsSortedDesc] = useState(true)

  const [total, setTotal] = useState(0)
  const [order, setOrder] = useState<ReadResult<Order>>()
  const [orders, setOrders] = useState<ReadResult<Order>[]>([])

  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true)

      const options = {
        attributes: [
          'id',
          'token',
          'batch',
          'quantity',
          'discount',
          'ordered_at',
          'created_at',
          'current_status',
          'activated_at'
        ],
        includes: {
          client: ['id', 'account_name', 'document', 'document_type'],
          plan: ['id', 'name', 'nature', 'amount', 'type', 'currency']
        },
        limit: maxItemsPerPage,
        filters,
        sort,
        search,
        page
      }

      try {
        const { count, data } = await list(options)
        setOrders(data)
        setTotal(count)
        setPage(page)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error('Falha ao buscar pedidos')
      }
    }

    fetchOrders()
  }, [page, search, sort, filters, maxItemsPerPage])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const onSort = useCallback(
    (sortBy: string) => {
      const sortValue = isSortedDesc ? -1 : 1

      setSort({ [sortBy]: sortValue })

      if (sortBy === 'ordered_at') {
        setIsAccountSorted(false)
        setIsActivatedAtSorted(false)
        setIsOrderedAtSorted(true)
      } else if (sortBy === 'activated_at') {
        setIsOrderedAtSorted(false)
        setIsAccountSorted(false)
        setIsActivatedAtSorted(true)
      } else {
        setIsAccountSorted(true)
        setIsActivatedAtSorted(false)
        setIsOrderedAtSorted(false)
      }
      setIsSortedDesc(!isSortedDesc)
    },
    [isSortedDesc]
  )

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onFilter = useCallback(
    (key: string, value: any, range?: string) => {
      const cleanFilters = removeFilter(filters, key, range)

      if (value === '') {
        if (cleanFilters.length > 0) {
          query.set('q', btoa(JSON.stringify(cleanFilters)))
        } else {
          query.delete('q')
        }

        replace({
          pathname: window.location.pathname,
          search: query.toString()
        })

        setFilters([...cleanFilters])
        return
      }

      if (key === 'current_status') {
        cleanFilters.push({ key, op: 'eq', value })
      }

      if (key === 'ordered_at') {
        if (range === 'from') {
          cleanFilters.push({ key, op: 'gte', value })
        }

        if (range === 'to') {
          cleanFilters.push({ key, op: 'lte', value })
        }
      }

      if (cleanFilters.length > 0) {
        query.set('q', btoa(JSON.stringify(cleanFilters)))
      } else {
        query.delete('q')
      }

      setFilters([...cleanFilters])

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })
    },
    [filters, replace, query]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  const onShowModal = useCallback(
    (id: number) => {
      setOrder(orders[id])
      setIsOpen(true)
    },
    [orders]
  )

  return (
    <>
      <PageHeader title="Pedidos" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[Role.MANAGER, Role.SALES]}>
          <Link to="/orders/create">
            <SimpleButton>Novo</SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      <div className="w-full px-4 my-4 h-16 flex items-center justify-between">
        <div className="w-1/5">
          <SimpleCustomSelect
            label=""
            options={statusFilters}
            value={
              filters?.find((filter) => filter.key === 'current_status')
                ?.value ?? statusFilters[0].value
            }
            onChange={(value) => onFilter('current_status', value)}
          />
        </div>
        <div className="flex space-x-2">
          <div className="w-44">
            <InputWithValidationError
              label=""
              type="date"
              name="from"
              value={
                filters?.find(
                  (filter) => filter.key === 'ordered_at' && filter.op === 'gte'
                )?.value
              }
              onChange={(value) => onFilter('ordered_at', value, 'from')}
            />
          </div>
          <div className="w-44">
            <InputWithValidationError
              label=""
              type="date"
              name="to"
              value={
                filters?.find(
                  (filter) => filter.key === 'ordered_at' && filter.op === 'lte'
                )?.value
              }
              onChange={(value) => onFilter('ordered_at', value, 'to')}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          CÓDIGO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          ESTADO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500 group cursor-pointer"
                          onClick={() => onSort('client.account_name')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>CONTA</div>
                            {isAccountSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          PLANO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          VALOR UNITÁRIO
                        </th>
                        <th
                          scope="col"
                          className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          onClick={() => onSort('ordered_at')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>PEDIDO EM</div>
                            {isOrderedAtSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="group cursor-pointer px-4 py-3 text-left text-sm font-light text-gray-500"
                          onClick={() => onSort('activated_at')}
                        >
                          <div className={'flex justify-between items-center'}>
                            <div>ATIVADO EM</div>
                            {isActivatedAtSorted ? (
                              isSortedDesc ? (
                                <SortDownIcon className="h-4 w-4" />
                              ) : (
                                <SorUptIcon className="h-4 w-4" />
                              )
                            ) : (
                              <SortIcon className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                            )}
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {orders.map((order, index) => (
                        <tr
                          key={order.id}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                <div className="text-xs text-left font-semibold text-gray-900 truncate">
                                  {' '}
                                  {order.attributes.token}{' '}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {' '}
                                  {order.attributes.batch}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="text-sm text-left font-semibold text-gray-900 truncate">
                              <Label
                                type="order_status"
                                label={
                                  translations['order_status'][
                                    order.attributes.current_status!
                                  ]
                                }
                                value={order.attributes.current_status}
                              />
                            </div>
                            <div className="ml-2 text-xs text-left text-gray-500">
                              {' '}
                              {date(
                                getOrderStatusAndDate(order).date as Date
                              )}{' '}
                            </div>
                          </td>
                          <td className="px-4 py-3 max-w-xxs whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                <div
                                  title={
                                    order.relationships?.client.attributes
                                      .account_name
                                  }
                                  className="text-xs text-left font-semibold text-gray-900 truncate"
                                >
                                  {
                                    order.relationships?.client.attributes
                                      .account_name
                                  }
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {document(
                                    order.relationships?.client.attributes
                                      .document,
                                    order.relationships?.client.attributes
                                      .document_type
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap max-w-xs text-sm text-gray-500">
                            <div className="flex items-center">
                              <div className="flex-1 truncate">
                                <div className="text-xs text-left font-semibold text-gray-900 truncate">
                                  {' '}
                                  {order.relationships?.plan.attributes.name ??
                                    '-'}{' '}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {' '}
                                  {
                                    translations.plan_nature[
                                      order.relationships?.plan.attributes
                                        .nature
                                    ]
                                  }{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {' '}
                                  {`${currency(
                                    order.relationships?.plan.attributes
                                      .currency
                                  )} ${amount(
                                    applyDiscount(
                                      order.relationships?.plan.attributes
                                        .amount,
                                      order.attributes.discount
                                    )
                                  )}`}{' '}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {' '}
                                  {`Quantidade: ${order.attributes.quantity}`}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {date(order.attributes.ordered_at)}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {time(order.attributes.ordered_at)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left font-semibold text-gray-900">
                                  {date(order.attributes.activated_at)}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {time(order.attributes.activated_at)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-xs text-left text-gray-500">
                            <div className="flex flex-row">
                              {
                                // Show order
                                <Link
                                  to={`/orders/${order.id}/${
                                    order.relationships?.plan.attributes
                                      .type === PlanType.connectivity
                                      ? 'chips'
                                      : 'devices'
                                  }`}
                                >
                                  <button
                                    type="button"
                                    className={
                                      'relative inline-flex items-center px-3 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 ' +
                                      ([
                                        'pending',
                                        'approved',
                                        'in_line'
                                      ].includes(
                                        order.attributes.current_status!
                                      )
                                        ? ''
                                        : 'rounded-r-md')
                                    }
                                  >
                                    <EyeIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Link>
                              }
                              {
                                // Edit order
                                order.attributes.current_status ===
                                  'pending' && (
                                  <Link to={`/orders/${order.id}`}>
                                    <button
                                      type="button"
                                      className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    >
                                      <PencilIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </Link>
                                )
                              }
                              {
                                // Split order modal
                                ['approved', 'in_line'].includes(
                                  order.attributes.current_status!
                                ) && (
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500"
                                    onClick={() => onShowModal(index)}
                                  >
                                    <DuplicateIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )
                              }
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={maxItemsPerPage}
                itemsOnPage={orders.length}
                totalItems={total}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </>
      )}

      <SplitModal order={order} open={isOpen} setOpen={setIsOpen} />
    </>
  )
}
