import { useCallback } from 'react'
import { StatusOfflineIcon, StatusOnlineIcon } from '@heroicons/react/solid'
import { toast } from 'react-toastify'

import { SimpleButton } from 'components/FormInputs/Button'
import {
  activateCoupon,
  deactivateCoupon
} from 'modules/Coupons/services/update'

interface Props {
  couponId: number
  deactivatedAt: Date | null
  isEditable: boolean
  onToggle?: (id: number, deactivatedAt: Date | null) => void
}

export function ToggleActivation({
  couponId,
  deactivatedAt,
  isEditable,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onToggle = () => {}
}: Props) {
  const activate = useCallback(
    async (couponId: number) => {
      try {
        await activateCoupon(couponId)
        toast.success('Cupom ativado com sucesso')
        onToggle(couponId, null)
      } catch (err: any) {
        toast.error(err.suggestedMessage ?? 'Não foi possível ativar o cupom')
      }
    },
    [onToggle]
  )

  const deactivate = useCallback(
    async (couponId: number) => {
      try {
        await deactivateCoupon(couponId)
        toast.success('Cupom desativado com sucesso')
        onToggle(couponId, new Date())
      } catch (err: any) {
        toast.error(
          err.suggestedMessage ?? 'Não foi possível desativar o cupom'
        )
      }
    },
    [onToggle]
  )

  return (
    <>
      {deactivatedAt ? (
        <SimpleButton
          title="Ativar Cupom"
          onClick={() => activate(couponId)}
          className={
            'relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 ' +
            (isEditable ? '' : 'rounded-r-md')
          }
        >
          <StatusOnlineIcon className="h-5 w-5" aria-hidden="true" />
        </SimpleButton>
      ) : (
        <button
          title="Desativar Cupom"
          onClick={() => deactivate(couponId)}
          className={
            'relative inline-flex items-center px-3 py-2 border border-gray-300 bg-white text-sm font-medium text-emerald-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:border-emerald-500 ' +
            (isEditable ? '' : 'rounded-r-md')
          }
        >
          <StatusOfflineIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      )}
    </>
  )
}
