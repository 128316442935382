import Downshift from 'downshift'
import debounce from 'lodash.debounce'
import { toast } from 'react-toastify'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { useState, useEffect, useCallback, useMemo } from 'react'

import config from 'config'
import { Address } from 'models'
import { getAddresses } from 'services/google'

interface Props {
  error?: string | null
  [key: string]: any
}

export function SearchAddress({ onSelect, value, error, ...rest }: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState(value ?? '')

  const [items, setItems] = useState<Address[]>()
  const [selected, setSelected] = useState<Address>()

  const search = useMemo(
    () =>
      debounce(async (search: string) => {
        try {
          if (search.length < config.min_search_length) return

          setIsLoading(true)

          const results = await getAddresses(search)

          setItems(results)
          setIsLoading(false)
        } catch (err: any) {
          toast.error('Falha ao tentar buscar endereço')
          setIsLoading(false)
          console.error(err)
        }
      }, config.autocomplete_debounce_rate),
    []
  )

  useEffect(() => {
    if (
      searchValue === '' ||
      searchValue === value ||
      searchValue === selected?.formatted_address
    )
      return

    search(searchValue)
  }, [error, search, selected, searchValue, value])

  useEffect(() => {
    search.cancel()
  }, [search])

  const onClick = useCallback(
    (index: number) => {
      if (items) {
        setSelected(items[index])
        setSearchValue(items[index].formatted_address)
      }
    },
    [items]
  )

  return (
    <div>
      <Downshift
        onChange={(selection) => (selection ? onSelect(selected) : undefined)}
        itemToString={(item) => item?.formatted_address ?? ''}
        selectedItem={{ formatted_address: '' }}
      >
        {({ getInputProps, getItemProps, isOpen }) => (
          <div className="relative">
            <label className="block text-left text-sm font-medium text-gray-700">
              Endereço
            </label>
            <div className="relative flex items-center">
              <input
                className={
                  'mt-1 block w-full rounded-md shadow-sm py-2 px-3 border focus:outline-none border-gray-300 focus:border-emerald-500 ' +
                  (error
                    ? 'border-red-500 focus:border-red-500'
                    : 'border-gray-300 focus:border-emerald-500')
                }
                {...getInputProps({
                  value: searchValue || '',
                  onChange: (e) => {
                    setSearchValue(e.currentTarget.value)
                  },
                  ...rest
                })}
              />
              {isLoading && (
                <div className="absolute right-1">
                  <svg
                    className="animate-spin mt-1 mr-3 h-5 w-5 text-emerald-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
              {error && (
                <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {error && (
              <p className="absolute left-0 text-xs text-red-500">{error}</p>
            )}
            <ul className="w-full absolute mt-2 rounded-md bg-white z-20 shadow flex flex-col">
              {isOpen && items && items.length > 0 && (
                <div className="max-h-96 overflow-auto">
                  {items.map((item, index) => (
                    <div
                      className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-between"
                      key={item.id}
                      {...getItemProps({
                        key: item.formatted_address,
                        onClick: () => {
                          onClick(index)
                        },
                        item
                      })}
                    >
                      <div>
                        <p className="text-md text-left text-gray-900">
                          {item.formatted_address}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {isOpen && items && !items.length && (
                <li className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-start">
                  Nenhum resultado encontrado
                </li>
              )}
            </ul>
          </div>
        )}
      </Downshift>
    </div>
  )
}
