import { Modal } from 'components/Modal'
import { User } from 'models'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { generateAccessCode } from '../services'
import { LoadingCircle } from 'components/Loading'

interface Props {
  open: boolean
  user: Partial<User>
  setOpen: (open: boolean) => any
}

export function GetUserAccessCodeModal({ open, setOpen, user }: Props) {
  const [isLoading, setIsLoading] = useState(false)

  const [accessCode, setAccessCode] = useState('')

  const onConfirm = useCallback(async () => {
    if (!user) return

    setIsLoading(true)

    try {
      const response = await generateAccessCode(user.attributes.username)

      setAccessCode(response.data.verification_code)

      setIsLoading(false)
    } catch (err: any) {
      setIsLoading(false)
      toast.error(
        err.suggestedMessage ?? 'Falha ao tentar gerar Código de Acesso'
      )
      return
    }
  }, [user, setOpen])

  return (
    <>
      {user && user.attributes && (
        <Modal
          open={open}
          title={'Gerar Código de Acesso do Cliente'}
          onClose={() => {
            setOpen(false)
            setTimeout(() => setAccessCode(''), 500)
          }}
          width="md:w-1/2"
        >
          <div className="mt-5">
            <div className="mt-2 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 sm:mt-0">
                  <div className="text-md font-medium text-gray-900">
                    {user.attributes.name}
                  </div>
                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                    <div className="mt-1 sm:mt-0">
                      {user.attributes.username}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                {isLoading ? (
                  <LoadingCircle />
                ) : accessCode ? (
                  <p className="text-gray-700 font-medium">{`Use o código ${accessCode} para logar como ${user.attributes.name}`}</p>
                ) : (
                  <button
                    disabled={isLoading}
                    type="button"
                    onClick={onConfirm}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:text-sm"
                  >
                    Gerar código
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
