import React, { useEffect, useState, useCallback } from 'react'
import { Errors } from 'types'
import { useEditStaff } from 'modules/Staff/hooks/useEditStaff'

import { validate } from 'modules/Staff/validations/restrictions'
import { check } from 'utils/error'
import { ReadResult } from 'interfaces/queryOptions'
import { StaffMember } from 'models'
import { InputWithValidationError } from 'components/FormInputs'
import { Button, Close } from 'components/Buttons'
import { DayButtons } from 'components/FormInputs/Button/DayButtons'
import { CountryCheckboxList } from 'components/FormInputs/CheckBox'

export function RestrictionsTab() {
  const {
    isSaving,
    restrictions,
    staff,
    setStaff,
    showRestrictions,
    editRestrictions,
    countries
  } = useEditStaff()

  const [errors, setErrors] = useState<Errors>({})

  const staffRestrictions = staff?.attributes.restrictions

  const [selectedDays, setSelectedDays] = useState(
    staffRestrictions?.allowed_days ?? ''
  )
  const [selectedCountries, setSelectedCountries] = useState(
    staffRestrictions?.allowed_countries ?? []
  )

  useEffect(() => {
    if (!restrictions) {
      showRestrictions()
      if (staffRestrictions?.allowed_days) {
        setSelectedDays(staffRestrictions?.allowed_days)
      }
      if (staffRestrictions?.allowed_countries) {
        setSelectedCountries(staffRestrictions?.allowed_countries)
      }
    }
  }, [
    restrictions,
    showRestrictions,
    staffRestrictions?.allowed_days,
    staffRestrictions?.allowed_countries
  ])

  const onAttributeChange = useCallback(
    (attr: any, value: any): void => {
      if (attr === 'allowed_ip_ranges') {
        value = splitStringIntoArray(value)
      }

      const error = validate(attr as any, value)

      if (staff && staff.attributes.restrictions) {
        setStaff({
          ...staff,
          attributes: {
            ...staff.attributes,
            restrictions: { ...staff.attributes.restrictions, [attr]: value }
          }
        })
        setErrors({ ...errors, [attr]: error })
      }
    },
    [staff, setStaff, errors]
  )

  function splitStringIntoArray(str: string): string[] {
    if (str === '') {
      return []
    }
    return str.split(',').map((item) => item.trim())
  }

  const validateFields = useCallback(
    (staff: ReadResult<StaffMember> | undefined): boolean => {
      if (!staff) return false

      const attributes = staff.attributes.restrictions

      const isFilled = {
        allowed_start_time:
          Object.prototype.hasOwnProperty.call(
            attributes,
            'allowed_start_time'
          ) && attributes?.allowed_start_time !== '',
        allowed_end_time:
          Object.prototype.hasOwnProperty.call(
            attributes,
            'allowed_end_time'
          ) && attributes?.allowed_end_time !== '',
        allowed_ip_ranges:
          Object.prototype.hasOwnProperty.call(
            attributes,
            'allowed_ip_ranges'
          ) && attributes?.allowed_ip_ranges.length
      }

      const areRestrictionsValid =
        isFilled.allowed_start_time &&
        isFilled.allowed_end_time &&
        isFilled.allowed_ip_ranges
      setErrors({
        ...errors,
        allowed_start_time: !isFilled.allowed_start_time
          ? 'O campo deve ser preenchido'
          : null,
        allowed_end_time: !isFilled.allowed_end_time
          ? 'O campo deve ser preenchido'
          : null,
        allowed_ip_ranges: !isFilled.allowed_ip_ranges
          ? 'O campo deve ser preenchido'
          : null
      })

      return !areRestrictionsValid
    },
    [errors]
  )

  const onToggleDay = (
    dayIndex: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()

    const updatedDays = selectedDays.split('')
    updatedDays[dayIndex] = updatedDays[dayIndex] === '1' ? '0' : '1'
    setSelectedDays(updatedDays.join(''))

    if (staff && staff.attributes.restrictions) {
      setStaff({
        ...staff,
        attributes: {
          ...staff?.attributes,
          restrictions: {
            ...staff?.attributes.restrictions,
            allowed_days: updatedDays.join('')
          }
        }
      })
    }
  }

  const onToggleCountry = (countryCode: string) => {
    setSelectedCountries((prevSelected) => {
      const updatedSelection = prevSelected.includes(countryCode)
        ? prevSelected.filter((code) => code !== countryCode)
        : [...prevSelected, countryCode]

      if (staff && staff.attributes.restrictions) {
        setStaff({
          ...staff,
          attributes: {
            ...staff?.attributes,
            restrictions: {
              ...staff?.attributes.restrictions,
              allowed_countries: updatedSelection
            }
          }
        })
      }

      return updatedSelection
    })
  }

  const onSave = useCallback(async () => {
    if (!staff) return

    if (validateFields(staff) || check(errors)) {
      return
    }

    const result = await editRestrictions()

    if (result) {
      setErrors(result)
    }
  }, [staff, editRestrictions, errors, validateFields])

  return (
    <>
      <div className="flex flex-row justify-between mt-10 animate-fade-in-down">
        <div className="w-1/2 px-4">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
            noValidate
          >
            <div className="space-y-6 mb-5">
              <div className="flex flex-row justify-between">
                <div className="text-xl text-left leading-6 font-medium text-gray-900">
                  Restrições de acesso
                </div>
              </div>

              <DayButtons
                allowedDays={selectedDays}
                onToggleDay={(dayIndex, event) => onToggleDay(dayIndex, event)}
              />

              <InputWithValidationError
                label="Horário de início"
                name="allowed_start_time"
                value={staff?.attributes.restrictions?.allowed_start_time}
                onChange={(value) =>
                  onAttributeChange('allowed_start_time', value)
                }
                error={errors.allowed_start_time}
              />

              <InputWithValidationError
                label="Horário de fim"
                name="allowed_end_time"
                value={staff?.attributes.restrictions?.allowed_end_time}
                onChange={(value) =>
                  onAttributeChange('allowed_end_time', value)
                }
                error={errors.allowed_end_time}
              />

              <CountryCheckboxList
                countries={countries}
                selectedCountries={selectedCountries}
                onToggleCountry={(countryCode) => onToggleCountry(countryCode)}
              />

              <InputWithValidationError
                label="Faixas de IPs permitidas"
                name="allowed_ip_ranges"
                value={staff?.attributes.restrictions?.allowed_ip_ranges}
                onChange={(value) =>
                  onAttributeChange('allowed_ip_ranges', value)
                }
                error={errors.allowed_ip_ranges}
              />
            </div>

            <div className="mt-8 w-full space-x-4 flex justify-end animate-fade-in-down">
              <Close linkTo="/staff-members" />
              <Button disabled={isSaving} label="Salvar" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
