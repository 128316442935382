import { StringOrNull } from 'types'

type Validations = {
  [key: string]: { validate: (value: StringOrNull) => boolean }
}

const patterns = {
  name: /\d/,
  imei: /^\d{15}$/
}

const validations: Validations = {
  imei: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      if (!value.match(patterns.imei)) return false
      return true
    }
  },
  name: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      if (!value.match(patterns.name)) return false
      return true
    }
  },
  purchase_tax_note: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false
      return true
    }
  },
  purchase_tax_note_date: {
    validate: (value: StringOrNull) => {
      if (!value || value === '') return false

      const delim = /[/-]/g
      const [val1, val2, val3] = value.split(delim)

      const day = parseInt(val1)
      const month = parseInt(val2)
      const year = parseInt(val3)

      if (isNaN(day) || isNaN(month) || isNaN(year)) {
        return false
      }

      return true
    }
  }
}

export function validate(prop: any, value: StringOrNull): boolean {
  if (!(prop in validations)) return true

  return validations[prop].validate(value)
}
