import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import { LoadingCircle } from 'components/Loading'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'

import config from 'config'
import { Role } from 'models'
import { phone } from 'utils/presentation'
import { useURLParams } from 'hooks/useURLParams'
import { Tabs } from 'modules/Items/components/Tabs'
import { QueryFilter } from 'interfaces/queryOptions'
import { ChipListResponse, list } from '../services/api'
import { SearchBar } from 'components/Search'

const MAX_ITEMS_PER_PAGE = config.items_per_list_page

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = { search: '' }

    const search = query.get('search')

    if (search) params.search = search

    return params
  }, [query])

  const [isLoading, setIsLoading] = useState(true)

  const [page, setPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>([
    { key: 'from_softruck', op: 'eq', value: true }
  ])

  const [chips, setChips] = useState([] as ChipListResponse[])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const fetchChips = async () => {
      setIsLoading(true)

      const options = {
        attributes: [
          'id',
          'number',
          'serial',
          'service_provider',
          'carrier',
          'apn',
          'apn_username',
          'apn_password'
        ],
        includes: { enterprise: ['name'] },
        filters,
        limit: MAX_ITEMS_PER_PAGE,
        sort: { created_at: -1 },
        search,
        page
      }

      try {
        const { count, chips } = await list(options)

        setPage(page)
        setChips(chips)
        setTotal(count)
        setIsLoading(false)
      } catch (err: any) {
        setIsLoading(false)
        console.error(err)
        toast.error('Falha ao buscar chips')
      }
    }

    fetchChips()
  }, [page, search, filters])

  const onFilterChange = useCallback(
    (op: string) => {
      const index = filters.findIndex(
        (filter) => filter.key === 'enterprise_id'
      )

      if (op === 'all') {
        if (index >= 0) {
          filters.splice(index, 1)
        }
      } else {
        if (index >= 0) {
          filters[index].op = op
        } else {
          filters.push({ key: 'enterprise_id', op, value: null })
        }
      }

      setFilters([...filters])
    },
    [filters]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Chips" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
          <Link to="/chips/create">
            <SimpleButton> Novos </SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      <div className="m-4 w-1/3">
        <Tabs onChange={(value: string) => onFilterChange(value)} />
      </div>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          SERIAL / NÚMERO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          OPERADORA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          PROVEDORA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          APN
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          USUÁRIO / SENHA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          EMPRESA
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {chips.map((chip, index) => (
                        <tr
                          key={index}
                          className={index % 2 ? 'bg-gray-50' : 'bg-white'}
                        >
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div className="text-xs text-gray-900">
                                  {' '}
                                  {chip.serial}{' '}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {' '}
                                  {`+${chip.number?.slice(0, 2)} ${phone(
                                    chip.number?.slice(2)
                                  )}`}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.service_provider}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.carrier}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.apn}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-gray-900">
                                  {' '}
                                  {chip.apn_username}{' '}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {' '}
                                  {chip.apn_password}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-xs text-gray-500 whitespace-pre-line">
                            {chip.enterprise ?? '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={MAX_ITEMS_PER_PAGE}
                itemsOnPage={chips.length}
                totalItems={total}
                onPageChange={setPage}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
