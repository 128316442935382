interface Props {
  pageSize: number
  currentPage: number
  itemsOnPage: number
  totalItems: number
  onPageChange: (page: number) => void
}

export function Pagination({
  currentPage,
  pageSize,
  itemsOnPage,
  totalItems,
  onPageChange
}: Props) {
  const showingFrom = (currentPage - 1) * pageSize + 1
  const showingTo = (currentPage - 1) * pageSize + itemsOnPage

  return (
    <nav
      className="py-2 my-4 ml-4 flex items-center justify-between"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          {'Mostrando '}
          <span className="font-medium">{showingFrom}</span>
          {' a '}
          <span className="font-medium">{showingTo}</span>
          {' de '}
          <span className="font-medium">{totalItems}</span>
          {' resultados'}
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {currentPage > 1 && (
          <button
            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={() => onPageChange(--currentPage)}
          >
            Anterior
          </button>
        )}
        {itemsOnPage < pageSize || showingTo === totalItems ? null : (
          <button
            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            onClick={() => onPageChange(++currentPage)}
          >
            Próximo
          </button>
        )}
      </div>
    </nav>
  )
}
