import { StringOrNull } from 'types'

const messages = {
  not_empty: 'O campo não pode ser vazio.',
  not_number: 'O campo deve ser um número.',
  not_negative: 'O campo não pode ser negativo.',
  not_zero_and_negative: 'O campo não pode ser zero ou negativo.'
}

export function notNullPositiveFloat(value: StringOrNull) {
  if (!value) return messages.not_empty

  const number = parseFloat(value.replaceAll(',', '.'))

  if (isNaN(number)) return messages.not_number

  if (number < 0) return messages.not_negative

  return null
}

export function notNullPositiveInteger(value: StringOrNull) {
  if (!value || value === '') return messages.not_empty

  const number = parseInt(value)

  if (isNaN(number)) return messages.not_number

  if (number < 1) return messages.not_zero_and_negative

  return null
}

export function notNullNonNegativeInteger(value: StringOrNull) {
  if (!value || value === '') return messages.not_empty

  const number = parseInt(value)

  if (isNaN(number)) return messages.not_number

  if (number < 0) return messages.not_negative

  return null
}

export function nullableInteger(value: StringOrNull) {
  if (!value) return null

  const number = parseInt(value)

  if (isNaN(number)) return messages.not_number

  return null
}

export function nullable(value: StringOrNull) {
  if (!value) return null

  return null
}

export function notEmpty(value: StringOrNull) {
  if (value === '') return messages.not_empty
  return null
}
