import { ReactNode, useState } from 'react'

import { ConfirmationContext, ShowConfirmationDialogParams } from './context'
import { ConfirmationDialog } from './ConfirmationDialog'

export function ConfirmationDialogProvider({
  children
}: {
  children: ReactNode
}) {
  const [dialog, setDialog] = useState({
    title: null as ReactNode,
    message: null as ReactNode,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirm: (() => {}) as () => any,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onCancel: (() => {}) as () => any,
    open: false
  })

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onConfirm: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel: () => {}
    })
  }

  const value = {
    showConfirmationDialog: (params: ShowConfirmationDialogParams) => {
      const onConfirm = () => {
        params.onConfirm()
        closeDialog()
      }

      const onCancel = () => {
        if (params.onCancel) params.onCancel()
        closeDialog()
      }

      setDialog({ ...params, open: true, onConfirm, onCancel })
    }
  }

  return (
    <ConfirmationContext.Provider value={value}>
      {children}
      <ConfirmationDialog {...dialog} />
    </ConfirmationContext.Provider>
  )
}
