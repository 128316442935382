import { OrderConnectivityStatus, OrderStatus } from 'models'

export const orderBaseSteps = [
  { id: '01', label: 'pending', name: 'Pendente', status: 'upcoming' },
  { id: '02', label: 'approved', name: 'Aprovado', status: 'upcoming' },
  { id: '03', label: 'in_line', name: 'Na fila', status: 'upcoming' },
  { id: '04', label: 'in_progress', name: 'Em progresso', status: 'upcoming' },
  {
    id: '05',
    label: 'updating_fiscal_data',
    name: 'Emitindo nota fiscal',
    status: 'upcoming'
  },
  {
    id: '06',
    label: 'ready_for_pick_up',
    name: 'Pronto para entrega',
    status: 'upcoming'
  },
  { id: '07', label: 'shipping', name: 'Em transporte', status: 'upcoming' },
  { id: '08', label: 'delivered', name: 'Entregue', status: 'upcoming' }
]

export const nonLogisticsOrderBaseSteps = [
  { id: '01', label: 'pending', name: 'Pendente', status: 'upcoming' },
  { id: '02', label: 'approved', name: 'Aprovado', status: 'upcoming' },
  { id: '03', label: 'delivered', name: 'Ativado', status: 'upcoming' }
]

export const subscriptionBaseSteps = [
  { id: '01', label: 'pending', name: 'Pendente', status: 'upcoming' },
  { id: '02', label: 'approved', name: 'Aprovado', status: 'upcoming' }
]

export const shippingMethods = [
  { value: 'shipping', label: 'Entrega padrão' },
  { value: 'pick_up', label: 'Retirar na Softruck' }
]

export const orderStatus = [
  { value: '', label: 'Selecione um estado' },
  { value: OrderStatus.in_line, label: 'Na fila' },
  { value: OrderStatus.in_progress, label: 'Em progresso' },
  { value: OrderStatus.updating_fiscal_data, label: 'Emitindo nota fiscal' },
  { value: OrderStatus.ready_for_pick_up, label: 'Pronto para entrega' },
  { value: OrderStatus.shipping, label: 'Em transporte' },
  { value: OrderStatus.delivered, label: 'Entregue' }
]

export const nonLogisticsOrderStatus = [
  { value: OrderStatus.delivered, label: 'Ativado' }
]

export const subscriptionStatus = [
  { value: OrderStatus.delivered, label: 'Entregue' }
]

export const orderConnectivityStatus = [
  { value: OrderConnectivityStatus.returned, label: 'Devolver' },
  { value: OrderConnectivityStatus.canceled, label: 'Cancelar' }
]

export const orderHardwareStatus = [
  { value: 'returned', label: 'Devolvido' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'ownership_changed', label: 'Troca de titularidade' }
]

export const writeOffMotives = {
  good: [{ value: 'sale', label: 'Venda' }],
  service: [
    { value: 'leasing', label: 'Comodato' },
    { value: 'rent', label: 'Aluguel' }
  ],
  cancel: [
    { value: 'loss', label: 'Perda' },
    { value: 'asset_sale', label: 'Venda de ativo' }
  ]
}
