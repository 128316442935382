export function LoadPage() {
  return (
    <div className="w-auto h-32 m-8 flex justify-center items-center">
      <h1 className="font-medium text-lg text-gray-500">
        Carregando Informações...{' '}
      </h1>
      <div className="animate-spin m-4 rounded-full h-6 w-6 border-b-2 border-gray-500"></div>
    </div>
  )
}
