import { useCallback, useEffect, useState } from 'react'

import { getStaff } from 'services/auth'
import { Token } from './useToken'
import { ReadResult } from 'interfaces/queryOptions'
import { StaffMember } from 'models'

const LocalStorage = window.localStorage

export type UserData = null | ReadResult<StaffMember>

type UserDataHook = [UserData, (token: Token) => Promise<void>]

export function useUserData(): UserDataHook {
  const [userData, setUserData] = useState<UserData>(null)

  useEffect(() => {
    const user = LocalStorage.getItem('@Softruck:user')

    if (user) {
      setUserData(JSON.parse(user))
    }
  }, [setUserData])

  useEffect(() => {
    if (userData) {
      LocalStorage.setItem('@Softruck:user', JSON.stringify(userData))
    } else {
      LocalStorage.removeItem('@Softruck:user')
    }
  }, [userData])

  const updateUserData = useCallback(async (token: Token) => {
    if (token && token.data) {
      const user = await getStaff(token.data.sid)

      setUserData(user)
    } else {
      setUserData(null)
    }
  }, [])

  return [userData, updateUserData]
}
