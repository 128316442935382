import { ReactNode, useEffect, useState, useCallback, useContext } from 'react'

import { OverallTab } from './Overall'
import { RestrictionsTab } from './Restrictions'
import { ClientsTab } from './Clients'
import { useHistory, useParams } from 'react-router-dom'
import { classNames, deepClone } from 'utils'
import { StaffViewContext } from 'modules/Staff/contexts/StaffViewContext'

type Tab = {
  id: string
  title: string
  current: boolean
  component: ReactNode
}

const defaultTabs = [
  {
    id: 'general',
    title: 'Geral',
    current: false,
    component: <OverallTab />
  },
  {
    id: 'restrictions',
    title: 'Restrições',
    current: false,
    component: <RestrictionsTab />
  },
  {
    id: 'clients',
    title: 'Clientes',
    current: false,
    component: <ClientsTab />
  }
]

export const ViewTabs = () => {
  const { replace } = useHistory()

  const { staffId, viewTab } = useParams() as unknown as {
    staffId: string
    viewTab: string
  }

  const { staff } = useContext(StaffViewContext)
  const role = staff?.relationships?.role.attributes.name

  const [tabs, setTabs] = useState<Tab[]>(defaultTabs)
  const [active, setActive] = useState<Tab>()

  useEffect(() => {
    if (!staffId || !viewTab) return

    const tabs: Tab[] = deepClone(defaultTabs)

    const index = tabs.findIndex((t) => t.id === viewTab)
    tabs[index].current = true

    setTabs(tabs)
    setActive(tabs[index])
  }, [staffId, viewTab])

  const onTabClick = useCallback(
    (selected: Tab) => {
      if (!staffId) return

      selected.current = true

      setActive(selected)

      replace(`/staff-members/${staffId}/view/${selected.id}`)
    },
    [staffId, replace]
  )

  return (
    <>
      <div>
        <div className="hidden sm:block">
          <div>
            <nav
              className="flex flex-row border-b border-gray-200"
              aria-label="Tabs"
            >
              {tabs
                ?.filter(
                  (tab) =>
                    !(
                      ['ROOT', 'MANAGER'].includes(role) && tab.id === 'clients'
                    )
                )
                .map((tab) => (
                  <button
                    key={tab.title}
                    className={classNames(
                      tab.current
                        ? 'border-emerald-500 text-emerald-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap justify-between w-1/5 py-3 px-1 border-b-2 font-medium text-sm bg-white'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                    onClick={() => onTabClick(tab)}
                  >
                    <div className="flex flex-row justify-center items-center space-x-1">
                      <div className="">{tab.title}</div>
                    </div>
                  </button>
                ))}
            </nav>
          </div>
        </div>
      </div>

      {active && active.component}
    </>
  )
}
