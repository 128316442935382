export default function transformLanguageCode(language: string): string {
  if (language.startsWith('es')) {
    return 'es_UY'
  } else if (language.startsWith('en')) {
    return 'en_US'
  } else if (language.startsWith('pt')) {
    return 'pt_BR'
  } else {
    return language
  }
}
