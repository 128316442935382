import { Coupon, CouponForm } from 'models/coupon'
import { isNil, omitBy } from 'utils'
import { create as baseCreate } from 'services/billing'

export async function create(formData: CouponForm) {
  const attributes = {
    ...formData,
    country_id: null
  }

  const relationships = {
    country: {
      type: 'country',
      id: formData.country_id
    }
  }

  const response = await baseCreate<Coupon>('/coupons', {
    attributes: omitBy(attributes, isNil),
    relationships
  })
  return response
}
