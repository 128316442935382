import { Button, Close } from 'components/Buttons'
import { Modal } from 'components/Modal'
import { useURLParams } from 'hooks/useURLParams'
import { ReadResult } from 'interfaces/queryOptions'
import { Client } from 'models'
import { createStaffPermission } from 'modules/Staff/services/api'
import { useCallback, useEffect, useMemo, useState } from 'react'
import config from 'config'
import { toast } from 'react-toastify'
import { list } from 'modules/Clients/services/read'
import { useHistory } from 'react-router-dom'
import { Pagination } from 'components/Pagination'
import { LoadingCircle } from 'components/Loading'
import { document } from 'utils'
import { SearchBar } from 'components/Search'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { CheckCircleIcon as CheckCircleIconSolid } from '@heroicons/react/solid'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any
  staffId: string
}

export function CreateStaffPermission({ open, setOpen, staffId }: Props) {
  const [clientId, setClientId] = useState('')

  const { replace } = useHistory()
  const history = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      search: ''
    }

    const search = query.get('search')

    if (search) params.search = search

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(1)

  const [search, setSearch] = useState(queryParams.search)
  const [sort] = useState({ account_name: 1 })
  const [total, setTotal] = useState(0)

  const [data, setData] = useState<ReadResult<Client>[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const maxItemsPerPage = config.items_per_list_page

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    async function loadData() {
      setIsLoading(true)

      const options = {
        limit: maxItemsPerPage,
        search,
        page,
        sort
      }

      try {
        const { count, data } = await list<Client>('/clients', options)
        setData(data)
        setTotal(count)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        toast.error('Falha ao buscar clientes')
        console.error(err)
      }
    }
    loadData()
  }, [maxItemsPerPage, page, search, sort])

  useEffect(() => {
    const newLastPage = Math.ceil(total / maxItemsPerPage)
    setLastPage(newLastPage)
  }, [maxItemsPerPage, total])

  const onPageChange = useCallback(
    (page: number) => {
      const newPage = Math.min(Math.max(1, page), lastPage)
      setPage(newPage)
    },
    [lastPage]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  const onClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const onSave = useCallback(async () => {
    if (!staffId || !clientId) return

    setIsSaving(true)

    try {
      await createStaffPermission(staffId, clientId)
      toast.success('Permissão adicionada com sucesso')

      onClose()
      setIsSaving(false)

      history.push(`/staff-members/${staffId}/view/clients`)
      window.location.reload()
    } catch (err: any) {
      setIsSaving(false)
      toast.error(
        err.suggestedMessage ?? 'Não foi possível adicionar a permissão'
      )
    }
  }, [staffId, clientId, onClose, history])

  return (
    <Modal
      open={open}
      title="Adicionar permissão"
      onClose={onClose}
      width="sm:w-1/2"
    >
      <div className="mt-4 space-y-5">
        <SearchBar value={search} onChange={onSearch} />
        {isLoading ? (
          <LoadingCircle />
        ) : (
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          CONTA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        >
                          EMPRESA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-sm font-light text-gray-500"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data.map((client, index) => (
                        <tr
                          key={client.id}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="px-4 py-3 text-left text-sm whitespace-nowrap max-w-xxs">
                            <div
                              title={client.attributes.account_name}
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {client.attributes.account_name ?? '-'}
                            </div>
                            <div className="text-xs text-left text-gray-500">
                              {client.attributes.email ?? '-'}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left max-w-xxs text-sm whitespace-nowrap">
                            <div
                              title={
                                client.attributes.corporate_data?.corporate_name
                              }
                              className="text-xs text-left font-semibold text-gray-900 truncate"
                            >
                              {client.attributes.corporate_data
                                ?.corporate_name ?? '-'}
                            </div>
                            <div className="text-xs text-left text-gray-500">
                              {document(
                                client.attributes.document,
                                client.attributes.document_type
                              )}
                            </div>
                          </td>
                          <td className="px-4 py-3 text-left max-w-xxs text-sm whitespace-nowrap">
                            <div
                              className="flex justify-center cursor-pointer text-blue-500 hover: underline"
                              onClick={() => setClientId(client.id.toString())}
                            >
                              {client.id.toString() === clientId ? (
                                <CheckCircleIconSolid className="text-emerald-600 w-6 h-6" />
                              ) : (
                                <CheckCircleIcon className="text-gray-300 w-6 h-6" />
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={maxItemsPerPage}
                itemsOnPage={data.length}
                totalItems={total}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        )}
      </div>

      <div className="mt-6 flex justify-end space-x-4">
        <Close onClick={onClose} />
        <Button disabled={isSaving} type="button" onClick={onSave} />
      </div>
    </Modal>
  )
}
