import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import { PageHeader } from 'components/PageHeader'
import { Autocomplete } from '../components/Autocomplete'
import { EmptyContent } from '../../components/EmptyContent'
import { ProtectedSection } from 'components/ProtectedSection'
import { NatureSelection } from '../components/NatureSelection'

import { Nature, Role } from 'models'
import { create } from '../services/api'
import { date } from 'utils/presentation'
import { translations } from '../../translations'
import { DeviceType, ParsedData } from 'modules/Items/types'
import { haveErrors, parseCSV } from 'modules/Items/helpers'

const MAX_CREATION_LIMIT = 500

export function Create() {
  const history = useHistory()

  const [file, setFile] = useState<File>()
  const [data, setData] = useState<ParsedData[]>([])

  const [nature, setNature] = useState<Nature>(Nature.service)
  const [deviceType, setDeviceType] = useState<DeviceType>()

  const [error, setError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (!file) return

    const reader = new FileReader()
    reader.onload = function (e: any) {
      const text: string = e.target.result
      const parsed = parseCSV(text, 'devices')

      setError(haveErrors(parsed))

      if (parsed.length > MAX_CREATION_LIMIT) {
        setError(true)
      }

      setData(parsed)
    }

    reader.readAsText(file)
  }, [file])

  const onClear = useCallback(() => {
    setData([])
    setDeviceType(undefined)
    setNature(Nature.service)
    setFile(undefined)
    setError(false)
  }, [])

  const removeFromTable = useCallback(
    (index: number) => {
      if (data.length === 1) onClear()

      data.splice(index, 1)
      setError(haveErrors(data))

      if (data.length > MAX_CREATION_LIMIT) {
        setError(true)
      }

      setData([...data])
    },
    [data, onClear]
  )

  const onSave = useCallback(async () => {
    if (!deviceType || !nature) return

    if (error) return

    const payload = []
    for (const line of data) {
      payload.push({
        imei: line.imei.value,
        name: line.name.value,
        device_type_id: deviceType.id,
        nature: nature,
        purchase_tax_note: line.purchase_tax_note.value,
        purchase_tax_note_date: line.purchase_tax_note_date.value
      })
    }

    setIsSaving(true)
    try {
      await create(payload.slice(0, MAX_CREATION_LIMIT))
      toast.success('Rastreadores criados com sucesso')

      onClear()

      setIsSaving(false)
      history.push('/devices')
    } catch (err: any) {
      setIsSaving(false)
      toast.error(
        err.suggestedMessage ??
          'Houve uma falha ao tentar criar os rastreadores'
      )
    }
  }, [data, deviceType, error, history, nature, onClear])

  return (
    <>
      <PageHeader title="Rastreadores" action="Novo">
        {file && (
          <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 animate-fade-in-down">
            <div className="px-8 py-4 bg-white shadow rounded-lg overflow-hidden">
              <dt className="text-sm font-medium text-center text-gray-500 truncate">
                Total
              </dt>
              <dd className="mt-1 text-xl text-center font-semibold text-gray-900">
                {data.length}
              </dd>
            </div>
          </dl>
        )}
      </PageHeader>

      {file && (
        <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
          <div className="flex flex-row animate-fade-in-down">
            <div className="w-1/4 mx-4 mb-4">
              <Autocomplete
                options={deviceType}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onSelect={() => {}}
                settype={setDeviceType}
              />
            </div>
            <div className="px-4">
              <NatureSelection
                label="Natureza"
                onChange={(nature) => setNature(nature as Nature)}
              />
            </div>
          </div>
        </ProtectedSection>
      )}

      {file && (error || !deviceType?.id) && (
        <div className="rounded-md bg-red-50 p-3 mb-4 mx-4 animate-fade-in-down">
          <div className="flex flex-row items-center space-x-4">
            <div className="text-sm text-center text-red-700">
              {data.length > MAX_CREATION_LIMIT
                ? `Limite excedido! Máximo permitido: ${MAX_CREATION_LIMIT}`
                : deviceType?.id
                ? 'A planilha apresenta erros. Corrija-os e tente novamente!'
                : 'Escolha um modelo para os rastreadores!'}
            </div>
          </div>
        </div>
      )}

      {data.length <= 0 ? (
        <EmptyContent
          modelType="devices"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFile(e.target.files?.[0])
          }}
        />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          IMEI / IDENTIFICADOR
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          MARCA / MODELO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          NATUREZA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          NOTA FISCAL
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data.map((device, index) => (
                        <tr
                          key={index}
                          className={index % 2 ? 'bg-gray-50' : 'bg-white'}
                        >
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div
                                  className={`text-xs ${
                                    device.imei.valid
                                      ? 'text-gray-900'
                                      : 'text-red-500'
                                  }`}
                                >
                                  {device.imei.value}
                                </div>
                                <div
                                  className={`text-xs ${
                                    device.name.valid
                                      ? 'text-gray-500'
                                      : 'text-red-500'
                                  }`}
                                >
                                  {device.name.value}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div className="text-xs text-left text-gray-900">
                                  {deviceType &&
                                  deviceType.supported &&
                                  deviceType.brand_alias ? (
                                    deviceType.brand_alias
                                  ) : (
                                    <div className="flex items-center">
                                      <ExclamationCircleIcon
                                        className="h-4 w-4 text-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {deviceType &&
                                  deviceType.supported &&
                                  deviceType.alias
                                    ? deviceType.alias
                                    : ''}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-left text-gray-500">
                            {translations.nature[nature as string] ?? '-'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left text-gray-900">
                                  {device.purchase_tax_note.valid ? (
                                    device.purchase_tax_note.value
                                  ) : (
                                    <div className="flex items-center">
                                      <ExclamationCircleIcon
                                        className="h-4 w-4 text-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {device.purchase_tax_note_date.valid ? (
                                    date(device.purchase_tax_note_date.value)
                                  ) : (
                                    <div className="flex items-center">
                                      <ExclamationCircleIcon
                                        className="h-4 w-4 text-red-500"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-left font-medium">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-red-700 hover:bg-gray-50 focus:outline-none"
                              onClick={() => removeFromTable(index)}
                            >
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="m-4 flex justify-end">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              onClick={onClear}
            >
              Limpar
            </button>
            <button
              disabled={error || isSaving}
              type="button"
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none ${
                error || isSaving
                  ? 'bg-gray-300'
                  : 'bg-emerald-600 hover:bg-emerald-700'
              }`}
              onClick={onSave}
            >
              Salvar
            </button>
          </div>
        </>
      )}
    </>
  )
}
