import { ExclamationCircleIcon } from '@heroicons/react/solid'

interface InputWithValidationErrorProps {
  label: string
  name: string
  onChange: (value: string) => any
  error?: string | null
  value?: any
  required?: boolean
  type?: string
  placeholder?: string
  optional?: string
  [key: string]: any
}

export function InputWithValidationError({
  label,
  name,
  value,
  onChange,
  error = null,
  type = 'text',
  required,
  placeholder = '',
  optional,
  ...rest
}: InputWithValidationErrorProps) {
  return (
    <div className="relative">
      <label
        htmlFor={name}
        className="block text-left text-sm font-medium text-gray-700"
      >
        {label}
        {optional && (
          <span className="text-xs text-gray-400"> {`(${optional})`} </span>
        )}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          required={required}
          type={type}
          name={name}
          id={name}
          value={value ?? ''}
          placeholder={placeholder}
          aria-invalid="true"
          aria-describedby={`${name}-error`}
          autoComplete="off"
          onChange={(e) => onChange(e.currentTarget.value)}
          className={
            'mt-1 overflow-hidden block w-full border rounded-md shadow-sm py-2 px-3 focus:outline-none' +
            (error
              ? ' border-red-300 focus:border-red-500'
              : ' border-gray-300 focus:border-emerald-500')
          }
          {...rest}
        />
        {error && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p
          className="absolute left-0 text-xs text-red-600"
          id={`${name}-error`}
        >
          {error}
        </p>
      )}
    </div>
  )
}
