import { Validations, StringOrNull } from 'types'

const patterns = {
  hour: /^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
  days: /^[01]{7}$/,
  cidr: /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[1-2]?[0-9])$/
}

const validations: Validations = {
  allowed_start_time: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'

      if (!value.match(patterns.hour))
        return 'O campo deve ter formato de hora (XX:XX:XX)'

      return null
    }
  },
  allowed_end_time: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'

      if (!value.match(patterns.hour))
        return 'O campo deve ter formato de hora (XX:XX:XX)'

      return null
    }
  },
  allowed_ip_ranges: {
    validate: (value: any) => {
      if (
        !Array.isArray(value) ||
        !value.every(
          (item) => typeof item === 'string' && item.match(patterns.cidr)
        )
      ) {
        return 'O campo deve ter um formato com os CIDRs (X.X.X.X/X) separados por vírgulas'
      }

      return null
    }
  }
}

export function validate(prop: any, value: StringOrNull): StringOrNull {
  if (!(prop in validations)) return null

  const error = validations[prop].validate(value)
  return error
}
