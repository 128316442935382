import { StringOrNull, Validations } from 'types'

const patterns = {
  phone: /^[0-9() -]+$/,
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  softruck_email: /@softruck+/gi
}

const validation: Validations = {
  email: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'

      if (!value.match(patterns.email)) return 'O email deve ser válido.'

      if (value.match(patterns.softruck_email))
        return 'O email não deve ser da Softruck.'

      return null
    }
  },
  username: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'
      return null
    }
  },
  name: {
    validate: (value: StringOrNull) => {
      if (!value) return 'O campo não pode ser vazio.'
      return null
    }
  },
  phone: {
    validate: (value: StringOrNull) => {
      if (!value) return null

      if (!value.match(patterns.phone)) return 'Somente números são aceitos'

      return null
    }
  }
}

export function validate(prop: string, value: StringOrNull): StringOrNull {
  if (!(prop in validation)) return null
  const error = validation[prop].validate(value)
  return error
}
