import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import React, { useCallback, useEffect, useState } from 'react'
import { ExclamationCircleIcon, XIcon } from '@heroicons/react/solid'

import { PageHeader } from 'components/PageHeader'
import { EmptyContent } from '../../components/EmptyContent'

import { create } from '../services/api'
import { phone } from 'utils/presentation'
import { ParsedData } from 'modules/Items/types'
import { haveErrors, parseCSV } from 'modules/Items/helpers'

const MAX_CREATION_LIMIT = 500

export function Create() {
  const history = useHistory()

  const [file, setFile] = useState<File>()
  const [data, setData] = useState<ParsedData[]>([])

  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!file) return

    const reader = new FileReader()
    reader.onload = function (e: any) {
      const text: string = e.target.result
      const parsed = parseCSV(text, 'chips')

      setError(haveErrors(parsed))

      if (parsed.length > MAX_CREATION_LIMIT) {
        setError(true)
      }

      setData(parsed)
    }
    reader.readAsText(file)
  }, [file])

  const onClear = useCallback(() => {
    setData([])
    setFile(undefined)
    setError(false)
  }, [])

  const removeFromTable = useCallback(
    (index: number) => {
      if (data.length === 1) onClear()

      data.splice(index, 1)
      setError(haveErrors(data))

      if (data.length > MAX_CREATION_LIMIT) {
        setError(true)
      }

      setData([...data])
    },
    [data, onClear]
  )

  const onSave = useCallback(async () => {
    if (error) return

    setIsSaving(true)
    const payload = []
    for (const line of data) {
      payload.push({
        serial: line.serial.value,
        number: line.number.value,
        service_provider: line.service_provider.value,
        carrier: line.carrier.value,
        apn: line.apn.value,
        apn_username: line.apn_username.value,
        apn_password: line.apn_password.value
      })
    }

    try {
      await create(payload)
      toast.success('Chips criados com sucesso')

      onClear()
      setIsSaving(false)
      history.push('/chips')
    } catch (err: any) {
      setIsSaving(false)
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao tentar criar os chips'
      )
    }
  }, [data, error, history, onClear])

  return (
    <>
      <PageHeader title="Chips" action="Novos">
        {file && (
          <div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 animate-fade-in-down">
              <div className="px-8 py-4 bg-white shadow rounded-lg overflow-hidden">
                <dt className="text-sm text-center font-medium text-gray-500 truncate">
                  Total
                </dt>
                <dd className="mt-1 text-xl text-center font-semibold text-gray-900">
                  {data.length}
                </dd>
              </div>
            </dl>
          </div>
        )}
      </PageHeader>

      {error && (
        <div className="rounded-md bg-red-50 p-3 m-4 animate-fade-in-down">
          <div className="flex flex-row items-center space-x-4">
            <div className="text-sm text-center text-red-700">
              {data.length > MAX_CREATION_LIMIT
                ? `Limite excedido! Máximo permitido: ${MAX_CREATION_LIMIT}`
                : 'A planilha apresenta erros. Corrija-os e tente novamente!'}
            </div>
          </div>
        </div>
      )}

      {data.length <= 0 ? (
        <EmptyContent
          modelType="chips"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFile(e.target.files?.[0])
          }}
        />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          SERIAL / NÚMERO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          OPERADORA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          PROVEDORA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          APN
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          USUÁRIO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          SENHA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500 tracking-wider"
                        >
                          AÇÕES
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {data.map((chip, index) => (
                        <tr
                          key={index}
                          className={index % 2 ? 'bg-gray-50' : 'bg-white'}
                        >
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-left">
                              <div className="flex-1">
                                <div
                                  className={`text-xs ${
                                    chip.serial.valid
                                      ? 'text-gray-900'
                                      : 'text-red-500'
                                  }`}
                                >
                                  {chip.serial.value}
                                </div>
                                <div
                                  className={`text-xs ${
                                    chip.number.valid
                                      ? 'text-gray-900'
                                      : 'text-red-500'
                                  }`}
                                >
                                  {phone(chip.number.value)}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.service_provider.valid ? (
                              chip.service_provider.value
                            ) : (
                              <div className="flex items-center">
                                <ExclamationCircleIcon
                                  className="h-4 w-4 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.carrier.valid ? (
                              chip.carrier.value
                            ) : (
                              <div className="flex items-center">
                                <ExclamationCircleIcon
                                  className="h-4 w-4 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.apn.valid ? (
                              chip.apn.value
                            ) : (
                              <div className="flex items-center">
                                <ExclamationCircleIcon
                                  className="h-4 w-4 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.apn_username.valid ? (
                              chip.apn_username.value
                            ) : (
                              <div className="flex items-center">
                                <ExclamationCircleIcon
                                  className="h-4 w-4 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-xs text-gray-500">
                            {chip.apn_password.valid ? (
                              chip.apn_password.value
                            ) : (
                              <div className="flex items-center">
                                <ExclamationCircleIcon
                                  className="h-4 w-4 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                          </td>

                          <td className="px-4 py-3 whitespace-nowrap text-xs font-medium">
                            <button
                              type="button"
                              className="bg-white py-2 px-4 border rounded-md shadow-sm text-sm font-medium text-red-700 hover:bg-gray-50 focus:outline-none"
                              onClick={() => removeFromTable(index)}
                            >
                              <XIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="m-4 flex justify-end space-x-4">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              onClick={onClear}
            >
              Limpar
            </button>
            <button
              disabled={error || isSaving}
              type="button"
              className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none ${
                error || isSaving
                  ? 'bg-gray-300'
                  : 'bg-emerald-600 hover:bg-emerald-700'
              }`}
              onClick={onSave}
            >
              Salvar
            </button>
          </div>
        </>
      )}
    </>
  )
}
