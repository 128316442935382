import { ReactNode } from 'react'

interface FromSectionProps {
  children: ReactNode
  title?: ReactNode
  hint?: ReactNode
  extra?: ReactNode
  [key: string]: any
}

export function FormSection({
  children,
  title = null,
  hint = null,
  extra = null
}: FromSectionProps) {
  return (
    <div className="bg-white space-y-6 mb-5">
      <div className="flex flex-row justify-between">
        <div>
          {title ? (
            <h3 className="text-xl text-left leading-6 font-medium text-gray-900">
              {title}
            </h3>
          ) : (
            <></>
          )}
          {hint ? <p className="mt-1 text-sm text-gray-500">{hint}</p> : <></>}
        </div>
        <div>{extra}</div>
      </div>
      {children}
    </div>
  )
}
