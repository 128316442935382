import { useEffect } from 'react'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { SubscriptionsTable } from './components/SubscriptionsTable'

export function SubscriptionTab() {
  const { client, fetchClient } = useViewClient()

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  return (
    <>
      <div className="py-8">
        <SubscriptionsTable />
      </div>
    </>
  )
}
