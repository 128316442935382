import { toast } from 'react-toastify'
import { Link, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Pagination } from 'components/Pagination'
import { PageHeader } from 'components/PageHeader'
import { LoadingCircle } from 'components/Loading'
import { SimpleButton } from 'components/FormInputs/Button'
import { ProtectedSection } from 'components/ProtectedSection'

import config from 'config'
import { Role } from 'models'
import { translations } from '../../translations'
import { useURLParams } from 'hooks/useURLParams'
import { Tabs } from 'modules/Items/components/Tabs'
import { QueryFilter } from 'interfaces/queryOptions'
import { DeviceListResponse, list } from '../services/api'
import { SearchBar } from 'components/Search'
import { date, time } from 'utils'

const MAX_ITEMS_PER_PAGE = config.items_per_list_page

export function List() {
  const { replace } = useHistory()

  const query = useURLParams()
  const queryParams = useMemo(() => {
    const params = {
      search: ''
    }

    const search = query.get('search')

    if (search) params.search = search

    return params
  }, [query])

  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(true)

  const [search, setSearch] = useState(queryParams.search)
  const [filters, setFilters] = useState<QueryFilter[]>([
    { key: 'from_softruck', op: 'eq', value: true }
  ])

  const [devices, setDevices] = useState<DeviceListResponse[]>([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const fetchDevices = async () => {
      setIsLoading(true)
      const options = {
        attributes: ['id', 'imei', 'name', 'nature'],
        includes: {
          enterprise: ['name'],
          type: ['brand_alias', 'alias'],
          last_gps: ['last_connection_time', 'last_gps_time']
        },
        filters,
        limit: MAX_ITEMS_PER_PAGE,
        sort: { created_at: -1 },
        search,
        page
      }
      try {
        const { count, devices } = await list(options)

        setPage(page)
        setDevices(devices)
        setTotal(count)
        setIsLoading(false)
      } catch (error: any) {
        toast.error(error.message ?? 'Erro ao buscar rastreadores')
        setIsLoading(false)
      }
    }

    fetchDevices()
  }, [page, search, filters])

  const onFilterChange = useCallback(
    (op: string) => {
      const index = filters.findIndex(
        (filter) => filter.key === 'enterprise_id'
      )

      if (op === 'all') {
        if (index >= 0) {
          filters.splice(index, 1)
        }
      } else {
        if (index >= 0) {
          filters[index].op = op
        } else {
          filters.push({ key: 'enterprise_id', op, value: null })
        }
      }

      setFilters([...filters])
    },
    [filters]
  )

  const onSearch = useCallback(
    (term: string) => {
      if (!term || !term.length || term.length < config.min_search_length) {
        term = ''
      }

      if (term === '') {
        query.delete('search')
      } else {
        query.set('search', term)
      }

      replace({
        pathname: window.location.pathname,
        search: query.toString()
      })

      setSearch(term)
    },
    [query, replace]
  )

  return (
    <>
      <PageHeader title="Rastreadores" action="Listagem">
        <SearchBar value={search} onChange={onSearch} />
        <ProtectedSection roles={[Role.MANAGER, Role.FINANCE, Role.LOGISTICS]}>
          <Link to="/devices/create">
            <SimpleButton> Novos </SimpleButton>
          </Link>
        </ProtectedSection>
      </PageHeader>

      <div className="m-4 w-1/3">
        <Tabs onChange={(value: string) => onFilterChange(value)} />
      </div>

      {isLoading ? (
        <LoadingCircle />
      ) : (
        <>
          <div className="flex flex-col animate-fade-in-down">
            <div className="-my-2 overflow-x-auto px-4">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          IMEI / IDENTIFICADOR
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          MARCA / MODELO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          NATUREZA
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          ÚLTIMA COMUNICAÇÃO
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-light text-gray-500"
                        >
                          EMPRESA
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {devices.map((device, index) => (
                        <tr
                          key={device.name}
                          className={
                            index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                          }
                        >
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div className="text-xs text-left text-gray-900">
                                  {' '}
                                  {device.imei}{' '}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {' '}
                                  {device.name}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-1">
                                <div className="text-xs text-left text-gray-900">
                                  {' '}
                                  {device.brand_alias}{' '}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {' '}
                                  {device.alias}{' '}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap text-sm text-left text-gray-500">
                            {translations.nature[
                              device.nature?.toLowerCase() as string
                            ] ?? '-'}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex">
                              <div className="flex-1">
                                <div className="text-xs text-left text-gray-900">
                                  {`${date(device.last_connection_time)} ${time(
                                    device.last_connection_time
                                  )}`}
                                </div>
                                <div className="text-xs text-left text-gray-500">
                                  {`${date(device.last_gps_time)} ${time(
                                    device.last_gps_time
                                  )}`}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-xs text-left text-gray-500 whitespace-pre-line">
                            {device.enterprise ?? '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="mx-4">
              <Pagination
                currentPage={page}
                pageSize={MAX_ITEMS_PER_PAGE}
                itemsOnPage={devices.length}
                totalItems={total}
                onPageChange={setPage}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
