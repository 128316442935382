import {
  notNullNonNegativeInteger,
  notNullPositiveInteger,
  nullable,
  nullableInteger
} from 'utils/validations'

type StringOrNull = string | null

type Validations = {
  [key: string]: { validate: (value: StringOrNull) => StringOrNull }
}

const createValidations: Validations = {
  quantity: { validate: notNullPositiveInteger },
  discount: { validate: nullableInteger },
  ordered_at: { validate: nullable },
  minimum_invoice_amount: { validate: notNullNonNegativeInteger },
  fine_amount: { validate: notNullNonNegativeInteger }
}

const updateValidations: Validations = {
  quantity: { validate: notNullPositiveInteger },
  discount: { validate: nullableInteger },
  ordered_at: { validate: nullable },
  minimum_invoice_amount: { validate: notNullNonNegativeInteger },
  fine_amount: { validate: notNullNonNegativeInteger }
}

export function validateCreation(
  prop: any,
  value: StringOrNull
): string | null {
  if (!(prop in createValidations)) return null

  const error = createValidations[prop].validate(value)
  return error
}

export function validateUpdate(prop: any, value: StringOrNull): string | null {
  if (!(prop in updateValidations)) return null

  const error = updateValidations[prop].validate(value)
  return error
}
