import { useCallback, useState } from 'react'
import { SearchIcon } from '@heroicons/react/solid'

interface Props {
  value: string
  onChange: (value: string) => void
}

export function SearchBar({ value, onChange }: Props) {
  const [search, setSearch] = useState(value)

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      onChange(search)
    },
    [onChange, search]
  )

  return (
    <div className="w-1/4">
      <form className="w-full flex-1 flex" onSubmit={onSubmit}>
        <div className="relative w-full text-gray-400 focus-within:text-gray-600">
          <span className="ml-2 absolute inset-y-0 left-0 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
          <input
            id="search-field"
            className="block w-full h-full pl-10 pr-2 py-2 text-gray-900 placeholder-gray-500 focus:outline-none"
            placeholder="Buscar..."
            autoComplete="off"
            type="search"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
        </div>
      </form>
      <hr />
    </div>
  )
}
